import React, {useEffect, useState} from "react";
import Header from "../Header";
import Footer from "../Footer";
import axios from "axios";
import { Link } from "react-router-dom";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoginPage, setIsLoginPage] = useState(true); // State to track if the user is on the login page
  useEffect(() => {
    // Set isLoginPage to true when the component mounts
    setIsLoginPage(true);
    // Clean up function to set isLoginPage to false when the component unmounts
    return () => {
      setIsLoginPage(false);
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("/api/login", { email, password })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {/* <Header></Header> */}
      <Header hideLoginButton={isLoginPage} /> {/* Pass isLoginPage state to Header */}
      <section className="login-section " style={{ paddingTop: "60px" }}>
        <div
          className="container d-flex align-items-center justify-content-center mb-sm-4"
          style={{ height: "100vh" }}
        >
          <div
            className="row align-self-center login rounded border bg-light-subtle"
            style={{ width: "80%", alignItems: "center" }}
          >
            <div className="col-sm-6 p-3 rounded">
              <img
                className="img-fluid rounded"
                src="assets/img/about2.jpg"
                style={{ width: "100%" }}
                alt=""
              />
            </div>

            <div className="col-sm-6 p-3 rounded bg-light-subtle ">
              <h4 className="text-center p-3 text-danger-emphasis">
          
                User Login
              </h4>
              <form onSubmit={handleSubmit} className="p-3">
                <div className="form-group">
                  <label htmlFor="email">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <Link to="/forgotpassword" className="text-decoration-none">
              
                    Forget Password ?
                  </Link>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary2 pt-2 pb-2 mt-2"
                >
                  Login
                </button>
              </form>

              {/* <p className="text-center">
                                Don't have an account? <Link to="/register">Register</Link>
                            </p> */}
            </div>
          </div>
        </div>
      </section>

      <Footer></Footer>
    </div>
  );
};

export default Signin;
