import {
  faArrowDown,
  faArrowUp,
  faCircleMinus,
  faCirclePlus,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { format } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import { TextField } from "@mui/material";
import { TimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PatientContext } from "../../contex/PatientContex";

const Pillscheduler = () => {
  // const [userId, setUserId] = useState("");
  const { user } = useContext(PatientContext);
  //  scheduled pill  state
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [medication, setMedication] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [type, setType] = useState([]);

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const [form, setForm] = useState({
    medicine_id: "",
    medication_type_id: "",
    number_of_medication_taken: "",
    dose: "",
    user_health_condition: "",
    medication_schedule: "every-day",
    no_of_days: 0,
    duration: "continuous",
    medication_start_date: getCurrentDate(),
    medication_end_date: "",
    reminderTimes: [],
    unit: "",
    custom_health_condition: "",
  });

  // Fetch the user ID from localStorage when the component mounts

  // useEffect(() => {
  //   const id = localStorage.getItem("userId");
  //   if (id) {
  //     setUserId(id);
  //     // console.log("id" + id);
  //   } else {
  //     console.error("User ID not found in localStorage");
  //   }
  // }, []);

  // setUserId(user.user_id);
  const userId = user.user_id;

  // useEffect(() => {
  //   // getType(); // Fetch medication types

  // }, []);

  useEffect(() => {
    if (userId) {
      fetchData();
      fetchHealth();
    }
  }, [userId]);
  const [healthCondition, setHealthCondition] = useState([]);
  const fetchHealth = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/healthCond/`
      );
      if (res.status === 200) {
        setHealthCondition(res.data);
        console.log(res.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  // ===================== thiss code is for get medicine type =================//
  // const getType = async () => {
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_BASEURL}/api/reminders/getmedicationtype`
  //     );
  //     setType(res.data.medicine_types);
  //     // console.log(res.data.medicine_types);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // =================== code for select  days of week ===================//
  const Options = [
    { value: "MON", label: "MON" },
    { value: "TUE", label: "TUE" },
    { value: "WED", label: "WED" },
    {
      value: "THU",
      label: "THU",
    },
    {
      value: "FRI",
      label: "FRI",
    },
    {
      value: "SAT",
      label: "SAT",
    },
    {
      value: "SUN",
      label: "SUN",
    },
  ];

  const handleDaySelect = (selectedOptions) => {
    setSelectedDays(selectedOptions);
  };

  // =======================================   submit form  =================================//

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "continuous") {
      form.medication_start_date = new Date().toISOString();
      // If duration is continuous, set medication_start_date to current date
    }
    // else if (name === "duration" && value === "no_of_days") {
    //   form.duration="no_of_days";
    //   // form.medication_start_date =  getCurrentDate();
    //   // form.medication_start_date = new Date().toLocaleDateString();
    //   // console.log(form.medication_start_date);
    //   // console.log(value)
    // }
    else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const selectedDaysArray = selectedDays.map((option) => option.value);
    // Format the dates to dd/MM/yyyy
    const formattedStartDate = form.medication_start_date
      ? format(new Date(form.medication_start_date), "dd/MM/yyyy")
      : "";
    const formattedEndDate = form.medication_end_date
      ? format(new Date(form.medication_end_date), "dd/MM/yyyy")
      : "";
    // Appending the selected values and formatted dates to your form data
    const updatedForm = {
      ...form,
      medication_schedule_specific_week: selectedDaysArray,
      medication_start_date: formattedStartDate,
      medication_end_date: formattedEndDate,
      user_id: userId,
      dose: form.dose + " " + form.unit,
    };
    if (form.reminderTimes.length == 0) {
      toast.warning("Please add the selected  time");
      return;
    }

    try {
      // console.log(updatedForm);
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }

      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/reminders/savereminder`,
        updatedForm,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 201) {
        // console.log(res.data);
        toast.success(res.data.message);
        setForm({
          medicine_id: "",
          medication_type_id: "",
          number_of_medication_taken: "",
          dose: "",
          user_health_condition: "",
          medication_schedule: "every-day",
          no_of_days: 0,
          duration: "continious",
          medication_start_date: getCurrentDate(),
          medication_end_date: "",
          reminderTimes: [],
          unit: "",
        });
        setMedication([]);
        fetchData();
        setSearch("");
      } else {
        toast.error(res.data.error);
        // console.log(res.data.error);
      }
    } catch (error) {
      toast.error(error.response.data.error.message || "An error occurred");
      // console.log(error);
      setTimeout(() => {
        if (error.request.status === 401) {
          console.log("Invalid");
          navigate("/plogin");
        }
      }, 1000);
    }
  };

  // =============================  Search Medicine =============================//
  const [search, setSearch] = useState("");

  const handleSearchChange = (event) => {
    // setSearchQuery(event.target.value);
    // getmedication(event.target.value); // Fetch medication based on the search query
    setSearch(event.target.value);
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (search) {
        getmedication(search);
      }
    }, 400);

    return () => {
      clearTimeout(delayDebounce);
    };
  }, [search]);

  const getmedication = async (name) => {
    // console.log(" hit api 0f  medicine ", name);
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/reminders/medicines?search=${name}&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        // `${process.env.REACT_APP_BASEURL}/api/reminders/medicines/${name}`
      );
      setMedication(res.data.medicines);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectMedication = (event) => {
    const selectedMedicationName = event.target.value;
    console.log(selectedMedicationName);

    // Check if the medication state is defined
    if (!medication) {
      // Medication list is not loaded or empty
      // Set an error state or display a message to the user
      // console.error("Medication list is not loaded or empty.");
      toast.warning("Medicine not found.");
      return;
    }

    // Find the selected medication by its name
    const selectedMedication = medication.find(
      (med) => med.name === selectedMedicationName
    );

    // Handle the case where the selected medication is not found
    if (!selectedMedication) {
      console.error("Selected medication not found.");
      toast.warning("Selected medication not found.");
      // Set an error state or display a message to the user indicating that the selected medication is not found
      return;
    }

    // Update the form state with the selected medication ID
    setForm((prevForm) => ({
      ...prevForm,
      medicine_id: selectedMedication.id,
    }));
  };

  // =========================== get reminders by id  ========================= ///

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/reminders/getRemindersbyId/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        setData(response.data.reminders);
        console.log(response.data.reminders);
      } else if (response.status === 204) {
        setData([]);
        toast.warning(" No Reminder found");
      } else {
        toast.error("Error while fetching data: " + response.status);
      }
    } catch (error) {
      console.log(error);
      // toast.warning(error.response.data.error);
      if (error.request.status === 401) {
        toast.error(" Unauthroized Login again");
        setTimeout(() => {
          navigate("/plogin");
        }, 1000);
      }
    }
  };

  const columns = [
    { field: "medicineName", headerName: "Medicine Name", width: 200 },
    { field: "medication_type_id", headerName: "Medicine Type", width: 100 },
    { field: "dose", headerName: "Doses", type: "number", width: 90 },
    { field: "user_health_condition", headerName: "Used For", width: 150 },
    { field: "reminderTimes", headerName: "Times", width: 150 },
    {
      field: "medication_schedule_specific_week",
      headerName: "Scheduled Days",
      width: 150,
    },
    { field: "medication_start_date", headerName: "Start date", width: 100 },
    { field: "medication_end_date", headerName: "End date", width: 100 },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => handleDelete(params.row.id)}
            color="error"
            className="fs-5"
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
          <IconButton
            onClick={() => handleEdit(params.row)}
            color="primary"
            className="fs-5 ms-2"
          >
            <FontAwesomeIcon icon={faEdit} />
          </IconButton>
        </>
      ),
    },
  ];

  // ========== below code is delete code  ==================================//
  const handleDelete = async (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (confirmed) {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      try {
        const res = await axios.delete(
          `${process.env.REACT_APP_BASEURL}/api/reminders/deletereminder/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 200) {
          toast.success("Successfully deleted");
          fetchData(); // Assuming fetchData is a function to fetch updated data
        }
      } catch (error) {
        toast.error("Error deleting");
      }
    }
  };

  // ================== code for update reminder  =================
  const [isupdate, setIsupdate] = useState(false);

  const handleEdit = (rowData) => {
    setIsupdate(true);
    const medicationId = rowData.id;
    // navigate(`/updatemedication/${btoa(medicationId)}`);
    getscheduled(medicationId);
  };
  //  this is the api call for the sechedule medication which we aare gooing to edit
  const getscheduled = async (medicationId) => {
    // console.log("id "+ medicationId);
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/reminders/reminderByReminderId/${medicationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200 && res.data) {
        console.log(res.data.reminder);
        setForm(res.data.reminder);
        setSearch(res.data.reminder.medicineName);
        // console.log(res.data.reminder.medicineName);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error: " + error.message);
    }
  };

  const handleUpdate = async (event) => {
    // event.preventDefault();
    const selectedDaysArray = selectedDays.map((option) => option.value);
    // Format the dates to dd/MM/yyyy
    const formattedStartDate = form.medication_start_date
      ? format(new Date(form.medication_start_date), "dd/MM/yyyy")
      : "";
    const formattedEndDate = form.medication_end_date
      ? format(new Date(form.medication_end_date), "dd/MM/yyyy")
      : "";
    // Appending the selected values and formatted dates to your form data
    const updatedForm = {
      ...form,
      medication_schedule_specific_week: selectedDaysArray,
      medication_start_date: formattedStartDate,
      medication_end_date: formattedEndDate,
      user_id: userId,
      dose: form.dose + " " + form.unit,
    };

    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      // console.log(updatedForm);
      const res = await axios.put(
        `${process.env.REACT_APP_BASEURL}/api/reminders/editreminder
        `,
        updatedForm,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        // console.log(res.data);
        toast.success(res.data.message);
        setForm({
          medicine_id: "",
          medication_type_id: "",
          number_of_medication_taken: "",
          dose: "",
          user_health_condition: "",
          medication_schedule: "every-day",
          medication_duration_days: "",
          duration: "continious",
          medication_start_date: new Date(),
          medication_end_date: "",
          reminderTimes: [],
          unit: "mg",
        });
        setMedication([]);
        setTimeout(() => {
          // navigate("/pillscheduler");
          setIsupdate(false);
          fetchData();
        }, 1000);
      } else {
        toast.error(res.data.error);
        // console.log(res.data.error);
      }
    } catch (error) {
      toast.error(error.response?.data?.error || "An error occurred");
      console.log(error);
    }
    // finally{
    //   setIsupdate(false);
    //   fetchData();
    // }
  };

  const getRowHeight = () => {
    return 80; // You can adjust this value according to your needs
  };

  // =========================  open dose   =================================================

  const [dosepanel, setDosepanel] = useState("hide");

  const handledose = () => {
    if (dosepanel == "hide") {
      setDosepanel("show");
    } else {
      setDosepanel("hide");
      form.dose = "";
    }
  };

  return (
    <div className="p-3">
      <div className="p-4 rounded shadow-sm border">
        <h3 className="notes-heading p-3 rounded fw-semibold text-center">
          Schedule your Medicine
        </h3>
        <div>
          <form onSubmit={handleSubmit} className="row">
            <div className="col-md-6 mt-2 mb-2">
              <label htmlFor="medicine_id" className="form-label">
                Medication Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                list="medications"
                name="search_medicine"
                required
                id="search_medicine"
                className="form-control"
                value={search}
                onChange={handleSearchChange}
                onBlur={handleSelectMedication}
              />
              <datalist id="medications">
                {medication &&
                  medication.map((med) => (
                    <option key={med.id} value={med.name} />
                  ))}
              </datalist>
            </div>

            <div className="col-md-6 mt-2 mb-2">
              <label htmlFor="medication_type_id" className="form-label">
                Type of Medication<span className="text-danger">*</span>
              </label>
              <select
                name="medication_type_id"
                id="medication_type_id"
                className="form-select"
                aria-label="Default select example"
                required
                onChange={handleInputChange}
                value={form.medication_type_id}
              >
                <option value=""> Please select Type</option>
                {/* {type.map((medType) => (
                  <option key={medType.id} value={medType.id}>
                    {medType.medication_type}
                  </option>
                ))} */}
                {medication &&
                  medication.map((med) => (
                    <option key={med.id} value={med.pack_size_label}>
                      {med.pack_size_label}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-md-6 mb-2">
              <div>
                <button
                  type="button"
                  className="btn ps-1 py-2"
                  onClick={() => {
                    handledose();
                  }}
                >
                  {" "}
                  Dose <span> &nbsp;</span>{" "}
                  {dosepanel == "hide" ? (
                    <FontAwesomeIcon
                      icon={faArrowDown}
                      className="text-primary"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faArrowUp}
                      className="text-primary"
                    />
                  )}
                </button>
                {dosepanel == "show" && (
                  <>
                    <div className="row">
                      <div className="col-sm-6">
                        <label htmlFor="" className="form-label">
                          Dose
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="value  in mg/ml /tablespoon "
                          onChange={handleInputChange}
                          value={form.dose}
                          name="dose"
                        />
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="" className="form-label">
                          Select Unit
                        </label>
                        <select
                          name="unit"
                          className="form-select"
                          value={form.unit}
                          onChange={handleInputChange}
                        >
                          <option value="">Please Select</option>
                          <option value="mg">mg (milligram)</option>
                          <option value="g">g (gram)</option>
                          <option value="mcg">mcg (microgram)</option>
                          <option value="ml">ml (milliliter)</option>
                          <option value="L">L (liter)</option>
                          <option value="unit">unit</option>
                          <option value="tablet">tablet</option>
                          <option value="capsule">capsule</option>
                          <option value="drop">drop</option>
                          <option value="puff">puff</option>
                          <option value="scoop">scoop</option>
                          <option value="teaspoon">teaspoon</option>
                          <option value="tablespoon">tablespoon</option>
                          <option value="spray">spray</option>
                          <option value="patch">patch</option>
                          <option value="ampoule">ampoule</option>
                          <option value="suppository">suppository</option>
                          <option value="sachet">sachet</option>
                          <option value="syringe">syringe</option>
                          <option value="bottle">bottle</option>
                          <option value="tube">tube</option>
                          <option value="piece">piece</option>
                          <option value="patch">patch</option>
                          <option value="pack">pack</option>
                          <option value="box">box</option>
                          <option value="strip">strip</option>
                          <option value="packets">packets</option>
                          <option value="bag">bag</option>
                          <option value="bar">bar</option>
                          <option value="can">can</option>
                          <option value="jar">jar</option>
                          <option value="roll">roll</option>
                          <option value="tube">tube</option>
                          <option value="vial">vial</option>
                          <option value="other">Other</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {/* <div className="row">
                <div className="col-sm-6">
                  <label htmlFor="" className="form-label">
                    Dose<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    placeholder="value  in mg/ml /tablespoon "
                    onChange={handleInputChange}
                    value={form.dose}
                    name="dose"
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="" className="form-label">
                    Select Unit{" "}
                  </label>
                  <select
                    name="unit"
                    className="form-select"
                    required
                    value={form.unit}
                    onChange={handleInputChange}
                  >
                    <option value="mg">mg (milligram)</option>
                    <option value="g">g (gram)</option>
                    <option value="mcg">mcg (microgram)</option>
                    <option value="ml">ml (milliliter)</option>
                    <option value="L">L (liter)</option>
                    <option value="unit">unit</option>
                    <option value="tablet">tablet</option>
                    <option value="capsule">capsule</option>
                    <option value="drop">drop</option>
                    <option value="puff">puff</option>
                    <option value="scoop">scoop</option>
                    <option value="teaspoon">teaspoon</option>
                    <option value="tablespoon">tablespoon</option>
                    <option value="spray">spray</option>
                    <option value="patch">patch</option>
                    <option value="ampoule">ampoule</option>
                    <option value="suppository">suppository</option>
                    <option value="sachet">sachet</option>
                    <option value="syringe">syringe</option>
                    <option value="bottle">bottle</option>
                    <option value="tube">tube</option>
                    <option value="piece">piece</option>
                    <option value="patch">patch</option>
                    <option value="pack">pack</option>
                    <option value="box">box</option>
                    <option value="strip">strip</option>
                    <option value="packets">packets</option>
                    <option value="bag">bag</option>
                    <option value="bar">bar</option>
                    <option value="can">can</option>
                    <option value="jar">jar</option>
                    <option value="roll">roll</option>
                    <option value="tube">tube</option>
                    <option value="vial">vial</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div> */}
            </div>
            {/* <div className="col-md-6">
              {form.medication_type_id === "1" && (
                <div>
                  <label htmlFor="" className="form-label">
                    Tablets/ Capsules <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={form.number_of_medication_taken}
                    name="number_of_medication_taken"
                    placeholder="2"
                    onChange={handleInputChange}
                  />
                </div>
              )}

              {form.medication_type_id === "2" && (
                <div className="for-injection">
                  <label htmlFor="" className="form-label">
                    Injection<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    placeholder=" 2"
                    name="number_of_medication_taken"
                    value={form.number_of_medication_taken}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {form.medication_type_id === "3" && (
                <div className="forLiquid">
                  <label htmlFor="" className="form-label">
                    Liquid / Solution <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    placeholder="1 "
                    value={form.number_of_medication_taken}
                    name="number_of_medication_taken"
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {form.medication_type_id === "4" && (
                <div className="for-drop">
                  <label htmlFor="" className="form-label">
                    Drop (Frequency) <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    placeholder="2"
                    value={form.number_of_medication_taken}
                    name="number_of_medication_taken"
                    onChange={handleInputChange}
                  />
                </div>
              )}

              {form.medication_type_id === "5" && (
                <div className="for-inhealer">
                  <label htmlfor="" className="form-label">
                    Inhaler <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    placeholder="2 "
                    value={form.number_of_medication_taken}
                    name="number_of_medication_taken"
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {form.medication_type_id === "6" && (
                <div className="for-powder">
                  <label htmlFor="" className="form-label">
                    Powder <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    placeholder="2 "
                    value={form.number_of_medication_taken}
                    name="number_of_medication_taken"
                    onChange={handleInputChange}
                  />
                </div>
              )}
              {form.medication_type_id === "7" && (
                <div className="for-other">
                  <label htmlFor="" className="form-label">
                    Other
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={form.number_of_medication_taken}
                    name="number_of_medication_taken"
                    onChange={handleInputChange}
                  />
                </div>
              )}
            </div> */}
            <div className="col-md-6">
              <div>
                <label htmlFor="" className="form-label">
                  Strength of Medicine
                </label>
                <select
                  type="text"
                  className="form-control"
                  value={form.number_of_medication_taken}
                  name="number_of_medication_taken"
                  placeholder="2"
                  onChange={handleInputChange}
                >
                  <option value=""> Please select Type</option>

                  {medication &&
                    medication.map((med) => (
                      <option key={med.id} value={med.composition_strength}>
                        {med.composition_strength}
                      </option>
                    ))}
                </select>
              </div>
            </div>

            <div className="col-md-6 mb-2">
              <label htmlFor="" className="form-label">
                Medication used For ( Health Condition )
              </label>
              <select
                id="medication"
                name="user_health_condition"
                className="form-select"
                onChange={handleInputChange}
                value={form.user_health_condition}
              >
                <option value=""> Select option</option>
                {healthCondition.map((health) => (
                  <option
                    key={health.user_health_id}
                    value={health.health_condition}
                  >
                    {health.health_condition}
                  </option>
                ))}
                {/* <option value="Others">Others</option> */}
              </select>
            </div>
            <div className="col-md-6">
              {form.user_health_condition === "Others" && (
                <div>
                  <label htmlFor="" className="form-label">
                    Enter your Health Condition
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="custom_health_condition"
                    value={form.custom_health_condition}
                    placeholder="Enter here"
                    onChange={handleInputChange}
                  />
                </div>
              )}
            </div>
            <div className="col-md-6 mb-2">
              <label className="form-label">Medication Schedule</label>
              <select
                name="medication_schedule"
                className="form-select"
                aria-label="Default select example"
                onChange={handleInputChange}
                value={form.medication_schedule}
              >
                <option value="every-day">Every day</option>
                <option value="every-week">Specific Day of week </option>
                <option value="every-x-days">Every X days</option>
              </select>
            </div>

            <div className="col-md-6">
              {form.medication_schedule === "every-x-days" && (
                <div>
                  <label htmlFor="no_of_days" className="form-label">
                    Enter days<span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    name="no_of_days"
                    min={0}
                    max={100}
                    value={form.no_of_days}
                    onChange={handleInputChange}
                  />
                </div>
              )}

              {form.medication_schedule === "every-week" && (
                <>
                  <label className="form-label"> Select days</label>
                  <Select
                    // defaultValue={[Options[2], Options[3]]}
                    isMulti
                    name="colors"
                    options={Options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleDaySelect}
                  />
                </>
              )}
            </div>
            <div className="col-md-6 mb-2 mt-2">
              <label htmlFor="duration" className="form-label">
                Medication Duration
              </label>
              <select
                name="duration"
                className="form-select"
                value={form.duration}
                onChange={handleInputChange}
              >
                <option value="continuous">Continuous</option>
                <option value="no_of_days">No. of days</option>
              </select>
            </div>

            <div className="col-md-6 mb-2 mt-2">
              {/* Start and End Date */}
              {form.duration === "no_of_days" && (
                <div className=" row">
                  <div className="col-sm-6">
                    <label
                      htmlFor="medication_start_date"
                      className="form-label"
                    >
                      Start Date<span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      required
                      className="form-control"
                      name="medication_start_date"
                      value={form.medication_start_date}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-sm-6">
                    <label
                      htmlFor="medication_end_date"
                      className="form-label "
                    >
                      End Date<span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      required
                      min={form.medication_start_date}
                      name="medication_end_date"
                      value={form.medication_end_date}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* <div className="col-md-6">
      <label htmlFor="" className="form-label">
        Set Reminder Time <span className="text-danger">*</span>
      </label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="d-flex">
          <TimePicker
          
            ampm
            value={form.newReminderTime}
            onChange={handleTimeChange}
            renderInput={(params) => (
              <input {...params} className="form-control m-4 py-2" />
            )}
          />
          <button
            className="btn px-4 py-2"
            type="button"
            onClick={addReminderTime}
          >
            <FontAwesomeIcon
              icon={faCirclePlus}
              className="text-success fw-bold fs-4"
            />
          </button>
        </div>
        <div className="mt-2">
          {form.reminderTimes.map((time, index) => (
            <div className="d-flex align-items-center" key={index}>
              <p className="m-0">
                Added time: <b>{time.format('hh:mm A')}</b>
              </p>
              <button
                className="btn px-2 py-1 fw-bold d-flex align-items-center ms-2"
                type="button"
                onClick={() => removeReminderTime(index)}
              >
                <FontAwesomeIcon
                  icon={faCircleMinus}
                  className="text-danger fw-bold fs-4"
                />
              </button>
            </div>
          ))}
        </div>
      </LocalizationProvider>
    </div> */}
            <div className="col-md-6">
              <label htmlFor="" className="form-label">
                Set Reminder Time <span className="text-danger">*</span>
              </label>
              <div className="d-flex">
                <input
                  type="time"
                  required={form.reminderTimes.length === 0}
                  className="form-control me-4"
                  value={form.newReminderTime || ""}
                  onChange={(e) =>
                    setForm({ ...form, newReminderTime: e.target.value })
                  }
                />
                <button
                  className="btn px-4 py-2"
                  type="button"
                  onClick={() => {
                    if (form.newReminderTime) {
                      setForm({
                        ...form,
                        reminderTimes: [
                          ...form.reminderTimes,
                          form.newReminderTime,
                        ],
                        newReminderTime: "",
                      });
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="text-success fw-bold fs-4"
                  />
                </button>
              </div>
              <div className="mt-2">
                {form.reminderTimes.map((time, index) => (
                  <div className="d-flex align-items-center">
                    <p key={index} className="m-0">
                      Added time: <b>{time}</b>{" "}
                    </p>
                    <button
                      className="btn px-2 py-1 fw-bold d-flex align-items-center ms-2"
                      type="button"
                      onClick={() => {
                        const newReminderTimes = [...form.reminderTimes];
                        newReminderTimes.splice(index, 1);
                        setForm({ ...form, reminderTimes: newReminderTimes });
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCircleMinus}
                        className="text-danger fw-bold fs-4"
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12 d-flex justify-content-center">
                {isupdate ? (
                  <>
                    <button
                      className="primary-btn px-4"
                      type="button"
                      onClick={() => {
                        handleUpdate();
                      }}
                    >
                      Update
                    </button>
                  </>
                ) : (
                  <>
                    <button className="primary-btn px-4" type="submit">
                      Submit
                    </button>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      {/*  schedulred pill  */}
      <div className="p-1">
        <div className="p-1 shadow-sm rounded border">
          <h4 className="p-2 rounded notes-heading"> Scheduled Medicine </h4>
          <div style={{ height: "80vh", width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={5}
              pageSizeOptions={[5, 10, 15, 20, 25]}
              // checkboxSelection
              getRowHeight={getRowHeight}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
            />
          </div>
        </div>
      </div>
      <ToastContainer position="top-center"/>
    </div>
  );
};

export default Pillscheduler;
