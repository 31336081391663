import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
 import "../../App.css"
const PrivacyPolicy = () => {
  const [updated, setupdated] = useState(" 10 May  2024");

  return (
    <div>
      <Header></Header>
      <section style={{ paddingTop: "60px" , textAlign:"justify"}}>
        <div className="container">
          <h1 className=" text-start fw-bold m-4 ">Privacy policy</h1>
          <p className="text-start fw-semibold m-4">
            Our Privacy Policy was last updated on {updated}
          </p>
          <h3 className="fw-semibold m-4 ">Applicability</h3>
          <p className="text-justi m-4">
            The provisions of the Privacy Policy shall be applicable to the
            individuals / users of App Name i.e. Bael Heath
          </p>
          <h2 className="text-start fw-bold m-4">
            Purpose of the Privacy Policy
          </h2>

          <p className="text-justi m-4">
            We created this Privacy Policy to demonstrate our commitment to the
            protection of your privacy and your personal information. Your use
            of and access to the Services is subject to this Privacy Policy and
            our Terms of Use. We are committed to the protection of privacy of
            data and will take all reasonable steps to protect the personal data
            belonging to users of the App. This privacy policy ("{" "}
            <b>Privacy Policy</b>") explains how we collect, use, share and
            protect personal information about the Users of the Services
            (jointly and severally referred to a <b>"you"</b> or <b>“User”</b>{" "}
            or <b>“Users”</b> in this Privacy Policy). Any capitalized term used
            but not defined in this Privacy Policy shall have the meaning
            attributed to it in our Terms of Use. By confirming that you are
            bound by this privacy policy (by the means provided on this website
            or app), by using the services or by otherwise giving us your
            information, you agree to the practices and policies outlined in
            this privacy policy and you hereby consent to our collection, use
            and sharing of your information as described in this privacy policy.
            We reserve the right to change, modify, add or delete portions of
            the terms of this privacy policy, at our sole discretion, at any
            time. if you do not agree with this privacy policy at any time, do
            not use any of the services or give us any of your information. if
            you use the services on behalf of someone else (such as your child)
            or an entity, you represent that you are authorized by such an
            individual or entity to accept this privacy policy on such an
            individual's or entity’s behalf.
          </p>
          <h4 className="text-start fw-bold m-4 ">
            B. WHY IS THIS PRIVACY POLICY?
          </h4>
          <p className="text-justi m-4">
            This Privacy Policy is published in compliance with, inter alia: i.
            Section 43A of the Information Technology Act, 2000;
          </p>
          <p className="text-justi m-4">
            This Privacy Policy states the following:
          </p>
          <p className="text-justi m-4">
            <ol className=" list-group-numbered">
              <li className="list-group-item border-none">
                The type of information collected from the Users, including
                sensitive personal data or information;
              </li>
              <li className="list-group-item border-none">
                The purpose, means and modes of usage of such information; and
              </li>
              <li className="list-group-item border-none">
                How and to whom we will disclose such information.
              </li>
            </ol>
          </p>
          <h4 className="text-start fw-bold m-4 ">
            B. COLLECTION OF PERSONAL INFORMATION
          </h4>
          <p className="text-justi m-4">
            <ol className=" list-group-numbered">
              <li className="list-group-item border-none">
                Generally some of the Services require us to know who you are so
                that we can best meet your needs. When you access the Services,
                we may ask you to voluntarily provide us with certain
                information that personally identifies you or could be used to
                personally identify you. Without prejudice to the generality of
                the above, information collected by us from you may include (but
                is not limited to) the following:
                <ol className=" list-group-numbred">
                  <li>
                    Contact data (such as your email address and phone number);
                  </li>
                  <li>
                    Demographic data (such as your gender, your date of birth
                    and your pin code);{" "}
                  </li>
                  <li>
                    {" "}
                    Data regarding your usage of the services and history of the
                    appointments and other transactions made by or with you
                    through the use of Services;
                  </li>
                  <li>
                    {" "}
                    Health or medical data (such as your past medical history
                    and conditions, diagnostic reports, prescriptions and
                    medication history)
                  </li>
                  <li>
                    {" "}
                    Insurance data (such as your insurance carrier and insurance
                    plan); and
                  </li>
                  <li>
                    {" "}
                    Other information that you voluntarily choose to provide to
                    us (such as information shared by you with us through emails
                    or letters, your work details, your family details)
                  </li>
                </ol>
              </li>
              <li className="list-group-item border-none">
                The information collected from you may constitute ‘personal
                information’ or ‘sensitive personal data or information’ under
                the SPI Rules. Personal information is defined under the SPI
                Rules to mean any information that relates to a natural person,
                which, either directly or indirectly, in combination with other
                information available or likely to be available with a body
                corporate, is capable of identifying such a person.
              </li>
              <li className="list-group-item border-none">
                The SPI Rules further define “sensitive personal data or
                information” of a person to mean personal information about that
                person relating to:
                <ol className=" list-group-numbred">
                  <li>passwords,</li>
                  <li>
                    financial information such as bank accounts, credit and
                    debit card details or other payment instrument details;
                  </li>
                  <li>physical, physiological and mental health condition;</li>
                  <li>sexual orientation;</li>
                  <li>medical records and history;</li>
                  <li>medical records and history;</li>
                  <li>biometric information;</li>
                  <li>
                    information received by body corporate under lawful contract
                    or otherwise;
                  </li>
                  <li>Call data records</li>
                </ol>
              </li>
            </ol>
            Information that is freely available in the public domain or
            accessible under the Right to Information Act, 2005 or any other law
            will not be regarded as personal information or sensitive personal
            data or information .
          </p>
          <h4 className="text-start fw-bold m-4">C. PRIVACY STATEMENTS</h4>
          <p className="text-justi m-4">
            <ol className="list-group-numberd">
              <li>
                A condition of each User’s use of and access to the Services is
                their acceptance of the Terms of Use, which also involves
                acceptance of the terms of this Privacy Policy. Any User that
                does not agree with any provisions of the same has the option to
                discontinue the Services provided by Bael immediately.
              </li>
              <li>
                All the information provided to us by a User, including
                sensitive personal information, is voluntary. You understand
                that Bael may use certain information of yours, which has been
                designated as ‘sensitive personal data or information’ for the
                purpose of providing you the Services, for commercial purposes
                for sale or transfer of such research, statistical or
                intelligence data. Bael also reserves the right to use
                information provided by or about the User for the following
                purposes:
                <ol>
                  <li>Publishing such information on the Website.</li>
                  <li>
                    Contacting Users for offering new products or services.
                  </li>
                  <li>
                    Contacting Users for taking product and Service feedback.
                  </li>
                  <li>
                    Analyzing software usage patterns for improving product
                    design and utility.
                  </li>
                  <li>Analyzing anonymized information for commercial use.</li>
                </ol>
              </li>
              <p className="pt-4">
                You hereby consent to such use of such information by Bael.
              </p>

              <li>
                {" "}
                Collection of information which has been designated as
                ‘sensitive personal data or information’ under the SPI Rules
                requires your express consent. By affirming your assent to this
                Privacy Policy, you provide your consent to such collection as
                required under applicable law. Our Services may be unavailable
                to you in the event such consent is not given.
              </li>
              <li>
                . Users’ personally identifiable information, which they choose
                to provide on the App is used to help the Users
                describe/identify themselves. Other information that does not
                personally identify the Users as an individual, is collected by
                Bael from and is exclusively owned by Bael. We may also use such
                information in an aggregated or non-personally identifiable form
                for research, statistical analysis and business intelligence
                purposes, and may sell or otherwise transfer such research,
                statistical or intelligence data in an aggregated or
                non-personally identifiable form to third parties. In
                particular, we reserve with us the right to use anonymized User
                demographics information and anonymized User health information
                for the following purposes:
                <ol>
                  <li>
                    Analyzing software usage patterns for improving product
                    design and utility.
                  </li>
                  <li>
                    Analyzing such information for research and development of
                    new technologies.
                  </li>
                  <li>
                    Using analysis of such information in other commercial
                    product offerings of Bael.
                  </li>
                  <li>
                    Sharing analysis of such information with third parties for
                    commercial use.
                  </li>
                </ol>
              </li>

              <li>
                You are responsible for maintaining the accuracy of the
                information you submit to us, such as your contact information
                provided as part of account registration. If your personal
                information changes, you may correct, delete inaccuracies, or
                amend information by making the change on your profile
                information page on the Websites or App or by contacting us at{" "}
                <a href="mailto:contact@tecraki.io">Contact@tecraki.io</a> .We
                will make good faith efforts to make requested changes in our
                then active databases as soon as reasonably practicable. If you
                provide any information that is untrue, inaccurate, out of date
                or incomplete (or becomes untrue, inaccurate, out of date or
                incomplete), or Bael has reasonable grounds to suspect that the
                information provided by you is untrue, inaccurate, out of date
                or incomplete, Bael may, at its sole discretion, discontinue the
                provision of the Services to you.
              </li>
              <li>
                . If you wish to cancel your account or request that we no
                longer use your information to provide you services, contact us
                through{" "}
                <a href="mailto:contact@tecraki.io">Contact@tecraki.io</a> . We
                will retain your information for as long as your account with
                the Services is active and as needed to provide you the
                Services. We shall not retain such information for longer than
                is required for the purposes for which the information may
                lawfully be used or is otherwise required under any other law
                for the time being in force. After a period of time, your data
                may be anonymized and aggregated, and then may be held by us ,
                as long as necessary for us to provide our Services effectively
                or improve the Services, but our use of the anonymized data will
                be solely for analytic purposes.
              </li>
              <li>
                Bael may require the User to pay with a credit card, debit card,
                net banking or other online payment mechanisms for Services for
                which an amount(s) is/are payable. Bael will collect such User’s
                credit card number and/or other financial institution
                information such as bank account numbers and will use that
                information for the billing and payment processes, including but
                not limited to the use and disclosure of such credit card number
                and information to third parties as necessary to complete such
                billing operation. Verification of credit information, however,
                is accomplished solely by the User through the authentication
                process offered by a third party payment gateway. User’s
                credit-card/debit card details are transacted upon secure sites
                of approved payment gateways which are digitally under
                encryption, thereby providing the highest possible degree of
                care as per current technology. Users are advised, however, that
                internet technology is not full proof safe and users should
                exercise discretion on using the same.
              </li>
              <li>
                Due to the communications standards on the Internet, when a User
                or/or anyone who visits the Website, we automatically receive
                the URL of the site from which anyone visits. We also receive
                the Internet Protocol (IP) address of each User’s computer (or
                the proxy server a User used to access the World Wide Web),
                User’s computer/ device operating system and type of web browser
                the User is using, email patterns, as well as the name of User’s
                ISP. This information is used to analyze overall trends to help
                Bael improve its Service. The linkage between User’s IP address
                and User’s personally identifiable information may be available
                to us but is not shared with other third parties.
                Notwithstanding the above, we may share some of the aggregate
                findings (not the specific data) in anonymized form (i.e.,
                non-personally identifiable) with advertisers, sponsors,
                investors, strategic partners, and others in order to help grow
                our business.
              </li>
              <li>
                In order to have access to all the features and benefits on App,
                a User must first create an account on or App. To create an
                account, a User is required to provide the following
                information, which such User recognizes and expressly
                acknowledges is personal information allowing others, including
                Bael, to identify the User: name, User ID, email address,
                address, date of birth, gender, phone number and password chosen
                by the User. Other optional information may be requested on the
                registration page. We may, in future, include other optional
                requests for information from the User to help us to customize
                the Services to deliver personalized information to the User.
                However, We assume your consent in relation to various matters,
                once you complete the registration process.
              </li>
              <li>
                This Privacy Policy applies to App and Services that are owned
                and operated by Bael. We do not exercise control over the sites
                that may be displayed as search results or links from within the
                Services. These other sites may place their own cookies or other
                files on the Users’ computer, collect data or solicit personal
                information from the Users, for which Bael is not responsible or
                liable. Accordingly, Bael does not make any representations
                concerning the privacy practices or policies of such third
                parties or terms of use of such websites, nor does Bael
                guarantee the accuracy, integrity, or quality of the
                information, data, text, software, sound, photographs, graphics,
                videos, messages or other materials available on such websites.
                The inclusion or exclusion does not imply any endorsement by
                Bael of the website, the website's provider, or the information
                on the website. If you decide to visit a third party website
                linked to the Website, you do this entirely at your own risk.
                Bael encourages the User to read the privacy policies of that
                website.
              </li>
              <li>
                The Services may enable a User to communicate with other Users
                or to post information to be accessed by others, whereupon other
                Users may collect such data. Such Users, including any
                moderators or administrators, are not authorized Bael
                representatives or agents, and their opinions or statements do
                not necessarily reflect those of Bael, and they are not
                authorized to bind Bael to any contract. Bael hereby expressly
                disclaims any liability for any reliance or misuse of such
                information that is made available by Users or visitors in such
                a manner.
              </li>
              <li>
                Bael may periodically ask users to complete surveys asking about
                their experiences with features of the App and Services. Our
                surveys may ask visitors for demographic information such as
                age, gender, and education. We use survey information for
                evaluation and quality improvement purposes, including helping
                Bael to improve information and services offered. In addition,
                users giving feedback may be individually contacted for
                follow-up due to concerns raised during the course of such
                evaluation. Demographic information and Web log data may be
                stored for future evaluation and quality improvement activities
              </li>
              <li>
                {" "}
                Comments or questions sent to us using email or secure messaging
                forms will be shared with our employees and health care
                professionals who are most able to address the comment or
                question. We will archive your messages once we have made our
                best effort to provide you with a complete and satisfactory
                response. Some of our services such as our automated appointment
                selection and prescription refill for Bael generated
                prescriptions interact directly with other Bael data systems.
                Data about your transaction may be stored in these systems, and
                available to people who test and support these systems. When you
                use a service on the Websites or the App to interact directly
                with Bael health care professionals, some information you
                provide may be documented in your medical record, and available
                for use to guide your treatment as a patient.
              </li>
              <li>
                Our App may include social media Features, such as the Facebook
                button. These Features may collect your IP address, which page
                you are visiting on our site, and may set a cookie to enable the
                Feature to function properly. Your interactions with these
                Features are governed by the privacy statement of the company
                providing them.
              </li>
              <li>
                If you are using the Websites or App, with your permission, we
                will use the geolocation feature of your mobile device or same
                or similar feature of the device you are using to access the
                Websites. When you download and use the App, we automatically
                collect information on the type of device you use, operating
                system version, and the device identifier. Bael does not share
                your location information with any third party. You may opt out
                of location based services on your mobile phone by changing the
                relevant/ applicable setting at your device level.
              </li>
              <li>
                Bael does not collect information about the visitors of the
                Website from other sources, such as public records or bodies, or
                private organizations, save and except for the purposes of
                registration of the Users (the collection, storage and
                disclosure of which each User must agree to under the terms of
                use in order for Bael to effectively render the Services).
              </li>
              <li>
                Bael has implemented best international market practices and
                security policies, rules and technical measures to protect the
                personal data that it has under its control from unauthorized
                access, improper use or disclosure, unauthorized modification
                and unlawful destruction or accidental loss. However, for any
                data loss or theft due to unauthorized access to the User’s
                electronic devices through which the User avails the Services,
                Bael shall not be held liable for any loss whatsoever incurred
                by the User.
              </li>
              <li>
                {" "}
                Bael ensures to implement reasonable security practices and
                procedures that are commensurate with respect to the information
                being collected and the nature of Bael’s business. The
                reasonable security practices and procedures implemented by us
                include but are not limited to: encrypting data when it is on
                the move using industry standard practices, regularly changing
                production keys and password, secure access to all production
                servers, performing regular security updates on our servers and
                more.
              </li>
              <li>
                {" "}
                Bael takes your right to privacy very seriously and other than
                as specifically stated in this privacy Policy, will only
                disclose your personal information in the event it is required
                to do so by law, rule, regulation, law enforcement agency,
                governmental official, legal authority or similar requirements
                or when Bael, in its sole discretion, deems it necessary in
                order to protect its rights or the rights of others, to prevent
                harm to persons or property, to fight fraud and credit risk, or
                to enforce or apply the Terms of Use.
              </li>
              <li>
                Bael may also disclose or transfer End-Users’ personal and other
                information a User provides, to a third party as part of
                reorganization or a sale of the assets of a Bael division or
                company. Any third party to which Bael transfers or sells its
                assets to will have the right to continue to use the personal
                and other information that Users provide to us, in accordance
                with the Terms of Use.
              </li>
              <li>
                To the extent necessary to provide Users with the Services, Bael
                may provide their personal information to third party
                contractors who work on behalf of or with Bael to provide Users
                with such Services, to help Bael communicate with Users or to
                maintain the Website or App. Generally these contractors do not
                have any independent right to share this information, however
                certain contractors who provide services on the Website,
                including the providers of online communications services, will
                have rights to use and disclose the personal information
                collected in connection with the provision of these Services in
                accordance with their own privacy policies.
              </li>
            </ol>
          </p>
          <h4 className="text-start fw-bold m-4">D. CASUAL VISITORS NOTE:</h4>

          <p className="m-4 text-justi pb-3">
            a) No sensitive personal data or information is automatically
            collected by Bael from any casual visitors of App, who are merely
            perusing the site.
          </p>
          <p className="m-4 text-justi pb-3">
            b) Certain provisions of this Privacy Policy are applicable to even
            such casual visitors, and such casual visitors are also required to
            read and understand the privacy statements set out herein, failing
            which they are required to leave the Website immediately.
          </p>
          <p className="m-4 text-justi pb-3">
            c) You are not a casual visitor if you have willingly submitted any
            personal data or information, all such visitors will be deemed to
            be, and will be treated as, Users for the purposes of this Privacy
            Policy, and in which case, all the statements in this Privacy Policy
            apply to such persons.
          </p>

          <h4 className="text-start fw-bold m-4">
            E. CONFIDENTIALITY AND SECURITY
          </h4>

          <p className="m-4 text-justi pb-3">
            a) Your Personal Information is maintained by us in electronic form
            on its or its employees and Partners equipment. Such information may
            also be converted to physical form from time to time. We take
            necessary precautions to protect your personal information both
            online and off-line, and implements reasonable security practices
            and measures including certain managerial, technical, operational
            and physical security control measures that are commensurate with
            respect to the information being collected and the nature of our
            business.
          </p>
          <p className="m-4 text-justi pb-3">
            b) No administrator of Bael will have knowledge of your password. It
            is important for you to protect against unauthorized access to your
            password, your computer and your mobile phone or device. Be sure to
            log off from the Website when finished. We do not undertake any
            liability for any unauthorized use of your account and password. If
            you suspect any unauthorized use of your account, you must
            immediately notify Bael by sending an email toContact@tecraki.io You
            shall be liable to indemnify Bael, its employees and Partners due to
            any loss suffered by them due to such unauthorized use of your
            account and password.
          </p>
          <p className="m-4 text-justi pb-3">
            c) Bael makes all User information accessible to its employees only
            on a need-to-know basis.
          </p>
          <p className="m-4 text-justi pb-3">
            d) Notwithstanding the above, Bael is not responsible for the
            confidentiality, security or distribution of your personal
            information by our Partners and third parties outside the scope of
            our agreement with such Partners and third parties. Further, Bael
            shall not be responsible for any breach of security or for any
            actions of any third parties or events that are beyond the
            reasonable control of Bael and its Partners including, acts of
            government, computer hacking, unauthorized access to computer data
            and storage device, computer crashes, breach of security and
            encryption, poor quality of Internet service or telephone service of
            the User etc.
          </p>

          <h4 className="text-start fw-bold m-4">
            F. CHANGE TO PRIVACY POLICY
          </h4>

          <p className="m-4 text-justi ">
            We may update this Privacy Policy at any time, with or without
            advance notice. In the event there are significant changes, We will
            display a notification on our’s app, so that you may review the
            changed terms prior to continuing to use the Services. If you object
            to any of the changes to our terms, and you no longer wish to use
            the Services, you may contact  <a href="mailto:contact@tecraki.io">Contact@tecraki.io</a> to deactivate your
            account. Unless stated otherwise, current Privacy Policy applies to
            all information that has been obtained about You and Your account.
          </p>

          <h4 className="text-start fw-bold m-4">
            G. CHILDREN'S AND MINOR'S PRIVACY
          </h4>

          <p className="m-4 text-justi ">
            We strongly encourage parents and guardians to supervise the online
            activities of their minor children and consider using parental
            control tools available from online services and software
            manufacturers to help provide a child-friendly online environment.
            These tools also can prevent minors from disclosing their name,
            address, and other personally identifiable information online
            without parental permission. Although the Bael Website, App and
            Services are not intended for use by minors, We respect the privacy
            of minors who may inadvertently use the internet or the mobile
            application.
          </p>

          <h4 className="text-start fw-bold m-4">H. CONSENT TO THIS POLICY</h4>

          <p className="m-4 text-justi ">
            You acknowledge that this Privacy Policy is a part of the Terms of
            Use of the Website and the other Services, and you unconditionally
            agree that becoming a User of the App and its Services signifies
            your assent to this Privacy Policy. Your visit to the use App and
            use of the Services is subject to this Privacy Policy and the Terms
            of Use.
          </p>

          <h4 className="text-start fw-bold m-4">
            I. ADDRESS FOR PRIVACY QUESTIONS
          </h4>

          <p className="m-4 text-justi ">
            Should you have questions about this Privacy Policy or Bael's
            information collection, use and disclosure practices, you may
            contact us at{" "}
            <a href="mailto:contact@tecraki.io">Contact@tecraki.io</a>. We will
            use reasonable efforts to respond promptly to any requests,
            questions or concerns, which you may have regarding our use of your
            personal information. If you have any grievance with respect to our
            use of your information, you may communicate such grievance to:
          </p>

          <h3 className="fw-bold m-4 ">Address</h3>
          <p className="m-4 text-justi fw-bold ">
            Office No. 421,Rishabh Cloud 9 Towers, Sector
            1,Vaishali,Ghaziabad-201010(UP),India
          </p>

          <h4 className="text-start fw-bold m-4">Still have a question?</h4>
          <p className="text-justi m-4">
            Contact us at{" "}
            <a
              href="mailto:contact@tecraki.io"
              className="text-decoration-none text-primary"
            >
              contact@tecraki.io
            </a>
          </p>
          <button className="btn btn-primary2 m-4">
            Contact Us <i className="bi bi-arrow-right"></i>
          </button>
        </div>
      </section>
      <section>
        <Footer></Footer>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
