import React, { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";

const Vitalsupdate = () => {
  const baseurl = process.env.REACT_APP_BASEURL;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data);
    data.userId="1";
   
    
    try {
      const response = await axios.post(
        `${baseurl}/api/vitals/saveVitals`,
        data
      );
      console.log("Form submitted successfully:", response.data);
      toast.success("Data Updated Successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Data Not Updated Successfully");
    }
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="container">
      <h2 className="text-center mt-5 mb-4 p-3 notes-heading rounded ">
        Update Vitals
      </h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className=" p-3 shadow-sm rounded border "
      >
       
        <div className="row">
          <div className="col-md-3 mt-2">
            <label htmlFor="oxygenSaturation">Date </label>
            <input
              type="date"
              className="form-control"
              id="date"
              required
              
              {...register("currentDate", {
                required: "Date is required",
               type:"date",
              })}
             
            />
            {errors.currentDate && (
              <small className="text-danger">
                {errors.currentDate.message}
              </small>
            )}
          </div>
          <div className="col-md-3 mt-2">
            <label htmlFor="oxygenSaturation">
              Oxygen Saturation /{" "}
              <span className="text-main-color">SpO2 %</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="oxygenSaturation"
              {...register("oxygenSaturation", {
                
                min: {
                  value: 50,
                  message: "Value must be between 50 to 100",
                },
                max: {
                  value: 100,
                  message: "Value must be less than 100 ",
                },
              })}
            
            />
            {errors.oxygenSaturation && (
              <small className="text-danger">
                {errors.oxygenSaturation.message}
              </small>
            )}
          </div>
          <div className="col-md-3 mt-2">
            <label htmlFor="BMI">BMI </label>
            <input
              type="number"
              className="form-control"
              id="BMI"
              {...register("BMI", {
                
                min: {
                  value: 20,
                  message: "Value must be between 50 to 100",
                },
                max: {
                  value: 100,
                  message: "Value must be less than 100 ",
                },
              })}
       
            />
            {errors.BMI && (
              <small className="text-danger">{errors.BMI.message}</small>
            )}
          </div>
          <div className="col-md-3 mt-2">
            <label htmlFor="weight">
              Weight /<span className="text-main-color">Kg</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="weight"
              {...register("weight", {
                
                min: {
                  value: 2,
                  message: "Value cannot be less than 2 kg ",
                },
                max: {
                  value: 300,
                  message: "Value must be less than  300 kg",
                },
              })}
            
            />
            {errors.weight && (
              <small className="text-danger">{errors.weight.message}</small>
            )}
          </div>
          <div className="col-md-3 mt-2">
            <label htmlFor="height">
              Height / <span className="text-main-color">Cm</span>
            </label>
            <input
              type="number"
              className="form-control"
              {...register("height", {
                
                min: {
                  value: 20,
                  message: "Value cannot be less than 20 cm ",
                },
                max: {
                  value: 250,
                  message: "Value must be less than 250 cm",
                },
              })}
              
            />
            {errors.height && (
              <small className="text-danger">{errors.height.message}</small>
            )}
          </div>
          <div className="col-md-3 mt-2">
            <label htmlFor="temperature">
              Temperature/ <span className="text-main-color">°F</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="temperature"
              {...register("temperature", {
              
                min: {
                  value: 80,
                  message: "Value cannot be less than 80 °F ",
                },
                max: {
                  value: 110,
                  message: "Value must be less than 110 °F",
                },
              })}
              // name="temperature"
              // required
              // value={formData.temperature}
              // onChange={handleInputChange}
            />
            {errors.temperature && (
              <small className="text-danger">
                {errors.temperature.message}
              </small>
            )}
          </div>
          <div className="col-md-3 mt-2">
            <label htmlFor="pulse">
              Pulse/ <span className="text-main-color">bpm</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="pulse"
              {...register("pulse", {
               
                min: {
                  value: 30,
                  message: "Value cannot be less than 50 bpm ",
                },
                max: {
                  value: 200,
                  message: "Value must be less than 200 bpm",
                },
              })}
              // name="pulse"
              // required
              // value={formData.pulse}
              // onChange={handleInputChange}
            />
            {errors.pulse && (
              <small className="text-danger">{errors.pulse.message}</small>
            )}
          </div>
          <div className="col-md-3 mt-2">
            <label htmlFor="bloodGlucose">
              Blood Glucose/ <span className="text-main-color">mg/dl</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="bloodGlucose"
              {...register("bloodGlucose", {
               
                min: {
                  value: 50,
                  message: "Value cannot be less than 50 mg/dl ",
                },
                max: {
                  value: 300,
                  message: "Value must be less than 300 mg/dl",
                },
              })}
              // name="bloodGlucose"
              // required
              // value={formData.bloodGlucose}
              // onChange={handleInputChange}
            />
            {
              errors.bloodGlucose && (
                <small className="text-danger">{errors.bloodGlucose.message}</small>
              )
            }
          </div>
          <div className="col-md-3 mt-2">
            <label htmlFor="bloodPressure">
              Blood Pressure <span className="text-main-color">mm/Hg</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="bloodPressure"
              {...register("bloodPressure", {
              
                min: {
                  value: 50,
                  message: "Value cannot be less than 50 mm/Hg ",
                },
                max: {
                  value: 400,
                  message: "Value must be less than 400 mm/Hg",
                },
              })}
              // name="bloodPressure"
              // required
              // value={formData.bloodPressure}
              // onChange={handleInputChange}
            />
            {
              errors.bloodPressure && (
                <small className="text-danger">
                  {errors.bloodPressure.message}
                </small>
              )
            }
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 ">
            <button type="submit" className=" primary-btn">
              Submit
            </button>
          </div>
        </div>
      </form>
      <ToastContainer
        className="mt-4 pt-4"
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Vitalsupdate;
