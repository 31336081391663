import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router-dom";

const Trackvitals = () => {
  const { id } = useParams();
  const [vitals, setVitals] = useState([]);
  const [heading, setHeading] = useState("");

  console.log(id);
  const setHead=() => {
    if(id=="bloodpressure"){
        setHeading("Blood Pressure / mmHg");
     }
     else if(id=="glucose"){
        setHeading("Blood Glucose mg/dl");
     } else if(id=="pulse"){
        setHeading("Pulse /bpm");
     }
     else if (id=="height"){
        setHeading("Height /cm");
     }else if (id=="weight"){
        setHeading("Weight /Kg");
     }else if( id=="temprature"){
        setHeading("Temprature / °F");
     } else if(id=="BMI"){
        setHeading("BMI / BMI");
     }else if(id=="oxygen"){
        setHeading("Oxygen Saturation  %SpO2");
     }
     
  };

 useEffect(()=>{
 setHead();
 },[])

 
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: " Vitals Chart",
      },
    },
    scales: {
      x: {
        type: "category",
        labels: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
        // labels: labeArray,
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 10, // Divide the axis into intervals of 10
        },
      },
    },
  };

  const chartData = {

    datasets: [
      {
        label:heading,
        // data: bloodPressureArray,
        data: [90, 130, 125, 180, 135, 180, 132],
        backgroundColor: "RGB(197, 245, 210)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      //   {
      //     label: "Glucose / mg/dL",
      //     data: [90, 130, 125, 180, 135, 180, 132],
      //     // data: glucoseArray,
      //     backgroundColor: "RGB(144, 238, 144)",
      //     borderColor: "rgb(65, 176, 110)",
      //     borderWidth: 1,
      //   },
      //   {
      //     label: "Oxygen / SpO2 %",
      //     data: [90, 130, 125, 180, 135, 180, 132],
      //     // data: oxygenArray,
      //     backgroundColor: "RGB(173, 216, 230)",
      //     borderColor: "rgba(54, 162, 235, 1)",
      //     borderWidth: 1,
      //   },
      //   {
      //     label: "Temperature / °F",
      //     data: [90, 130, 125, 180, 135, 180, 132],
      //     // data: temperatureArray,
      //     backgroundColor: " RGB(255, 165, 0)",
      //     borderColor: "rgba(255, 206, 86, 1)",
      //     borderWidth: 1,
      //   },
    ],
  };

  return (
    <div className="p-3">
      <div className="p-3 shadow-sm rounded border">
        <h4 className="text-center  notes-heading rounded  p-2">
          {" "}
          Track your ({heading})
        </h4>
        <div className="chart">
          <div className="chart">
            <Bar data={chartData} options={chartOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trackvitals;
