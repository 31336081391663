
import "./App.css";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom"; 
import Patient from "./components/pages/Patient";
import Contact from "./components/pages/Contact";
import Blog from "./components/pages/blogs/Blog";
import BlogMainPage from "./components/pages/blogs/BlogMainPage";
import BlogMainPage2 from "./components/pages/blogs/BlogMainPage2";
import BlogMainPage3 from "./components/pages/blogs/BlogMainPage3";
import Aboutus from "./components/pages/Aboutus";
import Doctor from "./components/pages/Doctor";
import Clinic from "./components/pages/Clinic";
import WriteBlog from "./components/pages/Writeblog";
import Notfound from "./components/pages/Notfound";
import Signin from "./components/pages/Signin";
import Home from "./components/pages/Home";
import Consent from "./components/documents/Consent";
import PrivacyPolicy from "./components/documents/PrivacyPolicy";
import DeleteMyAccount from "./components/documents/DeleteMyAccount";
import Scrolltop from "./components/Scrolltop";

//   these are the  Patient secction Routes
import PatientLogin from "./patientsection/auth/PatientLogin";
import Loginwithpassword from "././patientsection/auth/Loginwithpassword";
import Completeprofile from "./patientsection/auth/Completeprofile";
import Layout from "./patientsection/dashboard/Layout";
import Notes from "./patientsection/pages/Notes/Note";
import  Healthrecord from "./patientsection/pages/HealthRecord/Healthrecord";
import Viewrecord from "./patientsection/pages/HealthRecord/Viewrecord";
import Uploadrecord from "./patientsection/pages/HealthRecord/Uploadrecord";
import Expenses from "./patientsection/pages/Expenses/Expenses";
import Appointment from "./patientsection/pages/Appointment/Appointment";
import Testresults from "./patientsection/pages/TestResults/Testresults";
import Pillscheduler from "./patientsection/pages/PillScheduler/Pillscheduler";
import Vitals from "./patientsection/pages/Vitals/Vitals";
import Vitalsupdate from "./patientsection/pages/Vitals/Vitalsupdate";
import Trackvitals from "./patientsection/pages/Vitals/Trackvitals";
import Viewmember from "./patientsection/pages/FamilyMember/Viewmember";
import EditProfile from "./patientsection/pages/EditProfile";
import Myprofile from "./patientsection/pages/Myprofile";
import Addmember from "./patientsection/pages/FamilyMember/Addmember";
// import Patient_private_route from "./patientsection/Patient_private_route";
 import MedicationReminder from "./patientsection/pages/PillScheduler/MedicationReminder";
import SimpleForm from "./patientsection/pages/PillScheduler/med3";
import { regSw, subscribe } from './helper';
import EditReminder from "./patientsection/pages/PillScheduler/EditReminder";

function App() {
  useEffect(() => {
    async function setupPushNotifications() {
      try {
        const serviceWorkerReg = await regSw();
        await subscribe(serviceWorkerReg);
      } catch (error) {
        console.error('Error setting up push notifications', error);
      }
    }

    setupPushNotifications();
  }, []);
  return (
    <BrowserRouter>
    
      <Scrolltop />
      <Routes>
        <Route path="/" element={<Home />} />
        
        <Route path="login" element={<Signin />} />
        <Route path="patient" element={<Patient />} />
        <Route path="doctor" element={<Doctor />} />
        <Route path="clinic" element={<Clinic />} />
        <Route path="contact" element={<Contact />} />
        <Route path="about" element={<Aboutus />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blogpage1" element={<BlogMainPage />} />
        <Route path="blogpage2" element={<BlogMainPage2 />} />
        <Route path="blogpage3" element={<BlogMainPage3 />} />
        <Route path="consent" element={<Consent />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="deletemyaccount" element={<DeleteMyAccount />} />
        <Route path="writeblog" element={<WriteBlog />} />
       
        {/*  patient   setions Routes    */}

        <Route path="plogin" element={<PatientLogin />} />
        <Route path="loginwithpassword" element={<Loginwithpassword />} />
        <Route path="completeprofile" element={<Completeprofile />} />

        {/* <Route path="/" element={<Patient_private_route/>}> */}
          <Route path="/" element={<Layout />}>
            {/* <Route path="/" element={<Vitals />} /> */}
            <Route path="vitals/:id" element={<Vitals />} />
            <Route path="notes" element={<Notes />} />
            <Route path="healthrecord" element={<Healthrecord />} />
            <Route path="viewrecord/:id" element={<Viewrecord />} />
            <Route path="uploadrecord" element={<Uploadrecord />} />
            <Route path="updatevitals" element={<Vitalsupdate />} />
            <Route path="trackvitals/:id" element={<Trackvitals />} />
            <Route path="pillscheduler" element={<Pillscheduler />} />
            <Route path="test" element={<Testresults />} />
            <Route path="appointment" element={<Appointment />} />
            <Route path="expenses" element={<Expenses />} />
            {/* <Route path="updatemedication/:medicationId" element={<UpdateSchedule />} /> */}
            <Route path="family/viewmember" element={<Viewmember />} />
            <Route path="family/addmember" element={<Addmember />} />
            <Route path="editprofile/:userid" element={<EditProfile />} />
            <Route path="myprofile/:userid" element={<Myprofile />} />
            <Route path="medication" element={<MedicationReminder />} />
            <Route path="med3" element={<SimpleForm />} />
            <Route path="editreminder/:reminderId" element={<EditReminder/>}/>
          </Route>
        {/* </Route> */}
        
        <Route path="*" element={<Notfound />} />
      </Routes>
    
    </BrowserRouter>

  );
}

export default App;
