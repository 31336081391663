import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer";
import Header from "../../Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";

const BlogMainPage2 = () => {
  return (
    <>
      <Header />
      <div className="" style={{ paddingTop: "70px" }}>
        <div className="blog-header p-4">
          <div className="container">
            <h1 className="blog-title fs-1 fw-semibold">
              How Having Access to Digital Health Records Can Result in Better
              Health Outcomes?
            </h1>
            <div className="d-flex justify-content-between pt-4">
              <p className="fs-4">
                <FontAwesomeIcon icon={faCalendarCheck} /> June 1 , 2024
              </p>

              {/* <a
                href="#main"
                className="bg-dark-subtle py-2 px-3 rounded-circle fs-4"
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </a> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row p-4 " id="main">
            <div className="col-md-8">
              <h3 className="fw-semibold fs-2  sub-bg shadow-sm">
                Modern healthcare with Electronic Health Record system.
              </h3>
              <hr />
              <h4 className="fw-semibold">
                Benefits of having digital health records.
              </h4>
              <p className="text-secondary justify fs-5">
                The revolutionary EHR (electronic health record) system has
                welcomed the new era with efficiency. Digitalization has
                unlocked the two major components of healthcare operations,
                effectiveness, and efficiency. The EHR system is a comprehensive
                solution for Patients, health professionals, Clinics, and
                hospitals. Also, it ensures that healthcare institutions keep
                pace with technological advancements. Bael Healthcare has opted
                for Digitization Healthcare for special care and treatment of
                patients.
                <br />
                <ul className="text-secondary fs-5 ">
                  <li>Hospitals improve the quality of care.</li>
                  <li>Streamlined workflows and efficiency.</li>
                  <li>Digitalised easy documentation.</li>
                  <li>Enhance operational efficiency.</li>
                  <li>Automated revenue generation. </li>
                </ul>
              </p>
            </div>
            <div className="col-md-4 d-flex justify-content-center">
              <div>
                <div>
                  <img
                    src="assets/img/blog/ceo.jpg"
                    alt=""
                    style={{ maxHeight: "200px", width: "200px" }}
                    className="rounded-circle "
                  />
                </div>

                <div className="pt-3">
                  <p className="fw-semibold "> Prashant Yadav </p>
                  <p className="text-italic">
                    CEO of Tecraki Technology Solutions Pvt. Ltd.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            <div className="row">
              <div className="col-md-4 d-flex align-items-center">
                <img
                  src="assets/img/blog/digitalrecord.png"
                  alt=""
                  className="img-fluid "
                />
              </div>
              <div className="col-md-8">
                <h3 className=" sub-bg shadow-sm">
                  Developments in modern healthcare
                </h3>
                <p className="text-secondary justify fs-5">
                  The introduction of digital records, also known as electronic
                  health records has led to a profound change in modern
                  healthcare. There are numerous benefits and challenges
                  associated with it. Digital records allow healthcare providers
                  to share patient information in a smooth manner, leading to
                  better coordination of care. Administrative processes have
                  been streamlined by the digitization of records, reducing the
                  time spent by healthcare professionals on paperwork. Automated
                  features, such as automatic reminders of appointments,
                  prescription renewals, and billing have been introduced to
                  increase the efficiency of operations. The digitization of
                  medical records, which enables healthcare professionals to
                  gain remote access to patients' health information and allows
                  efficient consultation and monitoring. With regard to
                  security, the handling of patient data shall be secure and
                  confidential.
                </p>
                <ul className="text-secondary fs-5 ">
                  <li>Enhanced coordination. </li>
                  <li> Smooth streamlined workflow. </li>
                  <li>Patient access and outreach.</li>
                  <li> Enhanced security measures.</li>
                  <li>Operational efficiency.</li>
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="p-4">
            <h3 className=" sub-bg shadow-sm">
              Result and better health outcomes
            </h3>
            <p className="text-secondary justify fs-5">
              There have been improvements in the entire healthcare system
              through comprehensive data access. Starting from the ability to
              access a complete history of patients including past treatments,
              lab reports, and diagnoses. More accurate and precise treatment
              plans have been put in action with special care for the patients.
              Patient safety and security are guaranteed through regular
              monitoring. With the help of automated reminders, alerts, and
              follow-ups there has been significant improvement in health
              outcomes.
            </p>
            <ul className="text-secondary fs-5 ">
              <li>	Improvements in diagnostic features and accuracy. </li>
              <li>	Proactive monitoring for patients. </li>
              <li>	Better and direct engagement between patients and health providers.</li>
              <li>	Care coordination. </li>
          
            </ul>
          </div> */}
          <div className="p-4">
            <div className="row">
              <div className="col-md-8">
                <h3 className=" sub-bg shadow-sm">
                  Result and better health outcomes
                </h3>
                <p className="text-secondary justify fs-5">
                  There have been improvements in the entire healthcare system
                  through comprehensive data access. Starting from the ability
                  to access a complete history of patients including past
                  treatments, lab reports, and diagnoses. More accurate and
                  precise treatment plans have been put in action with special
                  care for the patients. Patient safety and security are
                  guaranteed through regular monitoring. With the help of
                  automated reminders, alerts, and follow-ups there has been
                  significant improvement in health outcomes.
                </p>
                <ul className="text-secondary fs-5 ">
                  <li> Improvements in diagnostic features and accuracy. </li>
                  <li> Proactive monitoring for patients. </li>
                  <li>
                    {" "}
                    Better and direct engagement between patients and health
                    providers.
                  </li>
                  <li> Care coordination. </li>
                </ul>
              </div>
              <div className="col-md-4">
                <img
                  src="assets/img/blog/newtech.png"
                  alt=""
                  className="img-fluid "
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="p-4">
            <h3 className="text-semibold"> Frequently Asked Questions </h3>
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed fs-5"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    How can digital records benefit the patient's safety?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body fs-5">
                    By reducing the risk of medication errors, Avoiding
                    duplicate tests, and prompt reminders for precautionary
                    measures and follow-up appointments, digital records improve
                    patient safety.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed fs-5"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    What are the improvements in health outcomes Through modern
                    health record systems?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse fs-5"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    There has been a positive drastic change in the modern
                    health system starting from smooth administrative workflow,
                    better coordination, efficient secure data exchanges, and
                    facility of maintaining entire family health records
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4">
            <p className="fw-semibold"> Still have a question? Contact us.</p>
            <Link to="/contact" className="btn btn-primary1">
              Contact us <i className="bi bi-arrow-right"></i>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogMainPage2;
