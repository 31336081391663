import React, { useContext, useEffect, useState } from "react";
import profile from "../../../assets/images/profile.png";
import { useForm } from "react-hook-form";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PatientContext } from "../../contex/PatientContex";

const Addmember = () => {
  const baseurl = process.env.REACT_APP_BASEURL;
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  // const {user, setuser}= useContext(PatientContext);
  // const id = user.id;
  const [id, setId] = useState("");

  useEffect(() => {
    const Id = localStorage.getItem("userId");
    if (Id) {
      setId(Id);
    }
  }, []);

  const navigate = useNavigate();

  const relation = watch("relation");

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("profile_photo", selectedFile);
    formData.append("name", data.name);
    formData.append("relation", data.relation);
    formData.append("gender", data.gender);
    formData.append("date_of_birth", data.date_of_birth);
    formData.append("aadharNumber", data.aadharNumber);
    formData.append("patient_id", id);

    if (data.customRelation) {
      formData.append("customRelation", data.customRelation);
    }

    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      // console.log("Form Data:", formData);
      const res = await axios.post(
        `${baseurl}/api/familyMember/saveFamily/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`
          },
        }
      );
      if (res.status === 201) {
        toast.success("Family Member Added Successfully");
        reset();
        setTimeout(()=>{
          navigate("/family/viewmember")
        },1000)
      
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.response.request.statusText + "please login again" );
      setTimeout(()=>{
        if(error.request.status ===401){
          navigate("/plogin")
        }
      },1000)
    
    }
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // console.log("Selected file:", file);
    setFileError(""); // Clear previous errors
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSize = 2 * 1024 * 1024; // 2 MB

      if (!validTypes.includes(file.type)) {
        setFileError("Only image files (JPEG, PNG, GIF) are allowed.");
      } else if (file.size > maxSize) {
        setFileError("File size must be less than 2 MB.");
      } else {
        setSelectedFile(file);
      }
    }
    // setSelectedFile(file);
  };

  return (
    <div className="p-3">
      <div className="p-3 shadow-sm border rounded ">
        <h3 className="p-3 rounded notes-heading text-center ">
          Add Familly Members
        </h3>
        <form action="" className="row" onSubmit={handleSubmit(onSubmit)}>
          <div className=" row">
            <div className="col-sm-6">
              <label htmlFor="" className="form-label">
                Upload profile Photo
              </label>

              <input
                type="file"
                required
                onChange={handleFileChange}
                name="profile_photo" // Make sure the name attribute matches the key in formData
                id="profile_pic"
                className="form-control"
              />
              {fileError && (
                    <small className="text-danger">{fileError}</small>
                  )}
             
            </div>
            <div className="col-sm-6 d-flex justify-content-end">
              <img
                src={selectedFile ? URL.createObjectURL(selectedFile) : profile}
                alt=""
                className="rounded-circle"
                style={{ height: "100px", width: "100px" }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="" className="form-label ">
              Name
            </label>
            <input
              type="text"
              id=""
              className="form-control"
              placeholder="please enter name "
              {...register("name", {
                required: "name is required",
                minLength: {
                  value: 2,
                  message: "Please enter at least 2 characters",
                },
                maxLength: {
                  value: 20,
                  message: "Please enter at most 20 characters",
                },
              })}
            />
            {errors.name && (
              <small className="text-danger">{errors.name.message}</small>
            )}
          </div>
          <div className="col-md-3">
            <label htmlFor="" className="form-label ">
              Relation
            </label>
            <select
              id="relationSelect"
              className="form-select"
              {...register("relation", {
                required: "Relation is required",
              })}
            >
              <option value="">Select One</option>
              <option value="Father">Father</option>
              <option value="Mother">Mother</option>
              <option value="Brother">Brother</option>
              <option value="Sister">Sister</option>
              <option value="Grandfather">Grandfather</option>
              <option value="Grandmother">Grandmother</option>
              <option value="Uncle">Uncle</option>
              <option value="Aunt">Aunt</option>
              <option value="Others">Other</option>
            </select>
            {errors.relation && (
              <small className="text-danger">{errors.relation.message}</small>
            )}
          </div>
          <div className="col-md-3">
            {relation == "Others" && (
              <div>
                <label htmlFor=""> Please enter relation</label>
                <input
                  type="text"
                  name=""
                  id=""
                  className="form-control"
                  {...register("customRelation", {})}
                />
              </div>
            )}
          </div>

          <div className="col-md-3">
            <label htmlFor="" className="form-label ">
              Gender
            </label>
            <select
              name=""
              id=""
              className="form-select"
              {...register("gender", {
                required: "Gender is required",
              })}
            >
              <option value="">Select One</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            {errors.gender && (
              <small className="text-danger">{errors.gender.message}</small>
            )}
          </div>
          <div className="col-md-3">
            <label htmlFor="" className="form-label">
              Date of Birth
            </label>
            <input
              type="date"
              name=""
              id=""
              className="form-control"
              {...register("date_of_birth", {
                required: "Date of Birth is required",
              })}
            />
            {errors.date_of_birth && (
              <small className="text-danger">
                {errors.date_of_birth.message}
              </small>
            )}
          </div>
          <div className="col-md-6">
            <label htmlFor="" className="form-label">
              Aadhar No
            </label>
            <input
              type="number"
              name=""
              id=""
              className="form-control"
              {...register("aadharNumber", {
                required: "Aadhar No is required",
                minLength: {
                  value: 12,
                  message: "Please enter a valid aadhar number",
                },
                maxLength: {
                  value: 12,
                  message: "Please enter a valid aadhar number",
                },
                pattern: {
                  value: /^\d+$/,
                  message: "Please enter a valid aadhar number",
                },
              })}
            />

            {errors.aadharNumber && (
              <small className="text-danger">
                {errors.aadharNumber.message}
              </small>
            )}
          </div>
          <div className="row d-flex justify-content-start mt-3">
            <div className="col-3">
              <button className="primary-btn px-3 py-2"> Save </button>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Addmember;
