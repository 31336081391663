import React, { createContext, useState, useEffect } from 'react';

const PatientContext = createContext();

const PatientProvider = ({ children }) => {
    // Initialize state from localStorage
    const [user, setUser] = useState(() => {
        const storedUser = localStorage.getItem('user');
        // console.log(JSON.parse(storedUser));
        return storedUser ? JSON.parse(storedUser) : null;
    });

    // Save user to localStorage whenever it changes
    
    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('user');
        }
    }, [user]);

    // Fetch user from localStorage when the component mounts


    return (
        <PatientContext.Provider value={{ user, setUser }}>
            {children}
        </PatientContext.Provider>
    );
};

export { PatientContext, PatientProvider };
