// import React, { useEffect } from "react";
// import AOS from "aos";
// import "aos/dist/aos.css";
// const Testemonial = () => {
//   useEffect(() => {
//     AOS.init();
//   }, []);

//   data = [
//     {
//       img: "assets/img/person/happyfaces1.jpg",
//       name: "Navneet Kumar Singh ",
//       comment:"BaelHealth has revolutionized the way I manage my family's health records. With just a tap, I can access all medical information, from lab results to treatment plans. It's like having a personal health assistant in my pocket. Highly recommended!",
//       rating: 5,
//     },
//     {
//       img: "assets/img/person/parshant.jpg",
//       name: "Prashant Dikshit  ",
//       comment:"I highly recommend, BaelHealth has been a game-changer. It allows me to access patient records instantly, enabling me to make more informed decisions. The seamless communication feature has improved patient-doctor interactions, leading to better healthcare outcomes.",
//       rating: 4,
//     },
//     {
//       img: "assets/img/person/sachin.jpg",
//       name: "Sachin Kumar ",
//       comment:"BaelHealth has revolutionized the way I manage my family's health records. With just a tap, I can access all medical information, from lab results to treatment plans. It's like having a personal health assistant in my pocket. Highly recommended!",
//       rating: 5,
//     },
//     {
//       img: "assets/img/person/neelabh.jpgg",
//       name: "Neelabh Tiwari",
//       comment:"BaelHealth has revolutionized the way I manage my family's health records. With just a tap, I can access all medical information, from lab results to treatment plans. It's like having a personal health assistant in my pocket. Highly recommended!",
//       rating: 4,
//     },
//   ];
//   return (
//     <div>
//       <section className="feedback1 ">
//         <div className="container">
//           <div
//             id="carouselExampleInterval"
//             className="carousel slide"
//             data-bs-ride="carousel"
//           >
//             <div className="carousel-inner ">
//               <div className="d-flex justify-content-between">
//                 <h2 className="good-text fw-bold pt-3"> Happy Faces </h2>
//                 {/* <div className="col-12 d-flex justify-content-end"> */}
//                 <div className="">
//                   <button
//                     className=" arrow m-3"
//                     type="button"
//                     data-bs-target="#carouselExampleInterval"
//                     data-bs-slide="prev"
//                   >
//                     <i className="bi bi-arrow-left"></i>
//                   </button>
//                   <button
//                     className=" arrow m-3"
//                     type="button"
//                     data-bs-target="#carouselExampleInterval"
//                     data-bs-slide="next"
//                   >
//                     <i className="bi bi-arrow-right"></i>
//                   </button>
//                 </div>
//               </div>
//               {/* </div> */}
//               <div className="carousel-item active" data-bs-interval="5000">
//                 <div className="row p-3 ">
//                   <div
//                     className="col-sm-4 align-self-center person-img"
//                     data-aos="fade-right"
//                   >
//                     <img
//                       src="assets/img/person/happyfaces1.jpg"
//                       className="rounded img-fluid"
//                       alt=""
//                     />
//                   </div>
//                   <div
//                     className="col-sm-8 align-self-center"
//                     data-aos="fade-left"
//                     data-aos-delay="200"
//                   >
//                     <div className="d-flex mb-3">
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-fill tick"></i>
//                     </div>
//                     <h3>Navneet Singh</h3>
//                     <blockquote className="fst-italic">
//                       "BaelHealth has revolutionized the way I manage my
//                       family's health records. With just a tap, I can access all
//                       medical information, from lab results to treatment plans.
//                       It's like having a personal health assistant in my pocket.
//                       Highly recommended!"
//                     </blockquote>
//                     <div className="align-self-bottom">
//                       {/* <p className="fst-italic">
//                                                     <span className="text-primary small">Software Engineer</span>
//                                                 </p> */}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="carousel-item" data-bs-interval="4000">
//                 <div className="row p-3">
//                   <div
//                     className="col-sm-4 align-self-center person-img"
//                     data-aos="fade-right"
//                   >
//                     <img
//                       src="assets/img/person/parshant.jpg"
//                       className="rounded img-fluid"
//                       alt=""
//                     />
//                   </div>
//                   <div
//                     className="col-sm-8 align-self-center"
//                     data-aos="fade-left"
//                     data-aos-delay="200"
//                   >
//                     <div className="d-flex mb-3">
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-half tick"></i>
//                     </div>
//                     <h3>Prashant Dikshit </h3>
//                     <blockquote className="fst-italic">
//                       "I highly recommend, BaelHealth has been a game-changer.
//                       It allows me to access patient records instantly, enabling
//                       me to make more informed decisions. The seamless
//                       communication feature has improved patient-doctor
//                       interactions, leading to better healthcare outcomes."
//                     </blockquote>
//                     <div className="align-self-bottom">
//                       {/* <p className="fst-italic">
//                                                     <span className="text-primary small">Doctor</span>
//                                                 </p> */}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="carousel-item" data-bs-interval="4000">
//                 <div className="row p-3">
//                   <div
//                     className="col-sm-4 align-self-center person-img"
//                     data-aos="fade-right"
//                   >
//                     <img
//                       src="assets/img/person/sachin.jpg"
//                       className="rounded img-fluid"
//                       alt=""
//                     />
//                   </div>
//                   <div
//                     className="col-sm-8 align-self-center"
//                     data-aos="fade-left"
//                     data-aos-delay="200"
//                   >
//                     <div className="d-flex mb-3">
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-half tick"></i>
//                     </div>
//                     <h3>Sachin Kumar</h3>
//                     <blockquote className="fst-italic">
//                       "Managing administrative tasks at our clinic has become so
//                       much easier with BaelHealth. From appointment scheduling
//                       to billing, everything is streamlined, saving us time and
//                       reducing paperwork. Plus, the ability to monitor expenses
//                       helps us maintain cost-efficiency."
//                     </blockquote>
//                     <div className="align-self-bottom">
//                       {/* <p className="fst-italic">
//                                                     <span className="text-primary small"> Clinic Manager</span>
//                                                 </p> */}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="carousel-item" data-bs-interval="4000">
//                 <div className="row p-3 ">
//                   <div
//                     className="col-sm-4 align-self-center person-img"
//                     data-aos="fade-right"
//                   >
//                     <img
//                       src="assets/img/person/neelabh.jpg"
//                       className="rounded img-fluid"
//                       alt=""
//                     />
//                   </div>
//                   <div
//                     className="col-sm-8 align-self-center"
//                     data-aos="fade-left"
//                     data-aos-delay="200"
//                   >
//                     <div className="d-flex mb-3">
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-fill tick"></i>
//                       <i className="bi bi-star-half tick"></i>
//                     </div>
//                     <h3> Neelabh Tiwari</h3>
//                     <blockquote className="fst-italic">
//                       "I often find it challenging to keep track of my family's
//                       health records and vitals. However, ever since I
//                       discovered the BaelHealth app, that's no longer a problem.
//                       This app offers a seamless solution for managing family
//                       profiles and monitoring vital health indicators. F "
//                     </blockquote>
//                     <div className="align-self-bottom">
//                       {/* <p className="fst-italic">
//                                                     <span className="text-primary small"> Health Insurance Advisor</span>
//                                                 </p> */}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Testemonial;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Testemonial = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const data = [
    // {
    //   img: "assets/img/person/happyfaces1.jpg",
    //   name: "Navneet Kumar Singh",
    //   comment: " Baelhealth allow us to store and share my health records with anyone.",
    //   rating: 5,
    // },
    {
      img: "assets/img/person/parshant.jpg",
      name: "Prashant Dikshit",
      comment: "Helps me track my vitals and health information.",
      rating: 5,
    },
    {
      img: "assets/img/person/sachin.jpg",
      name: "Sachin Kumar",
      comment: "Allows us to add our family members' health records and track expenses on doctor fees and medicine.",
      rating: 5,
    },
    {
      img: "assets/img/person/neelabh.jpg",
      name: "Neelabh Tiwari",
      comment: "Easily book appointments with any doctor and manage health records.",
      rating: 5,
    },
  ];
  
  return (
    <div>
      <section className="feedback1">
        <div className="container">
          <div
            id="carouselExampleInterval"
            className="carousel slide"
            data-bs-ride="carousel"
          >  <div className="d-flex justify-content-between">
          <h2 className="good-text fw-bold pt-3">Happy Faces</h2>
          <div>
            <button
              className="arrow m-3"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="prev"
            >
              <i className="bi bi-arrow-left"></i>
            </button>
            <button
              className="arrow m-3"
              type="button"
              data-bs-target="#carouselExampleInterval"
              data-bs-slide="next"
            >
              <i className="bi bi-arrow-right"></i>
            </button>
          </div>
        </div>
            <div className="carousel-inner">
              {data.map((item, index) => (
                <div
                  key={index}
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                  data-bs-interval={index === 0 ? 5000 : 4000}
                >
                  <div className="row p-3">
                    <div
                      className="col-sm-4 align-self-center person-img"
                      data-aos="fade-right"
                    >
                      <img
                        src={item.img}
                        className="rounded img-fluid"
                        alt=""
                      />
                    </div>
                    <div
                      className="col-sm-8 align-self-center"
                      data-aos="fade-left"
                      data-aos-delay="200"
                    >
                      <div className="d-flex mb-3">
                        {[...Array(item.rating)].map((_, idx) => (
                          <i key={idx} className="bi bi-star-fill tick"></i>
                        ))}
                      </div>
                      <h3>{item.name}</h3>
                      <blockquote className="fst-italic">
                        "{item.comment}"
                      </blockquote>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testemonial;
