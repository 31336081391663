import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import "../../Patient.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { PatientContext } from "../../contex/PatientContex";
import { useParams } from "react-router-dom";
const EditReminder = () => {
  const [reminderName, setReminderName] = useState("");
  const { reminderId } = useParams();
  console.log(reminderId);
  // const [userId, setUserId] = useState("");
  const { user } = useContext(PatientContext);
  const userId = user.user_id;
  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months start from 0
    const dd = String(today.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  const [medicines, setMedicines] = useState([
    {
      user_id: userId,
      schedule_name: reminderName,
      medicine_id: "",
      medication_type_id: "",
      instructions: "",
      medication_schedule: "everyday",
      dose: "",
      no_of_days: 0,
      medication_start_date: getCurrentDate(),
      medication_end_date: "",
      reminderTimes: [""],
      medication_schedule_specific_week: [],
    },
  ]);
  useEffect(() => {
    if (userId) {
      getReminders();
    }
  }, []);

  const getReminders = async () => {
    try {
      const access_token = localStorage.getItem("access_token");
      if (!access_token) return;

      const config = {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/reminders/reminderByReminderId/${atob(
          reminderId
        )}`,
        config
      );
      if (response.status === 200) {
        setMedicines(response.data.reminders);
        console.log(response.data)
        setReminderName(response.data.reminders[0].schedule_name);
      }
    } catch (error) {
        console.log(error);
    }
  };
  // Update medicines when reminderName changes
  useEffect(() => {
    setMedicines((prevMedicines) =>
      prevMedicines.map((medicine) => ({
        ...medicine,
        schedule_name: reminderName,
      }))
    );
  }, [reminderName]);

  const [suggestions] = useState([
    "Headache",
    "Migraine",
    "Fever",
    "Cough",
    "Cold",
    "Flu",
    "Sore Throat",
    "Stomachache",
    "Nausea",
    "Diarrhea",
    "Constipation",
    "Heartburn",
    "Allergies",
    "Asthma",
    "Bronchitis",
    "Pneumonia",
    "Sinusitis",
    "Arthritis",
    "Back Pain",
    "Muscle Strain",
    "Sprain",
    "Fracture",
    "High Blood Pressure",
    "Low Blood Pressure",
    "Diabetes",
    "High Cholesterol",
    "Thyroid Disorders",
    "Anxiety",
    "Depression",
    "Stress",
    "Insomnia",
    "Sleep Apnea",
    "Obesity",
    "Alzheimer's Disease",
    "Dementia",
    "Stroke",
    "Heart Disease",
    "Cancer",
    "HIV/AIDS",
    "Sexually Transmitted Infections (STIs)",
    "Urinary Tract Infection (UTI)",
    "Kidney Stones",
    "Liver Disease",
  ]);

  const daysOptions = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
  ];

  const handleAddMedicine = () => {
    if (medicines.length > 10) {
      toast.warning("Maximum 10 medicines can be added");
      return;
    }
    setMedicines([
      ...medicines,
      {
        user_id: userId,
        schedule_name: reminderName,
        medicine_id: "",
        medication_type_id: "",
        instructions: "",
        medication_schedule: "everyday",
        dose: "",
        no_of_days: 0,
        medication_start_date: getCurrentDate(),
        medication_end_date: "",
        reminderTimes: [""],
        medication_schedule_specific_week: [],
      },
    ]);
  };

  const handleRemoveMedicine = (index) => {
    if (medicines.length === 1) {
      toast.warning("At least add one   medicine ");
      return;
    }
    const newMedicines = [...medicines];
    newMedicines.splice(index, 1);
    setMedicines(newMedicines);
  };

  const handleMedicineChange = (index, name, value) => {
    const newMedicines = [...medicines];
    newMedicines[index][name] = value;
    setMedicines(newMedicines);
  };

  const handleTimeChange = (medIndex, timeIndex, value) => {
    const newMedicines = [...medicines];
    newMedicines[medIndex].reminderTimes[timeIndex] = value;
    setMedicines(newMedicines);
  };

  const handleAddTime = (medIndex) => {
    const newMedicines = [...medicines];
    newMedicines[medIndex].reminderTimes.push("");
    setMedicines(newMedicines);
  };

  const handleRemoveTime = (medIndex, timeIndex) => {
    // const newMedicines = [...medicines];
    // newMedicines[medIndex].reminderTimes.splice(timeIndex, 1);
    // setMedicines(newMedicines);
    const newMedicines = [...medicines];
    if (newMedicines[medIndex].reminderTimes.length === 1) {
      toast.warning("At least one time is required");
      return;
    }
    newMedicines[medIndex].reminderTimes.splice(timeIndex, 1);
    setMedicines(newMedicines);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // const formData = {
    //   formdata: {
    //     reminderName,
    //     id: userId,
    //     medicines,
    //   },
    // };
    // console.log(formData);
    const formData = {
      medicines,
    };
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const res = await axios.post(
        `${process.env.REACT_APP_BASEURL}/api/reminders/savereminder`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 201) {
        toast.success(res.data.message);
        setReminderName("");

        setMedicines([
          {
            user_id: userId,
            schedule_name: reminderName,
            medicine_id: "",
            medication_type_id: "",
            instructions: "",
            medication_schedule: "everyday",
            dose: "",
            no_of_days: 0,
            medication_start_date: getCurrentDate(),
            medication_end_date: "",
            reminderTimes: [""],
            medication_schedule_specific_week: [],
          },
        ]);
      } else {
        toast.error(res.data.error);
      }
    } catch (error) {
      toast.error(error.response?.data?.error?.message || "An error occurred");
    }
  };
  const handleDaysChange = (medIndex, selectedOptions) => {
    const newMedicines = [...medicines];
    newMedicines[medIndex].medication_schedule_specific_week = selectedOptions;
    // ? selectedOptions.map((option) => option.value)
    // : [];
    setMedicines(newMedicines);
  };

  const [med, setMed] = useState([]);

  const handleMedSearch = async (medIndex, value) => {
    const newMedicines = [...medicines];
    newMedicines[medIndex].medicineName = value;
    setMedicines(newMedicines);
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/reminders/medicines?search=${value}&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMed(res.data.medicines);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error);
    }
  };
  const handleMedSelection = (medIndex, value) => {
    if (!med || !Array.isArray(med) || med.length === 0) {
      toast.info(
        "Please wait for the medicine list to load or try again later"
      );
      return;
    }

    if (!value || value === "") {
      toast.warning("Please select a medicine");
      return;
    }
    const selectedMed = med.find((medication) => medication.name === value);
    if (selectedMed) {
      // handleMedicineChange(medIndex, "medicineName", selectedMed.name);
      handleMedicineChange(medIndex, "medicine_id", selectedMed.id); // Set medicineId
      handleMedicineChange(
        medIndex,
        "medication_type_id",
        selectedMed.pack_size_label
      );
    }
  };
  return (
    <div className="p-3">
      <div className="p-4 rounded shadow-sm border">
        <h3 className="notes-heading p-2 rounded fw-semibold text-center">
          Schedule your Medicine
        </h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-3 col-md-6">
            <label htmlFor="reminderName" className="form-label">
              Schedule Name
            </label>
            <input
              type="text"
              required
              placeholder=" Diabites / High Blood Pressure/  Back Pain / Headache"
              className="form-control"
              id="reminderName"
              value={reminderName}
              onChange={(e) => setReminderName(e.target.value)}
              list="suggestionList"
            />
            <div className="" style={{ maxHeight: "200px" }}>
              <datalist id="suggestionList">
                {suggestions.map((suggestion, index) => (
                  <option key={index} value={suggestion} />
                ))}
              </datalist>
            </div>
          </div>
          <div className="pt-0 ps-3 pe-3 pb-3 ">
            {medicines.map((medicine, medIndex) => (
              <div key={medIndex} className="mb-3 border p-3 rounded row">
                <div className="mb-3 col-md-4">
                  <label className="form-label">
                    Medicine Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    required
                    className="form-control"
                    list="medications"
                    value={medicine.medicineName}
                    onChange={(e) => handleMedSearch(medIndex, e.target.value)}
                    onBlur={(e) => handleMedSelection(medIndex, e.target.value)}
                  />
                  <datalist id="medications">
                    {med &&
                      med.map((medication) => (
                        <option key={medication.id} value={medication.name} />
                      ))}
                  </datalist>
                </div>
                <div className="mb-3 col-md-4">
                  <label className="form-label">
                    Medicine Type <span className="text-danger">*</span>
                  </label>

                  <select
                    className="form-select"
                    // required
                    // onChange={(e) =>
                    //   handleMedSelection(
                    //     medIndex,
                    //     "medication_type_id",
                    //     e.target.value
                    //   )
                    // }
                    value={medicine.medication_type_id}
                  >
                    <option value="">{medicine.medication_type_id}</option>
                    {/* {med &&
                    med.map((medType) => (
                      <option selected
                        key={medType.id}
                        value={medType.pack_size_label}
                      >
                        {medType.pack_size_label}
                      </option>
                    ))} */}
                  </select>
                </div>

                <div className="mb-3 col-md-4">
                  <label className="form-label">End Date</label>
                  <input
                    type="date"
                    min={getCurrentDate()}
                    className="form-control"
                    value={medicine.endDate}
                    onChange={(e) =>
                      handleMedicineChange(
                        medIndex,
                        "medication_end_date",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label">Dose</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=" 500 MG / 2 Capsule /1 tabelSpoon"
                    value={medicine.dose}
                    onChange={(e) =>
                      handleMedicineChange(medIndex, "dose", e.target.value)
                    }
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label">Any Instruction</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="  Before Meals/ After meals / Orally  "
                    value={medicine.instructions}
                    onChange={(e) =>
                      handleMedicineChange(
                        medIndex,
                        "instructions",
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="col-md-3 mb-3">
                  <label htmlFor="" className="form-label">
                    Medication Schedule
                  </label>
                  <select
                    className="form-select"
                    onChange={(e) =>
                      handleMedicineChange(
                        medIndex,
                        "medication_schedule",
                        e.target.value
                      )
                    }
                  >
                    <option value="everyday">Every Day</option>
                    <option value="week_day">Specific Days of Week </option>
                    <option value="x_days">Every X days </option>
                  </select>
                </div>
                {medicine.medication_schedule === "week_day" && (
                  <div className="mb-3 col-md-3">
                    <label className="form-label">Days</label>
                    <Select
                      options={daysOptions}
                      isMulti
                      value={medicine.medication_schedule_specific_week.map(
                        (day) => ({
                          label: day,
                          value: day,
                        })
                      )}
                      onChange={(selected) =>
                        handleDaysChange(
                          medIndex,
                          selected ? selected.map((option) => option.value) : []
                        )
                      }
                    />
                  </div>
                )}

                {medicine.medication_schedule === "x_days" && (
                  <div className="mb-3 col-md-3">
                    <label className="form-label">Number of Days</label>
                    <input
                      type="number"
                      className="form-control"
                      min={0}
                      max={100}
                      value={medicine.no_of_days}
                      onChange={(e) =>
                        handleMedicineChange(
                          medIndex,
                          "no_of_days",
                          e.target.value
                        )
                      }
                    />
                  </div>
                )}
                <div className="mb-3 col-md-3 d-flex align-items-center gap-1">
                  <div>
                    <label className="form-label">
                      Times <span className="text-danger">*</span>
                    </label>
                    {medicine.reminderTimes.map((time, timeIndex) => (
                      <div key={timeIndex} className="d-flex mb-1">
                        <input
                          type="time"
                          required
                          className="form-control"
                          value={time}
                          onChange={(e) =>
                            handleTimeChange(
                              medIndex,
                              timeIndex,
                              e.target.value
                            )
                          }
                        />
                        <button
                          type="button"
                          className=" ms-2 px-2  rounded-circle  bg-danger-subtle border-0"
                          onClick={() => handleRemoveTime(medIndex, timeIndex)}
                        >
                          <FontAwesomeIcon
                            icon={faMinus}
                            className="text-danger m-0"
                          />
                        </button>
                      </div>
                    ))}
                  </div>
                  <div>
                    <button
                      type="button"
                      className="px-2 py-1 m-0  mt-4 rounded-circle  bg-primary-subtle border-0"
                      onClick={() => handleAddTime(medIndex)}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="text-success m-0"
                      />
                    </button>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="d-flex justify-content-end align-items-center ">
                    <button
                      type="button"
                      className="primary-btn "
                      onClick={() => handleRemoveMedicine(medIndex)}
                    >
                      Remove Medicine
                    </button>
                    <button
                      type="button"
                      className="primary-btn "
                      onClick={handleAddMedicine}
                    >
                      Add Medicine
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <button type="submit" className="primary-btn px-3 py-2">
            Submit
          </button>
        </form>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default EditReminder;
