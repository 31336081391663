import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";

import { Link } from "react-router-dom";

const Contact = () => {
    
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = async (data) => {
        try {
            // Implement your form submission logic here
            console.log(data);
            // Reset the form after successful submission
            reset();
            // Show success notification
            toast.success("Form submitted successfully!", {
                position: "top-center"
            });
        } catch (error) {
            // Handle form submission errors
            console.error("Form submission error:", error);
            // Show error notification
            toast.error("Failed to submit form. Please try again later.", {
                position: "top-center"
            });
        }
    };


    return (
        <div>
            <Header />
            <section id="contactus" style={{ paddingTop: "56px" }}>
                <div className="container p-3">
                    <div className="row pt-4 contactus-border">
                        <div className="col-md-7 p-4  bg-white rounded  shadow-sm">
                            <h1 className="fw-bold">Contact us for a demo and information</h1>
                            <p className="mb-5">
                                Fill in the form below if you are curious about Lifeness and
                                want more information.
                            </p>
                         
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="firstName" className="form-label">
                                            First name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="firstName"
                                            {...register("firstName", {
                                                required: true,
                                                minLength: 2,
                                                maxLength: 30,
                                            })}
                                        />
                                        {errors.firstName &&
                                            errors.firstName.type === "required" && (
                                                <span className="text-danger">This field is required</span>
                                            )}
                                        {errors.firstName &&
                                            errors.firstName.type === "minLength" && (
                                                <span className="text-danger">First name should be at least 2 characters</span>
                                            )}
                                        {errors.firstName &&
                                            errors.firstName.type === "maxLength" && (
                                                <span className="text-danger">First name should not exceed 30 characters</span>
                                            )}
                                    </div>
                                    <div className="col">
                                        <label htmlFor="lastName" className="form-label">
                                            Last name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="lastName"
                                            {...register("lastName", {
                                                required: true,
                                                minLength: 2,
                                                maxLength: 30,
                                            })}
                                        />
                                        {errors.lastName && errors.lastName.type === "required" && (
                                            <span className="text-danger">This field is required</span>
                                        )}
                                        {errors.lastName &&
                                            errors.lastName.type === "minLength" && (
                                                <span className="text-danger">Last name should be at least 2 characters</span>
                                            )}
                                        {errors.lastName &&
                                            errors.lastName.type === "maxLength" && (
                                                <span className="text-danger">Last name should not exceed 30 characters</span>
                                            )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="email" className="form-label">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            {...register("email", {
                                                required: true,
                                                pattern: /^\S+@\S+$/i,
                                            })}
                                        />
                                        {errors.email && errors.email.type === "required" && (
                                            <span className="text-danger">This field is required</span>
                                        )}
                                        {errors.email && errors.email.type === "pattern" && (
                                            <span className="text-danger">Invalid email address</span>
                                        )}
                                    </div>
                                    <div className="col">
                                        <label htmlFor="phone" className="form-label">
                                            Phone
                                        </label>
                                        <input
                                            type="tel"
                                            className="form-control"
                                            id="phone"
                                            {...register("phone", {
                                                required: true,
                                                minLength: 10,
                                                maxLength: 12,
                                            })}
                                        />
                                        {errors.phone && errors.phone.type === "required" && (
                                            <span className="text-danger">This field is required</span>
                                        )}
                                        {errors.phone && errors.phone.type === "minLength" && (
                                            <span className="text-danger">Phone number should be at least 10 digits</span>
                                        )}
                                        {errors.phone && errors.phone.type === "maxLength" && (
                                            <span className="text-danger">Phone number should not exceed 12 digits</span>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="companyName" className="form-label">
                                            Clinic/ Hospital Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="companyName"
                                            {...register("companyName", { required: true })}
                                        />
                                        {errors.companyName && <span className="text-danger">This field is required</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <label htmlFor="problemDescription" className="form-label">
                                            What can we help with? Please describe
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            id="problemDescription"
                                            {...register("problemDescription", { required: true })}
                                        ></textarea>
                                        {errors.problemDescription && (
                                            <span className="text-danger">This field is required</span>
                                        )}
                                    </div>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="wantDemo"
                                        {...register("wantDemo")}
                                    />
                                    <label className="form-check-label" htmlFor="wantDemo">
                                        Do you want a demo?
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="acceptTerms"
                                        {...register("acceptTerms", { required: true })}
                                    />
                                    <label className="form-check-label" htmlFor="acceptTerms">
                                        I accept the <Link to="/terms">terms and conditions</Link>
                                    </label>
                                    {errors.acceptTerms && (
                                        <span className="text-danger">You must accept the terms and conditions</span>
                                    )}
                                </div>
                                <button type="submit" className="btn btn-primary2 mt-3 px-3 py-2">
                                    Submit <i className="bi bi-send text-white p-1"></i>
                                </button>
                            </form>
                        </div>
                        <div className="col-md-5 align-self-center text-center align-items-center p-4">
                            <img
                                src="assets/img/webimg/Contact.svg"
                                className="img-fluid rounded-3"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-p">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 align-self-center p-2 align-self-center">
                            <i className="bi bi-envelope-plus-fill  fs-2 text-danger-emphasis"></i>
                            <h3 className="">Email Us </h3>
                            <p>

                                <a href="mailto:Baelhealth.india@gmail.com">Baelhealth.india@gmail.com</a>
                            </p>
                        </div>
                        <div className="col-md-4 align-self-center p-2 align-self-center">
                            <i className="bi bi-telephone-forward-fill fs-2 text-danger-emphasis"></i>
                            <h3 className="">Phone </h3>
                            <p>

                                <a href="tel:+91 1204941137">+91 1204941137</a>
                            </p>
                        </div>
                        <div className="col-md-4 align-self-center p-2 align-self-center">
                            <i className=" bi bi-geo-alt-fill fs-2 text-danger-emphasis"></i>
                            <h3 className="">Offices </h3>
                            <p>
                                <a href="conatct@tecraki.io"> Ghaziabad U.P (201010)</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <ToastContainer />
        </div>
    );
};

export default Contact;
