import React,{useState , useEffect}from 'react'
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';

const Expenses = () => {
  const [expenses, setExpenses] = useState([]);
  const [typeFilter, setTypeFilter] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    fetchExpenses();
  }, []);

  const fetchExpenses = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASEURL}/api/expenses`);
      if (res.status === 200) {
        setExpenses(res.data);
        calculateTotalAmount(res.data);
      }
    } catch (error) {
      console.error('Error fetching expenses:', error);
    }
  };

  const calculateTotalAmount = (expenses) => {
    const total = expenses.reduce((acc, expense) => acc + expense.amount, 0);
    setTotalAmount(total);
  };

  const handleTypeFilterChange = (event) => {
    setTypeFilter(event.target.value);
  };

  const columns = [
    { field: 'type', headerName: 'Type', width: 150 },
    { field: 'label', headerName: 'Label', width: 250 },
    { field: 'amount', headerName: 'Amount', width: 150 },
  ];

  const filteredExpenses = typeFilter ? expenses.filter(expense => expense.type === typeFilter) : expenses;

  return (
    <>
       <div className=" p-3 ">
         <h1 className='notes-heading p-2 text-center rounded'>Expenses</h1>
         <div className="p-2 rounded shadow-sm  border">
         <form action="" className="row">
         <div className="col-md-6">
            <label htmlFor="" className="form-label">Enter Label</label>
             <input type="text" name="" id="" className="form-control" />
          </div>
          <div className="col-md-6">
            <label htmlFor="" className="form-label">Type of Expenses</label>
            <select name="" id="" className='form-select'>
              <option value="Lab fees"> Lab Fees</option>
              <option value="Doctor fees"> Doctor Fees</option>
              <option value="Medication"> Medication Fees</option>
              <option value="Other"> Other</option>
            </select>
          </div>
      
          <div className="col-md-6">
            <label htmlFor="" className="form-label">Enter Amount</label>
             <input type="number" name="" id="" className="form-control" />
          </div>
         </form>
         </div>
         <div className="p-3 shadow-sm mt-2 " style={{ height: expenses.length === 0 ? 200 : 'auto' }}>
         <DataGrid
            rows={filteredExpenses}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
          />
        </div>
        <div className="p-2 shadow-sm mt-2">
          <p>Total Amount: {totalAmount}</p>
        </div>
         </div>

     
    </>
  )
}

export default Expenses