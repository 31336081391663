import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import "../../Patient.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { PatientContext } from "../../contex/PatientContex";
import { BsCheckLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const Notes = () => {
  const baseurl = process.env.REACT_APP_BASEURL;
  const [time, setTime] = useState();
  const [date, setDate] = useState();
  const [data, setData] = useState([]);
  const { user, setuser } = useContext(PatientContext);

  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  // Fetch the user ID from localStorage when the component mounts
  useEffect(() => {
    const id = localStorage.getItem("userId");
    const currentDate = new Date();
    setTime(currentDate.toLocaleTimeString());
    setDate(
      `${currentDate.getDate()}/${
        currentDate.getMonth() + 1
      }/${currentDate.getFullYear()}`
    );
    if (id) {
      setUserId(id);
    } else {
      console.error("User ID not found in localStorage");
    }
  }, []);

  // Fetch notes when userId is set
  useEffect(() => {
    if (userId) {
      getNotes();
    }
  }, [userId]);

  const labelColors = {
    Emergency: "bg-danger-subtle text-black",
    General: "bg-success-subtle text-black",
    Mood: "bg-primary-subtle text-black",
    Allergy: "bg-info-subtle text-black",
    // Add more mappings as needed
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const getNotes = async () => {
    try {
      const token = localStorage.getItem("access_token");
      // const token = JSON.stringify(localStorage.getItem("access_token"));
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const res = await axios.get(`${baseurl}/api/notes/getNotes/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status === 200) {
        console.log(res.data.notes);
        setData(res.data.notes); // Set the data state to the fetched data
      } else {
        console.log("Failed to fetch data", res.status, res.statusText);
      }
    } catch (error) {
      // console.error("Error fetching notes", error);
      toast.error(error.response.data.error.message + "please login again");
      setTimeout(() => {
        if (error.request.status === 401) {
          navigate("/plogin");
        }
      }, 1000);
    }
  };

  const onSubmit = async (formData) => {
    formData.note_date = date + ",  " + time;
    formData.user_id = userId;

    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const res = await axios.post(`${baseurl}/api/notes/saveNote`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        toast.success("Note saved successfully");
        reset();
        getNotes();
      } else {
        console.log("Failed to save note", res.status, res.statusText);
      }
    } catch (error) {
      toast.error(error.response.data.error.message + "please login again");
      setTimeout(() => {
        if (error.request.status === 401) {
          navigate("/plogin");
        }
      }, 1000);
    }
  };

  const deleteNotes = async (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this note?"
    );
    if (isConfirmed) {
      try {
        const token = localStorage.getItem("access_token");
        if (!token) {
          throw new Error("Authorization token not found");
        }
        const res = await axios.delete(
          `${baseurl}/api/notes/deleteNote/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 200) {
          console.log(res);
          toast.success("Note deleted successfully");
          getNotes();
        } else {
          console.log("Failed to delete note", res.status, res.statusText);
        }
      } catch (error) {
        console.error("Error deleting note", error);
        toast.error("Note not deleted successfully");
      }
    } else {
      toast.warn("Note is not deleted");
    }
  };

  return (
    <>
      <div className=" p-3  m-2 rounded border bg-body-tertiary">
        <h4 className=" text-center fw-semibold mb-3 notes-heading p-3 rounded">
          Add Notes
        </h4>
        <hr />
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-4">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  General Information
                </label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="label_id"
                  {...register("label_id", {
                    required: "true",
                  })}
                >
                  <option value="General" className="text-success">
                    General
                  </option>
                  <option value="Mood" className="text-primary">
                    Mood
                  </option>
                  <option value="Emergency" className="text-danger">
                    Emergency
                  </option>
                  <option value="Side Effects" className="text-warning">
                    Side Effect
                  </option>
                  <option value="Allergy" className="text-info">
                    Allergy
                  </option>
                </select>
              </div>
              <div className="mb-3 col-md-5">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Note Entry
                </label>
                <textarea
                  type="text"
                  rows={2}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder=" Notes "
                  name="note_content"
                  {...register("note_content", {
                    required: "required",
                  })}
                />
              </div>

              <div className="col-md-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Time and Date
                </label>
                <p className="fw-semibold">
                  {time} , {date}
                </p>
              </div>
            </div>
            <div className="mt-3">
              <button className=" btn btn-outline-primary d-flex align-item-center p-2 ">
                Add
                <FontAwesomeIcon icon={faPlus} className="  p-1 ms-1" />
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="m-2 p-3 rounded border rounded bg-">
        <h3 className="notes-heading text-center  p-3 rounded">Notes</h3>
        <hr />
        {data.map((note) => (
          <div
            key={note.note_id}
            className="row p-2 border rounded shadow-sm  bg-body-tertiary note-back m-1"
          >
            <div className="col-sm-11 ">
              <div className="d-flex">
                <p
                  className={`ps-4 pe-4 pt-1 pb-1 rounded border  ${
                    labelColors[note.label_id] || "bg-warning-subtle"
                  } justify-content`}
                  style={{ display: "inline-block", maxWidth: "fit-content" }}
                >
                  {note.label_id}
                </p>
                <p className=" ms-4 fw-medium">{note.note_date}</p>
              </div>
              <p className="fw-medium">{note.note_content}</p>
            </div>
            <div className="col-sm-1 d-flex align-self-center">
              <button className="btn" onClick={() => deleteNotes(note.note_id)}>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  className="text-danger fs-4"
                />
              </button>
            </div>
          </div>
        ))}
      </div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Notes;
