
import axios from 'axios';
async function regSw() {
  if ('serviceWorker' in navigator) {
    const url = `${process.env.PUBLIC_URL}/sw.js`;
    try {
      const reg = await navigator.serviceWorker.register(url, { scope: '/' });
      console.log('Service Worker registered', { reg });
      return reg;
    } catch (error) {
      console.error('Service Worker registration failed', error);
      throw error;
    }
  } else {
    throw new Error('ServiceWorker is not supported in this browser');
  }
}

async function subscribe(serviceWorkerReg) {
  try {
    let subscription = await serviceWorkerReg.pushManager.getSubscription();
    console.log('Current subscription', { subscription });

    if (!subscription) {
      // Check if the user has granted permission for notifications
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        // Permission granted, proceed with subscription
        const applicationServerKey =  process.env.PUBLIC_VAPID_KEY;
        // const applicationServerKey = urlBase64ToUint8Array(`${process.env.PUBLIC_VAPID_KEY}`);
        subscription = await serviceWorkerReg.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey,
        });
        console.log('New subscription', { subscription });

        // Show a notification upon successful subscription
        showNotification("Welcome to our app!", "You are now subscribed to notifications");

        // Send subscription to server
        await axios.post('/subscribe', subscription);
        console.log('Subscription sent to server');
      } else {
        console.log('Permission for notifications denied');
      }
    }
  } catch (error) {
    console.error('Subscription failed', error);
    throw error;
  }
}

function showNotification(title, message) {
  if ('Notification' in window && Notification.permission === 'granted') {
    new Notification(title, { body: message });
  }
}


function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4)
    // eslint-disable-next-line
    const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/")
  
    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }
  
export { regSw, subscribe };
