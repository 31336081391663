import React, { useEffect, useState, useContext } from "react";
import "./Layout.css";
import { Link, useParams } from "react-router-dom";
import { Outlet, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import profile from "../../assets/images/profile.png";
import {
  faBell,
  faBookMedical,
  faVialCircleCheck,
  faWallet,
  faCalendarAlt,
  faUser,
  faPills,
  faListCheck,
  faGear,
  faPowerOff,
  faPeopleRoof,
  faHeartPulse,
  faM,
  faAddressCard,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "../../assets/images/logo1.png";
import Footer from "../../components/Footer";
import axios from "axios";
import { PatientContext } from "../contex/PatientContex";

const Layout = () => {
  const navigate = useNavigate();
  const [family, setFamily] = useState([]);
  const { user, setUser} = useContext(PatientContext);
  const authToken = localStorage.getItem("access_token");
  // const { id } = useParams();
  // console.log(id);
  const id=user.user_id;
  // Check if authToken exists
  useEffect(() => {
    if (!authToken) {
      navigate("/plogin");
    }
  }, [authToken, navigate]);

  // const user_id = user?.user_id;

  const handlelogout = () => {
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    if (id) {
      fetchuser();
      fetchFamily();
    }
  }, [id]);
  // let id =  user.user_id;
  const fetchFamily = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/familyMember/getFamily/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        // console.log(response.data.familyMembers);
        setFamily(response.data.familyMembers);
      }
      // console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchuser = async () => {
    // try {
    //   const token = localStorage.getItem("access_token");
    //   if (!token) {
    //     throw new Error("Authorization token not found");
    //   }
    //   const response = await axios.get(
    //     `${process.env.REACT_APP_BASEURL}/api/user/getUser/${atob(id)}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    //   if (response.status === 200) {
    //     // setUser(response.data.patient);
    //     setUser(response.data.user);
    //     console.log(response.data);
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const tab = [
    {
      icon: faBookMedical,
      name: "Health Record",
      link: "/healthrecord",
    },
    {
      icon: faVialCircleCheck,
      name: "Test Results",
      link: "/test",
    },
    {
      icon: faHeartPulse,
      name: "Vitals",
      link: `/vitals/${btoa(id)}`,
    },
    {
      icon: faWallet,
      name: "Expenses",
      link: "/expenses",
    },
    {
      icon: faCalendarAlt,
      name: "Appointments",
      link: "/appointment",
    },
    {
      icon: faListCheck,
      name: "Notes",
      link: "/notes",
    },
    {
      icon: faPills,
      name: "Medicine Scheduler",
      link: "/pillscheduler",
    },
    {
      icon: faPills,
      name: "Medicine Reminders",
      link: "/medication",
    },
  ];

  const switchAccount = async (fId) => {
    // try {
    //   // const res = await axios.get(
    //   //   `${process.env.REACT_APP_BASEURL}/api/familyMember/getdetails/${fId}`
    //   // );
    //   //   if(res.status===200){
    //   //     console.log(res.data);
    //   //     navigate("/vitals")
    //   //   }
    //    setUser({id:fId});
    //   //  console.log(fId);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  return (
    <div className="layout">
      <div className="row">
        <div className="col-2 sidebar shadow-sm">
          <div>
            <img
              src={Logo}
              alt=" logo imae"
              className=" img-fluid  sidebar-logo"
            />
          </div>
          <hr />
          <div>
            <ul className="list-item p-2">
              {tab.map((item) => (
                <Link to={item.link} className="text-decoration-none">
                  <li
                    key={item.name}
                    className="text-black  d-flex align-items-center justify-content-start p-2 Link"
                  >
                    <FontAwesomeIcon
                      icon={item.icon}
                      className="fs-3 icons text-body "
                    />
                    <p className="list-text m-0">&nbsp; {item.name}</p>
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-10 navabar ps-0">
          <div className="navbar1 shadow-sm ms-2 ">
            <ul class="nav justify-content-end  nav-items me-3">
              <li class="nav-item text-black fw-semibold">
                <Link to="/blog" className="text-decoration-none text-black">
                  Blog
                </Link>
              </li>
              <li class="nav-item">
                <div class="dropdown ms-3 me-3">
                  <Link
                    Link
                    to=""
                    class="text-black  text-decoration-none fw-semibold "
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon icon={faUser} />
                    <span className="ms-1"> My Account</span>
                  </Link>
                  <ul class="dropdown-menu">
                    <li>
                      <Link
                        class="dropdown-item"
                        to={`/editprofile/${btoa(id)}`}
                      >
                        <FontAwesomeIcon icon={faGear} /> Edit Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        class="dropdown-item"
                        to={`/myprofile/${btoa(id)}`}
                      >
                        <FontAwesomeIcon icon={faAddressCard} /> My Profile
                      </Link>
                    </li>
                    <li>
                      <Link class="dropdown-item" to="/family/viewmember">
                        <FontAwesomeIcon icon={faPeopleRoof} /> Family Profile
                      </Link>
                    </li>
                    <li>
                      <Link class="dropdown-item" href="#">
                        <FontAwesomeIcon icon={faBell} /> Notification
                      </Link>
                    </li>
                    <li>
                      <button
                        class="dropdown-item secondary-btn text-danger fw-semibold"
                        onClick={handlelogout}
                      >
                        <FontAwesomeIcon icon={faPowerOff} className="ms-1" />
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <div class="dropdown ms-3 me-3">
                  {/* <tooltip>See your profile and family member</tooltip> */}
                  <Link
                    Link
                    to=""
                    class="text-black  text-decoration-none fw-semibold "
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {user && user.profile_picture ? (
                      <>
                        <img
                          data-bs-toggle="tooltip"
                          title="See your profile and family member"
                          src={`${process.env.REACT_APP_IMAGEURL}/${user.profile_picture}`}
                          alt=""
                          className=" profile-img"
                        />
                      </>
                    ) : (
                      <>
                        <img src={profile} alt="" className=" profile-img" />
                      </>
                    )}
                  </Link>

                  <ul class="dropdown-menu">
                    {user && (
                      <li className="fw-semibold ps-4 fs-6">
                        {user.user_email}
                      </li>
                    )}

                    <hr />
                    <div className="scrollable-family-list">
                      {family.map((item) => (
                        <li className="list-width">
                          <button
                            class="dropdown-item d-flex align-item-center"
                            onClick={() => {
                              switchAccount(item.id);
                            }}
                          >
                            <img
                              key={item.name}
                              src={`${process.env.REACT_APP_BASEURL}/${item.profile_photo}`}
                              alt=""
                              className=" profile-img"
                            />
                            <div>
                              <p className=" d-flex  align-self-center ms-1 p-0 fw-semibold">
                                {item.name}
                              </p>
                              <p className="text-black ">
                                Patient Id:{" "}
                                <span className=" fw-semibold text-primary">
                                  {item.id}
                                </span>
                              </p>
                            </div>
                          </button>

                          <hr />
                        </li>
                      ))}
                    </div>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="content row">
          <div className="col-2"></div>
          <div
            className="col-10  content-col10"
            style={{
              marginTop: "80px",
            }}
          >
            <Outlet />
            {/* <Footer/> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
