import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import Aos from "aos";
import { Chrono } from "react-chrono";
import Testemonial from "../Testemonial";
const Clinic = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  const items = [
    { title: "Step 1" },
    { title: "Step 2" },
    { title: "Step 3" },
    { title: "Step 4" },
  ];
  return (
    <div>
      <Header />

      <section
        className="clinic-section"
        style={{ paddingTop: "66px" , minHeight:"700px"}}
        id="doctor"
      >
        <div className="container">
          <div className="row ">
            <div className="col-md-6  align-self-center">
              <h1 className="fw-bold">
                Efficient Practice Management between the patients and{" "}
                <span className="good-text">health</span> provides.
              </h1>
              <p className="fw-semibold">
                Bael Health system enables clinics to deliver high-quality
                patient-centered care while optimizing operational efficiency
                and regulatory compliance.
              </p>
              <div className=" d-flex mt-4 gap-1">
                <Link to="/contact" className="btn btn-primary2">
                  Contact us for a demo
                </Link>
              </div>
            </div>
            <div className="col-md-6  align-self-center" data-aos="fade-left">
              <img
                src="assets/img/webimg/img2.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section p-4">
        <div className="container">
          <h1 className="section-heading text-center text-justify p-4 ">
            We care equally for the needs of Doctors & Patients and support you
            throughout your healthcare journey.
          </h1>
        </div>
      </section>
      <section className="patient-stripe">
        <div className="container " data-aos="fade-right">
          <div className="row  stripe-bar">
            <div className="col-sm-3 ">
              <i className="bi bi-bar-chart text-danger-emphasis fs-1"> X5</i>
              <p> Better Care Delivery</p>
            </div>
            <div className="col-sm-3">
              <i className="bi bi-emoji-smile  text-danger-emphasis fs-1">
                86%
              </i>

              <p>Happy Patients</p>
            </div>
            <div className="col-sm-3">
              <i className="bi bi-people-fill text-danger-emphasis fs-1">
                1000 +
              </i>
              <p> Active Users</p>
            </div>
            <div className="col-sm-3">
              <i className="bi bi-pen text-danger-emphasis fs-1"></i>
              <p> E - Prescription</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row p-4 ">
            <div className="col-md-6" data-aos="fade-right">
              <img
                src="assets/img/webimg/clinic_svg2.svg"
                className="rounded img-fluid"
                alt=""
              />
            </div>
            <div className="col-md-6 align-self-center " data-aos="fade-left">
              <h2 className="section-heading pb-4">
                Clinics and hospitals prefer coordination with care with us!
              </h2>
              <ul className="unordered-li p-0">
                <li className="d-flex d-flex">
                  <i className="bi  bi-check-circle-fill tick fs-4 me-1">
                    &nbsp;
                  </i>
                  Improves efficiency and reduces administrative pressures. Go
                  Digital with Bael health.
                </li>
                <li className="d-flex">
                  <i className="bi  bi-check-circle-fill tick fs-4 me-1">
                    &nbsp;
                  </i>
                  Automated bill processing, Easy insurance claiming, hence
                  faster reimbursement.
                </li>
                <li className="d-flex ">
                  <i className="bi  bi-check-circle-fill tick fs-4 me-1">
                    &nbsp;
                  </i>
                  Safe information sharing with health providers and clinics.
                </li>

                <li className="d-flex">
                  <i className="bi  bi-check-circle-fill tick fs-4 me-1">
                    &nbsp;
                  </i>
                  Easy decision making based on data.
                </li>
                <li className="d-flex">
                  <i className="bi  bi-check-circle-fill tick fs-4 me-1">
                    &nbsp;
                  </i>
                  New clinics and establishments can easily adopt the
                  application.
                </li>
              </ul>
              <div
                className=" d-flex mt-4 gap-1 "
                data-aos="fade-right"
                aos-delay="300"
              >
                <Link to="/contact" className="btn btn-primary1">
                  Contact us for a demo
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5">
        <div className="container">
          <div className="row  scroll">
            <div
              className="col-md-6   div1-scroll d-flex flex-column justify-content-center align-items-center "
              data-aos="fade-right"
              id="secondDiv"
            >
              <h3 className="section-heading pb-3"> How to Get Started </h3>
              <Link to="/contact" className="btn btn-primary1">
                Contact us for a demo
              </Link>
            </div>
            <div
              className="col-md-6  timeline-container rounded "
              data-aos="fade-left"
              id="secondDiv"
              style={{ height: "100vh" }}
            >
              <div>
                <Chrono
                  mode="VERTICAL"
                  disableToolbar
                  items={items}
                  cardHeight="100%"
                  scrollable="true"
                  theme={{ primary: "maroon", secondary: "pink" }}
                >
                  <div className="col step-p event">
                    <span className="number">01</span>
                    <h3>Register</h3>
                    {/* <p>Receive an invite</p> */}

                    <img
                      src="assets/img/webimg/register.svg"
                      className="rounded img-fluid"
                      alt=""
                      style={{ height: "350px", width: "350px" }}
                    />
                  </div>
                  <div className="col step-p  event">
                    <span className="number">02</span>
                    <h3>Lets meet for setup your clinic</h3>
                    {/* <p>In Apple Store or Google Play</p> */}

                    <img
                      src="assets/img/webimg/doctor_svg2.svg"
                      className="rounded img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col step-p  event">
                    <span className="number">03</span>
                    <h3>Invite Patients</h3>
                    {/* <p>Remember to remember your pin code</p> */}

                    <img
                      src="assets/img/webimg/invite.svg"
                      className="rounded img-fluid"
                      style={{ height: "350px", width: "350px" }}
                      alt=""
                    />
                  </div>
                  <div className="col step-p  event">
                    <span className="number">04</span>
                    <h3>Result</h3>
                    {/* <p>Your health care provider will be in touch with you. Tip: Connect other apps to synch health data.</p> */}

                    <img
                      src="assets/img/webimg/result.svg"
                      className="rounded img-fluid"
                      style={{ height: "350px", width: "350px" }}
                      alt=""
                    />
                  </div>
                </Chrono>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row pt-4 pb-4">
            <div className="col-sm-5 align-self-center">
              <h1 className="section-heading pb-3"> FAQs</h1>

              <p className="fw-semibold"> Still have a question? Contact us.</p>
              <Link to="/contact" className="btn btn-primary1">
                Contact us
              </Link>
            </div>
            <div className="col-sm-7">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item p-4">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Is it easy to use the Bael health system for Clinical
                      purposes?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Yes, it is easy, simple and accessible.
                    </div>
                  </div>
                </div>

                <div class="accordion-item p-4">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-three"
                      aria-expanded="false"
                      aria-controls="flush-three"
                    >
                      What facilities can I receive through Bael health app?
                    </button>
                  </h2>
                  <div
                    id="flush-three"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Efficient access to patient information including medical
                      records, lab reports, prescriptions. It also helps in easy
                      documentation, billing and seamless communication.
                    </div>
                  </div>
                </div>

                <div class="accordion-item p-4">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-four"
                      aria-expanded="false"
                      aria-controls="flush-four"
                    >
                      Can I check family member details also in BAEL health ?
                    </button>
                  </h2>
                  <div
                    id="flush-four"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Yes, with the consent of the user it is accessible. It is
                      a central platform for Patients, Doctors & Clinics to
                      store & accessHealth Information.
                    </div>
                  </div>
                </div>

                <div class="accordion-item p-4">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-five"
                      aria-expanded="false"
                      aria-controls="flush-five"
                    >
                      How we can register new patient in BAEL health?
                    </button>
                  </h2>
                  <div
                    id="flush-five"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      First step is to sign up, then create a profile and then
                      you are good to go.
                    </div>
                  </div>
                </div>
                <div class="accordion-item p-4">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-six"
                      aria-expanded="false"
                      aria-controls="flush-six"
                    >
                      Is BAEL health have option to manage patient queue?
                    </button>
                  </h2>
                  <div
                    id="flush-six"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Yes, we highlight the time available time schedules for
                      the doctors specifically so the patients internet can make
                      appointments or ask suggestions just in one tap through
                      Bael health app.
                    </div>
                  </div>
                </div>
                <div class="accordion-item p-4">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-seven"
                      aria-expanded="false"
                      aria-controls="flush-seven"
                    >
                      Is BAEL health easy insurance claim from clinic
                      prospective?
                    </button>
                  </h2>
                  <div
                    id="flush-seven"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Yes, Bael health app have the feature of claiming
                      insurance easily. It makes the process of claims quicker
                      and allows insurers to review and approve them more
                      quickly.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testemonial></Testemonial>
      <Footer />
    </div>
  );
};

export default Clinic;
