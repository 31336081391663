import React, { useState, useEffect } from "react";
import axios from "axios";
import { faLocation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { DataGrid } from '@mui/x-data-grid';
const Testresults = () => {
     const [data, setData] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const columns = [
    { field: 'type', headerName: 'Patient Name', width: 250 },
    { field: 'label', headerName: 'Test Type', width: 250 },
    { field: 'lab', headerName: 'Location', width: 250 },
    { field: 'amount', headerName: 'Test Code ', width: 150 },
    { field: 'ewfvg', headerName: 'Action ', width: 150 },
  ];
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div className=" p-3    ">
      <div className=" p-3 shadow-sm rounded border ">
        <h2 className="my-4 p-2 notes-heading text-center rounded-1">Test Results</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="patientName" className="form-label">
              Patient Name:
            </label>
            <input
              type="text"
              className="form-control"
              id="patientName"
              {...register("patientName", {
                required: "Patient Name is required",
                minLength: {
                  value: 2,
                  message: "Patient Name must be at least 2 characters",
                },
                maxLength: {
                  value: 20,
                  message: "Patient Name should not exceed 20 characters",
                },
              })}
        
            />
            {errors.patientName && (
              <small className="text-danger">
                {errors.patientName.message}
              </small>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="testCategories" className="form-label">
              Test Categories:
            </label>
            <select
              className="form-select"
              id="testCategories"
              name="testCategories"
              {...register("testCategories", {
                required: "Test Category is required",
                minLength: {
                  value: 2,
                  message: "Test Category must be at least 2 characters",
                },
                maxLength: {
                  value: 20,
                  message: "Test Category should not exceed 20 characters",
                },
              })}
              
            >
              <option value="">Select Category</option>
              <option value="Category1">Category 1</option>
              <option value="Category2">Category 2</option>
              <option value="Category3">Category 3</option>
            </select>
            {errors.testCategories && (
              <small className="text-danger">
                {errors.testCategories.message}
              </small>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="testCode" className="form-label">
              Enter Test Code:
            </label>
            <input
              type="text"
              className="form-control"
              id="testCode"
              {...register("testCode", {
                required: "Test Code is required",
                minLength: {
                  value: 2,
                  message: "Test Code must be at least 2 characters",
                },
                maxLength: {
                  value: 20,
                  message: "Test Code should not exceed 20 characters",
                },
              })}
             
            />
            {errors.testCode && (
              <small className="text-danger">{errors.testCode.message}</small>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="mobileNo" className="form-label">
              Enter Mobile No.:
            </label>
            <input
              type="text"
              className="form-control"
              id="mobileNo"
              {...register("mobileNo", {
                required: "Mobile No. is required",
                minLength: {
                  value: 10,
                  message: "Mobile No. must be at least 10 characters",
                },
                maxLength: {
                  value: 10,
                  message: "Mobile No. should not exceed 10 characters",
                },
                pattern: {
                  value: /^[1-9]\d{9}$/,
                  message: "Mobile No. should start with 1-9",
                },
              })}
            />
            {errors.mobileNo && (
              <small className="text-danger">{errors.mobileNo.message}</small>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="location" className="form-label">
              Enter Location:
            </label>
            <input
              type="text"
              className="form-control"
              id="location"
              {...register("location", {
                required: "Location is required",
                minLength: {
                  value: 2,
                  message: "Location must be at least 2 characters",
                },
                maxLength: {
                  value: 20,
                  message: "Location should not exceed 20 characters",
                },
              })}
           
            />
            {errors.location && (
              <small className="text-danger">{errors.location.message}</small>
            )}
          </div>
          
          <div className="d-flex justify-content-start">
            <button type="submit" className=" primary-btn px-4">
              Submit
            </button>
          </div>
        </form>
      </div>
      <div className="mt-2 " style={{ height: data.length === 0 ? 200 : 'auto' }}>
      <DataGrid
            rows={data}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
          />
      </div>
    </div>
  );
};

export default Testresults;
