import React, { useEffect } from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Blogcard from "./Blogcard";
import AOS from "aos";

const Blog = () => {
  // const [blog, setBlog] = useState([]);

  useEffect(() => {
    AOS.init();
  }, []);

  const blogs = [
    {
      id: "1",
      blogimg:"assets/img/blog/EHR2.png",
      path: "/blogpage1",
      title: "Overall Health care with EHR System.",
      category: "A Better tomorrow with EHR",
      author: "Prashant Yadav",
      date: "May 23, 2024",
      profile: "assets/img/blog/ceo.jpg",
      content:
        "EHR stands for Electronic Health Records, which is a digital platform of patients' paper charts. Means, it allows storing entire health records in digital format and allows real-time sharing of data between doctors, patients & hospitals.....",
    },
    {
      id: "2",
      blogimg:"assets/img/blog/Healthrecord1.png",
      path: "/blogpage2",
      title:"How Having Access to Digital Health Records Can Result in Better Health Outcomes?",
      category: "Modern healthcare with Electronic Health Record system.",
      author: "Prashant Yadav",
      date: "June 1, 2024",
      profile: "assets/img/blog/ceo.jpg",
      content:
        "The revolutionary EHR (electronic health record) system has welcomed the new era with efficiency.....",
    },
    {
      id: "3",
      blogimg:"assets/img/blog/Ehr4.png",
      path: "/blogpage3",
      title:"Evolution of Digital Technologies in India’s Healthcare Domain.",
      category: "Digital technologies  role in reshaping India's medical landscape. ",
      author: "Prashant Yadav",
      date: "June 13, 2024",
      profile: "assets/img/blog/ceo.jpg",
      content:
        "Over the last few decades, digital technologies have undergone rapid changes that have changed our way of life, ..........",
    },
  ];

  return (
    <div>
      <Header />
      <section style={{ paddingTop: "76px" }} className="blog-page">
        <div className="row ">
          <div className="col-12 text-center blog-header pt-4">
            <div className="section-title mb-2 pb-2">
              <h1 className=" pb-4">Latest Blog &amp; News</h1>
              <p className="text-muted para-desc mx-auto mb-0">
                Our solution to make Digital Health Records accessible to
                everyone , anytime, anywhere.
              </p>
            </div>
          </div>
        </div>
        <div className="container pt-3">
          <div className="row row-cols-1 row-cols-md-3 g-4 mb-4">
            {blogs.map((blog) => (
              <div className="col-md-4" key={blog.id}>
                <Blogcard
                  title={blog.title}
                  blogimg={blog.blogimg}
                  category={blog.category}
                  content={blog.content}
                  author={blog.author}
                  date={blog.date}
                  profile={blog.profile}
                  path={blog.path}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Blog;
