import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import Footer from "../Footer";
import Header from "../Header";
import { Chrono } from "react-chrono";
import Testemonial from "../Testemonial";

const Patient = () => {
  const firstColumnRef = useRef(null);
  const secondColumnRef = useRef(null);

  useEffect(() => {
    AOS.init();
  }, []);
  const items = [{ title: "Step 1" }, { title: "Step 2" }, { title: "Step 3" }];

  return (
    <div>
      <Header />
      <section className="patients-section" style={{ paddingTop: "56px" }}>
        <div className="container front-section d-flex align-items-center">
          <div className="row p-4">
            <div className="col-md-6 align-self-center">
              <h1 className="fw-bold">
                {" "}
                Enhancing Patient Centered{" "}
                <span className="good-text">Care</span>{" "}
              </h1>
              <p className="fw-semibold">
                Welcome to Bael Health, where You can have Efficient
                Coordination with care. Explore our Platform’s exclusive
                features with easy access.
              </p>
              <div className="d-flex mt-4 gap-1">
                <Link to="#" className="dow-img">
                  <img
                    src="assets/img/app1.jpg"
                    className="img-fluid rounded-2 playstore"
                    alt=""
                  />
                </Link>
                <Link to="#" className="dow-img">
                  <img
                    src="assets/img/play1.jpg"
                    className="img-fluid rounded-2 playstore"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6 align-self-center rounded">
              <img src="assets/img/webimg/patient1.svg" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="section p-4">
        <div className="container">
          <h1 className="section-heading text-center text-justify p-4 ">
            Better healthcare experience & improve your health with Bael.
          </h1>
        </div>
      </section>
      <section className="pb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6 align-self-center" data-aos="fade-left">
              <h2 className="section-heading pb-4">
                {" "}
                Your health information at your fingertips.{" "}
              </h2>
              {/* <p> Bael provides a centralized storage center  for all your previous and present health  records , vitals & expenses</p> */}
              <ul className="unordered-li p-0">
                <li>
                  {" "}
                  <i className=" bi bi-check-circle-fill tick fs-4"> </i> Easy
                  access to medical records including vitals and status.{" "}
                </li>
                <li>
                  {" "}
                  <i className=" bi bi-check-circle-fill tick fs-4"> </i> Easy
                  communication between patients and health providers directly.{" "}
                </li>
                <li>
                  {" "}
                  <i className=" bi bi-check-circle-fill tick fs-4"> </i>
                  Efficient appointment scheduling, and regular reminders.{" "}
                </li>
                <li>
                  {" "}
                  <i className=" bi bi-check-circle-fill tick fs-4"> </i>Family
                  data records and sharing.
                </li>
                <li>
                  {" "}
                  <i className=" bi bi-check-circle-fill tick fs-4"> </i>
                  Digitalised and easy paper work.{" "}
                </li>
              </ul>
              <div className="d-flex mt-4 gap-1">
                <Link to="#" className="dow-img">
                  <img
                    src="assets/img/app1.jpg"
                    className="img-fluid rounded-2 playstore"
                    alt=""
                  />
                </Link>
                <Link to="#" className="dow-img">
                  <img
                    src="assets/img/play1.jpg"
                    className="img-fluid rounded-2 playstore"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-6" data-aos="fade-right">
              <img
                src="assets/img/webimg/2.png"
                className="rounded img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="pt-4 pb-4 ">
        <div className="container ">
          <div className="row scroll ">
            <div className="col-md-6 align-self-center div1-scroll">
              <h3 className="section-heading pb-3">
                {" "}
                Download to Get Started{" "}
              </h3>
              <div className="d-flex mt-4 gap-1">
                <Link to="#" className="dow-img">
                  <img
                    src="assets/img/app1.jpg"
                    className="img-fluid rounded-2 playstore"
                    alt=""
                  />
                </Link>
                <Link to="#" className="dow-img">
                  <img
                    src="assets/img/play1.jpg"
                    className="img-fluid rounded-2 playstore"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div
              className="col-md-6 timeline-container"
              style={{ height: "90vh" }}
            >
              <div>
                <Chrono
                  className="pb-4"
                  mode="VERTICAL"
                  disableToolbar
                  items={items}
                  cardHeight="100%"
                  scrollable="true"
                  theme={{ primary: "maroon", secondary: "pink" }}
                >
                  <div className=" step-p event">
                    <span className="number">01</span>
                    <h3>Download the app</h3>
                    <p>In Apple Store or Google Play</p>
                    <img
                      src="assets/img/webimg/undraw_download.svg"
                      className="rounded img-fluid step-images"
                      alt=""
                      style={{ height: "250px", width: "250px" }}
                    />
                  </div>
                  <div className=" step-p event">
                    <span className="number">02</span>
                    <h3>Set up an account</h3>
                    <p>Fill basic details </p>
                    <img
                      src="assets/img/webimg/setupAccount.svg"
                      className="rounded img-fluid  step-images"
                      alt=""
                      style={{ height: "250px", width: "250px" }}
                    />
                  </div>
                  <div className=" step-p event">
                    <span className="number">03</span>
                    <h3>Program start</h3>
                    <p>
                      Your health care provider will be in <br /> touch with
                      you.
                    </p>
                    <img
                      src="assets/img/webimg/programstart.svg"
                      className="rounded img-fluid  step-images"
                      alt=""
                      style={{ height: "250px", width: "250px" }}
                    />
                  </div>
                </Chrono>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row pt-4 pb-4">
            <div className="col-sm-5 align-self-center">
              <h1 className="section-heading pb-2">
                {" "}
                <span className="good-text fs-1">FAQ</span>s
              </h1>
              <p className="fw-semibold"> Still have a question? Contact us.</p>
              <Link to="/contact" className="btn btn-primary1">
                Contact us <i className="bi bi-arrow-right"></i>{" "}
              </Link>
            </div>
            <div className="col-sm-7">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item p-4">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      How we can download the Bael health App and is it
                      available for iPhone also ?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Yes, the Bael health app is available for iPhone users.
                      Bael Health App can be easily downloaded from the Apple
                      App Store and Google play store.
                    </div>
                  </div>
                </div>
                <div className="accordion-item p-4">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-three"
                      aria-expanded="false"
                      aria-controls="flush-three"
                    >
                      Is my health records safe and secure within the Bael
                      Health app?
                    </button>
                  </h2>
                  <div
                    id="flush-three"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Yes, Privacy and security is maintained. Any details
                      should be approved by the user first.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item p-4">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-four"
                      aria-expanded="false"
                      aria-controls="flush-four"
                    >
                      Can we see the availability of doctor and also schedule
                      appointments of doctors on Bael Heath.?{" "}
                    </button>
                  </h2>
                  <div
                    id="flush-four"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Yes, it is easily accessible and you can schedule
                      appointments based on your availability.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item p-4">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-five"
                      aria-expanded="false"
                      aria-controls="flush-five"
                    >
                      Does Beal Health have a facility to store and maintain an
                      entire family’s health record?{" "}
                    </button>
                  </h2>
                  <div
                    id="flush-five"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Yes, That is the most unique feature we have. Not only the
                      user but user can also maintain entire family health
                      history.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item p-4">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-six"
                      aria-expanded="false"
                      aria-controls="flush-six"
                    >
                      Do we share medical record to different other uses like
                      doctor view/Third party opinion etc?{" "}
                    </button>
                  </h2>
                  <div
                    id="flush-six"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Yes, We can, this depends on the user based in their
                      requirements.
                    </div>
                  </div>
                </div>
                <div className="accordion-item p-4">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-seven"
                      aria-expanded="false"
                      aria-controls="flush-seven"
                    >
                      Can we also store our expenses details also like doctor
                      fee, Lab invoice & medicine bill etc{" "}
                    </button>
                  </h2>
                  <div
                    id="flush-seven"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Yes, Through the feature of automated finances.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testemonial />
      <Footer />
    </div>
  );
};

export default Patient;
