import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import profile1 from "../../assets/images/profile.png";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PatientContext } from "../contex/PatientContex";

const EditProfile = () => {
  const baseurl = process.env.REACT_APP_BASEURL;
  const navigate = useNavigate();
  const { user, setUser } = useContext(PatientContext);
  const { userid } = useParams();
  const [profilePicUrl, setProfilePicUrl] = useState(profile1); // New state for profile picture URL
 
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState("");
  // console.log(userid);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const password = watch("user_password", "");
  const user_id = user?.user_Id; // Optional chaining to prevent errors

  useEffect(() => {
    if (userid) {
      fetchUser();
    }
  }, [userid]);
 

  const fetchUser = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }

      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/user/getUser/${atob(userid)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setUser(res.data.user);
        prepopulateForm(res.data.user);
        if (res.data.user.profile_picture) {
          setProfilePicUrl(
            `${process.env.REACT_APP_IMAGEURL}/${res.data.user.profile_picture}`
          );
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      if (error.request.status === 404) {
        toast.error("Unauthrized ");
        navigate("/plogin");
      }
    }
  };

  const prepopulateForm = (data) => {
    setValue("user_first_name", data.user_first_name);
    setValue("user_last_name", data.user_last_name);
    setValue("user_gender", data.user_gender);
    setValue("user_date_of_birth", data.user_date_of_birth);
    setValue("user_aadhar", data.user_aadhar);
    setValue("user_phone", data.user_phone);
    setValue("user_email", data.user_email);

    // You might not want to prepopulate the password fields
    // setValue("user_password", data.user_password);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileError(""); // Clear previous errors
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSize = 2 * 1024 * 1024; // 2 MB

      if (!validTypes.includes(file.type)) {
        setFileError("Only image files (JPEG, PNG, GIF) are allowed.");
      } else if (file.size > maxSize) {
        setFileError("File size must be less than 2 MB.");
      } else {
        setSelectedFile(file);
        setProfilePicUrl(URL.createObjectURL(file)); // Update profile picture preview
      }
    }
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("profile_picture", selectedFile);
    formData.append("user_first_name", data.user_first_name);
    formData.append("user_last_name", data.user_last_name);
    formData.append("user_gender", data.user_gender);
    formData.append("user_date_of_birth", data.user_date_of_birth);
    formData.append("user_aadhar", data.user_aadhar);
    formData.append("user_phone", data.user_phone);
    formData.append("user_email", data.user_email);
    formData.append("user_password", data.user_password);

    try {
      if (data.user_password !== data.confirmpassword) {
        toast.error("Passwords do not match");
        return;
      }

    
        const token = localStorage.getItem("access_token");
        if (!token) {
          throw new Error("Authorization token not found");
        }
        
      const res = await axios.put(`${baseurl}/api/user/editUser/${atob(userid)}`, formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
      );
      if (res.status === 200) {
        toast.success(res.data.message);
      
      }
    } catch (error) {
      console.log(error);
      // console.log("hit api of edit3 ")
      if(error.request.status===401){
        toast.error(" Unauthroized Login again");
         setTimeout(() => {
          
           navigate("/plogin");
         }, 1000);
        }
      // toast.error(error.response.data.error.message);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const [type, setType] = useState("password");

  const eyeTogle = () => {
    setShowPassword(!showPassword);
    setType(type === "password" ? "text" : "password");
  };

  return (
    <div className="p-3">
      <div className="p-4 bg-body-tertiary">
        <h4 className="text-start">Update Profile</h4>
        <form className="pt-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-sm-6">
              <label htmlFor="" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                {...register("user_first_name", {
                  required: {
                    value: true,
                    message: "First name is required",
                  },
                  minLength: {
                    value: 2,
                    message: "Please enter at least 2 characters",
                  },
                  maxLength: {
                    value: 20,
                    message: "First name should not exceed 20 characters",
                  },
                })}
              />
              {errors.user_first_name && (
                <small className="text-danger">
                  {errors.user_first_name.message}
                </small>
              )}
            </div>
            <div className="col-sm-6">
              <label htmlFor="" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                {...register("user_last_name")}
              />
            </div>
          </div>

          <div className="row">
            <div className="pt-3 col-md-6">
              <label htmlFor="" className="form-label">
                Gender
              </label>
              <br />
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="male"
                  value="Male"
                  {...register("user_gender", {
                    required: "please select gender",
                  })}
                />
                <label className="form-check-label" htmlFor="Male">
                  Male
                </label>
                {errors.user_gender && (
                  <small className="text-danger">
                    {errors.user_gender.message}
                  </small>
                )}
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="female"
                  value="Female"
                  {...register("user_gender", {
                    required: "Please select gender",
                  })}
                />
                <label className="form-check-label" htmlFor="Female">
                  Female
                </label>
              </div>
            </div>

            <div className="pt-3 col-6">
              <label htmlFor="" className="form-label">
                Date of Birth
              </label>
              <input
                type="date"
                className="form-control col-6"
                placeholder="Date of Birth"
                {...register("user_date_of_birth", {
                  required: "please enter your date of birth",
                })}
              />
              {errors.user_date_of_birth && (
                <small className="text-danger">
                  {errors.user_date_of_birth.message}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="pt-3 col-6">
              <label htmlFor="" className="form-label">
                Aadhar Number
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Aadhar No"
                {...register("user_aadhar", {
                  required: true,
                  maxLength: {
                    value: 12,
                    message: "Please enter a valid aadhar number",
                  },
                  minLength: {
                    value: 12,
                    message: "Please enter 12 digit aadhar number",
                  },
                  type: "number",
                })}
              />
              {errors.user_aadhar && (
                <small className="text-danger">
                  {errors.user_aadhar.message}
                </small>
              )}
            </div>
            <div className="pt-3 col-6">
              <label htmlFor="" className="form-label">
                Phone Number
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Phone Number"
                {...register("user_phone", {
                  required: "Please enter a valid phone number",
                  minLength: {
                    value: 10,
                    message: "Please enter a valid phone number",
                  },
                  maxLength: {
                    value: 10,
                    message: "Please enter a valid phone number",
                  },
                })}
              />
              {errors.user_phone && (
                <small className="text-danger">
                  {errors.user_phone.message}
                </small>
              )}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-sm-4 mt-2">
              <label htmlFor="" className="form-label">
                Please Upload Profile Picture
              </label>
              <input
                type="file"
                className="form-control"
                onChange={handleFileChange}
                placeholder="profile photo"
              />
              {fileError && <small className="text-danger">{fileError}</small>}
            </div>
            <div className="col-sm-4 d-flex justify-content-end mb-2">
              <img
                src={profilePicUrl}
                alt=""
                className="rounded-circle"
                style={{ height: "100px", width: "100px" }}
              />
            </div>
          </div>
          <div className="pt-3 row">
            <div className="col-8">
              <label htmlFor="" className="form-label">
                Email
              </label>
              <input
                type="email"
                disabled
                className="form-control"
                placeholder="Email"
                {...register("user_email", {
                  required: "please enter Valid email",
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                })}
              />
              {errors.user_email && (
                <small className="text-danger">
                  {errors.user_email.message}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="pt-3 col-6">
              <label htmlFor="" className="form-label">
                Password
              </label>
              <div className="d-flex">
                <input
                  type={type}
                  className="form-control"
                  placeholder="Password"
                  {...register("user_password", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password should be at least 8 characters",
                    },
                    maxLength: {
                      value: 15,
                      message: "Password should not exceed 15 characters",
                    },
                    pattern: {
                      value:
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                      message:
                        "Minimum eight characters, at least one letter, one number and one special character",
                    },
                  })}
                />
                <p className="py-2 m-0 ms-2">
                  {showPassword ? (
                    <FontAwesomeIcon onClick={eyeTogle} icon={faEye} />
                  ) : (
                    <FontAwesomeIcon onClick={eyeTogle} icon={faEyeSlash} />
                  )}
                </p>
              </div>
              {errors.user_password && (
                <small className="text-danger">
                  {errors.user_password.message}
                </small>
              )}
            </div>
            <div className="pt-3 col-6">
              <label htmlFor="" className="form-label">
                Confirm Password
              </label>
              <input
                type={type}
                className="form-control"
                placeholder="Confirm Password"
                {...register("confirmpassword", {
                  required: "Confirm Password is required",
                  validate: (value) =>
                    value === password || "Passwords do not match",
                })}
              />
              {errors.confirmpassword && (
                <small className="text-danger">
                  {errors.confirmpassword.message}
                </small>
              )}
            </div>
          </div>
          <div className="pt-3 col-3">
            <button className="primary-btn col-8">Submit</button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditProfile;
