import React, { useContext, useEffect, useState } from "react";
import profile from "../../assets/images/profile.png";
import axios from "axios";
import { PatientContext } from "../contex/PatientContex";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Myprofile = () => {
  const [data, setdata] = useState({});
  const navigate = useNavigate();
  const { user } = useContext(PatientContext);
  const userId = user.user_id;

  useEffect(() => {
    fetchUserData();
  }, [userId]);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const res = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/user/getUser/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setdata(res.data.user);
        console.log("data" + res.data.user);
        if (res.data.user.profile_picture) {
          setImg(
            `${process.env.REACT_APP_IMAGEURL}/${res.data.user.profile_picture}`
          );
        }
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      if (error.request.status === 404) {
        toast.error("Unauthrized ");
        navigate("/plogin");
      }
    }
  };
  const [img, setImg] = useState(profile);

  return (
    <div className="p-3">
      <div className="p-3 shadow-sm rounded border">
        <h4 className="text-center  notes-heading rounded  p-2"> My Profile</h4>
        <div className="row">
          <div className="col-md-4 d-flex align-items-center">
            <div className="rounded-2 p-3">
              {/* {
                data.profile_picture ===null ?<img src={profile} alt="" className="profileimg img-fluid" style={{maxHeight:"200px", maxWidth:"200px"}} />:<img src={data.profile_picture} alt="" className="profileimg img-fluid" style={{maxHeight:"200px", maxWidth:"200px"}} />
            }
               */}
              <img
                src={img}
                alt=""
                className="profileimg img-fluid rounded-4"
                style={{ maxHeight: "200px", maxWidth: "200px" }}
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="row pt-4">
              <div className="col-sm-6">
                <p className="">
                  Name: {data.user_first_name + data.user_last_name}
                </p>
                <p className=""> Date of birth : {data.user_date_of_birth} </p>
                <p className=""> Gender: {data.user_gender} </p>
              </div>
              <div className="col-sm-6">
                <p className=""> Phone: {data.user_phone} </p>
                <p className=""> Aadhar No : {data.user_aadhar} </p>
                <p className=""> Email: {data.user_email} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Myprofile;
