import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link } from "react-router-dom";
const Msg = ({ closeToast, message }) => (
  <div>
    <p className=" text-center  text-success"> {message} </p>

    <div className="d-flex  justify-content-center ">
      <div className=" ">
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="fs-1  text-center text-success "
        />
      </div>
    </div>
    <div className=" d-flex align-item-center justify-content-center mt-3">
      <Link to="/healthrecord" className="  primary-btn  text-decoration-none">
        
        Back to Record
      </Link>
    </div>
  </div>
);

const Uploadrecord = () => {
   const navigate = useNavigate();


  const [id, setId]=useState("");
  const baseurl = process.env.REACT_APP_BASEURL;
  const [recordtype, setRecordType] = useState(false);
  const [formData, setFormData] = useState({
    record_name: "",
    record_type: "Prescription",
    date_of_prescription: "",
    file_link: "",
    doctor_name: "",
    facility_name: "",
    Fees_type: "",
    user_id:id,
  });
 
   useEffect(()=>{
     const user = localStorage.getItem("userId");
     if (user) {
       setId(user);
     } else {
       navigate("/login");
     }
   },[])
    // useEffect(()=>{
    //    if(id){

    //    }
    // },[id])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    switch (e.target.name) {
      case "record_type":
        if (e.target.value === "Expenses") {
          setRecordType(true);
        } else {
          setRecordType(false);
        }
        break;
      default:
        break;
    }
  };
  const [fileError, setFileError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (e) => {
    // setFormData({
    //   ...formData,
    //   file_link: e.target.files[0],
    // });
    const file = e.target.files[0];
    setFileError(""); // Clear previous errors
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "image/gif"];
      const maxSize = 2 * 1024 * 1024; // 2 MB

      if (!validTypes.includes(file.type)) {
        setFileError("Only image files (JPEG, PNG, GIF) are allowed.");
      } else if (file.size > maxSize) {
        setFileError("File size must be less than 2 MB.");
      } else {
        setSelectedFile(file);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const formDataToSend = new FormData();
      formDataToSend.append('record_name', formData.record_name);
      formDataToSend.append('record_type', formData.record_type);
      formDataToSend.append('date_of_prescription', formData.date_of_prescription);
      formDataToSend.append('file_link', selectedFile); // Append the file here
       formDataToSend.append('doctor_name', formData.doctor_name);
       formDataToSend.append('facility_name', formData.facility_name);
       formDataToSend.append('Fees_type', formData.Fees_type); // Append the file here  
       formDataToSend.append('user_id', id); // Append the file here
      const response = await axios.post(`${baseurl}/api/healthRecords/saveHealthRecord`, formDataToSend,{
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      // const response = await axios.post(`${baseurl}/api/healthRecords/saveHealthRecord`, formData);
       if(response.status==200){
        toast(<Msg message={" Record Added Succesfully "} />);
        //  navigate('/healthrecord');
        
       }
    
      setFormData({
        record_name: "",
        record_type: "Prescription",
        date_of_prescription: "",
        file_link: "",
        doctor_name: "",
        facility_name: "",
        Fees_type: "",
      });
    } catch (error) {

      toast.error(error.response.data.error)
      setTimeout(()=>{
        if(error.request.status ===401){
          navigate("/plogin")
        }
      },1000)
    }
      // Handle error, like showing an error message
    
  };

  return (
    <div className="p-3">
      {/* <button onClick={handleSubmit}>check msg </button> */}
      <h4 className="notes-heading text-center p-2 rounded">Upload Record</h4>
      <div className="border rounded shadow-sm p-3">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6">
              <label htmlFor="record_name" className="form-label">
                Record Name
              </label>
              <input
                type="text"
                name="record_name"
                required
                value={formData.record_name}
                className="form-control"
                onChange={handleChange}
                placeholder="Please Enter Record Name"
              />
            </div>
            <div className="col-sm-6">
              <label htmlFor="record_type" className="form-label">
                Record Type
              </label>
              <select
                name="record_type"
                required
                className="form-select"
                aria-label="Default select example"
                value={formData.record_type}
                onChange={handleChange}
              >
                <option value="Prescription">Prescription</option>
                <option value="Test Results">Test Results</option>
                <option value="Expenses">Expenses</option>
              </select>
            </div>
            <div className="col-sm-6 mt-3">
              <label htmlFor="date_of_prescription" className="form-label">
                Date of Prescription/Result
              </label>
              <input
                type="date"
                required
                name="date_of_prescription"
                className="form-control"
                value={formData.date_of_prescription}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-6 mt-3">
              <label htmlFor="file" className="form-label">
                Upload File
              </label>
              <input
                type="file"
                required
                name="file_link"
                className="form-control"
                onChange={handleFileChange}
              />
              {fileError && (
                    <small className="text-danger">{fileError}</small>
                  )}
            </div>
            <div className="col-sm-6 mt-3">
              <label htmlFor="doctor_name" className="form-label">
                Doctor Name
              </label>
              <input
                type="text"
                name="doctor_name"
                required
                className="form-control"
                value={formData.doctor_name}
                onChange={handleChange}
              />
            </div>
            <div className="col-sm-6 mt-3">
              <label htmlFor="facility_name" className="form-label">
                Facility Name
              </label>
              <input
                type="text"
                name="facility_name"
                className="form-control"
                required
                value={formData.facility_name}
                onChange={handleChange}
              />
            </div>
            {recordtype ? (
              <>
                <div className="col-sm-6">
                  <label htmlFor="Fees_type" className="form-label">
                    Type of Fees
                  </label>
                  <select
                    name="Fees_type"
                    className="form-select"
                    aria-label="Default select example"
                    value={formData.Fees_type}
                    required
                    onChange={handleChange}
                  >
                    <option value="Doctors fees">Doctor Fees</option>
                    <option value="Medicine Fees">Medicine Fees</option>
                    <option value="Lab fees">Lab Fees</option>
                  </select>
                </div>
              </>
            ) : null}
          </div>
          <div className="d-flex justify-content-start mt-3">
            <button type="submit" className="primary-btn px-4">
              Upload
            </button>
          </div>
        </form>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Uploadrecord;
