import React, { useContext, useState, useEffect } from "react";
import logo from "../../assets/images/logo3.svg";
import { useForm } from "react-hook-form";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { PatientContext } from "../contex/PatientContex";

const Loginwithpassword = () => {
  const baseurl = process.env.REACT_APP_BASEURL;
  const { user, setUser } = useContext(PatientContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(`${baseurl}/api/user/login`, data);
      if (res.status === 200) {
        //  console.log(res.data.user);
        toast.success(res.data.msg);
        setUser(res.data.user);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        localStorage.setItem("access_token", res.data.access_token);
        localStorage.setItem("userId", res.data.user.user_id);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        setTimeout(() => {
          navigate(`/vitals/${btoa(res.data.user.user_id)}`);
        }, 1500);
      } else {
        toast.error("Error while fetching data: " + res.status);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      if(error.response){

        toast.error(error.response.data.error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  // Password tolggle code
  const [showPassword, setShowPassword] = useState(false);
  const [type, setType] = useState("password");
  const eyeTogle = () => {
    setShowPassword(!showPassword);
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <div className="container">
      <div className="login-container align-content-center">
        <div className="row login-box shadow-sm">
          <div className="col-md-6 align-content-center login-col-1 text-center">
            <img src={logo} className="img-fluid logo-img" alt=" web logo" />
          </div>
          <div className="col-md-6 p-4 bg-body-tertiary">
            <p className="text-center fs-4 fw-medium ">Login to your Account</p>
            <form onSubmit={handleSubmit(onSubmit)} className="pt-3">
              {/* Ensure handleSubmit is attached to onSubmit */}
              <div>
                <label htmlFor="" className="form-label">
                  Username
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  {...register("user_email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Invalid email address",
                    },
                  })}
                />
                {errors.user_email && (
                  <small className="text-danger">
                    {errors.user_email.message}
                  </small>
                )}
              </div>
              <div className="pt-3">
                <label htmlFor="" className="form-label">
                  Password
                </label>
                <div className="text-end eyebutton ">
                  {showPassword ? (
                    <FontAwesomeIcon onClick={eyeTogle} icon={faEye} />
                  ) : (
                    <FontAwesomeIcon onClick={eyeTogle} icon={faEyeSlash} />
                  )}
                </div>
                <input
                  type={type}
                  className="form-control"
                  name="password"
                  {...register("user_password", {
                    required: " Pasword is required ",
                    pattern: {
                      value:
                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                      message:
                        "Minimum eight characters, at least one letter, one number and one special character",
                    },
                  })}
                />

                {errors.user_password && (
                  <small className="text-danger">
                    {errors.user_password.message}
                  </small>
                )}
              </div>
              <div className="pt-3">
                <button type="submit" className="primary-btn w-100  text-center">
                  Sign In
                  
                </button>
              </div>
            </form>
                {loading && (
                    <div className="d-flex justify-content-center pt-2">
                      <div class="spinner-border text-primary " role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}

            <div className="d-flex justify-content-center">
              <p className="text-center pt-3">
                Don't have an account?
                <Link to="/completeprofile" className="text-main-color">
                  Register here
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Loginwithpassword;
