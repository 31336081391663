import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import "../../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const BlogMainPage3 = () => {
  return (
    <>
      <Header />
      <div className="" style={{ paddingTop: "70px" }}>
        <div className="blog-header p-4">
          <div className="container">
            <h1 className="blog-title fs-1 fw-semibold">
              Evolution of Digital Technologies in India’s Healthcare Domain.
            </h1>
            <div className="d-flex justify-content-between pt-4">
              <p className="fs-4">
                <FontAwesomeIcon icon={faCalendarCheck} /> 13/06/2024
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row p-4 " id="main">
            <div className="col-md-8">
              <h3 className="fw-semibold fs-2  sub-bg shadow-sm">
                Digital technologies role in reshaping India's medical
                landscape.
              </h3>
              <hr />
              <p className="text-secondary justify fs-5">
                Over the last few decades, digital technologies have undergone
                rapid changes that have changed our way of life, working and
                communicating. Nowadays, telemedicine has been introduced in
                India which removes geographic obstacles to accessing
                healthcare. Which has led to a drastic change. Hospitals have
                been able to provide patients with remote consultations, so they
                can access healthcare services in their own homes. One of the
                major platforms launched by the government of India eSanjeevani
                , which is already widely used. Also, the implementation of
                electronic health records has facilitated patient data
                management, increasing the efficiency and accuracy of diagnosis
                and treatment.
              </p>
            </div>
            <div className="col-md-4 d-flex justify-content-center">
              <div>
                <div>
                  <img
                    src="assets/img/blog/ceo.jpg"
                    alt=""
                    style={{ maxHeight: "200px", width: "200px" }}
                    className="rounded-circle "
                  />
                </div>

                <div className="pt-3">
                  <p className="fw-semibold "> Prashant Yadav </p>
                  <p className="text-italic">
                    CEO of Tecraki Technology Solutions Pvt. Ltd.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            <div className="row">
              <div className="col-md-4">
                <img
                  src="assets/img/blog/onlinecon1.png"
                  alt=""
                  className="img-fluid "
                />
              </div>
              <div className="col-md-8">
                <h3 className=" sub-bg shadow-sm">
                  The current state of digitalization in 1,2,3 tier cities and
                  rural areas.
                </h3>
                <p className="text-secondary justify fs-5">
                  India's digital development, reflecting different levels of
                  access, adoption and impact of Digital Technologies, varies
                  widely from city to village. Advanced Digital Infrastructures
                  are available in tier 1 cities such as Mumbai, Delhi,
                  Bangalore and Hyderabad. The tech system in tier 1 cities are
                  the most advanced level that foster innovation and digital
                  entrepreneurship.
                  <br /> <br />
                  Rapid improvements in digital infrastructure are taking place in
                Tier 2 cities such as Pune, Jaipur, and Lucknow. Internet access
                is improved due to increased investment in broadband networks
                and mobile connections, although connectivity problems still
                exist in certain areas. Currently Tier 2 cities are adopting
                digital advancements such as the use of e-commerce, online
                education, and digital payment systems.
                </p>
              </div>
            </div>
            <div>
              <p className="text-secondary justify fs-5">
           
                
                The digital infrastructure of tier 3 cities such as Mangalore,
                Ajmer and Tiruchirappalli is being developed steadily. In Tier 3
                cities, there is an increase in awareness and use of
                digitisation services. Slowly, digital banking and online
                education have begun to be embraced by local companies and
                consumers. Efforts are being made by the government to increase
                digital literacy, bridging the digital divide.
              </p>
              <ul className="text-secondary fs-5 ">
                <li>Varying level of digital infrastructure, Accessibility.</li>
                <li>
                  Tier 2 & 3 cities are gradually adapting to the new systems.
                </li>
                <li>Promotion of Digital literacy.</li>
                <li>Upgrading the health care system for the entire nation.</li>
              </ul>
            </div>
          </div>
          <div className="p-4">
            <h3 className=" sub-bg shadow-sm">
              Immense potential in the field of healthcare digitalisation.
            </h3>
            <p className="text-secondary justify fs-5">
              Digitalisation offers huge potential to improve patient outcomes,
              increase efficiency and reduce costs in the healthcare sector,
              which is undergoing a profound transformation. Telemedicine
              platforms are revolutionizing access to healthcare, particularly
              for the populations in far and unreached areas. EHR systems help
              streamline administrative procedures and reduce paperwork. Which
              has also led to significant time savings for healthcare providers,
              which will allow them to focus more on patient care.
            </p>
            <ul className="text-secondary fs-5 ">
              <li>
                {" "}
                Expanded health care initiatives taken by the Government of
                India.
              </li>
              <li> Implementation of EHR system for efficiency. </li>
              <li> Patient engagement through health apps. </li>
              <li> Real-time automated workflows & Smooth communication.</li>
            </ul>
          </div>
          <div className="p-4">
            <div className="row">
              <div className="col-md-8">
                <h3 className=" sub-bg shadow-sm">
                  Initiatives by government of India
                </h3>
                <p className="text-secondary justify fs-5">
                  In order to improve access, efficiency and quality of health
                  care for all citizens, the Government of India has initiated a
                  number of initiatives in support of Digital Health. One of the
                  few initiatives taken by the government of India is Ayushman
                  Bharat Digital Mission (ABDM) aims at building an integrated
                  digital health infrastructure throughout the country. In order
                  to ensure seamless access between healthcare providers, it
                  aims to provide each citizen with a unique health ID, which
                  will store his or her health records digitally.
                  <br /> <br />
                  Another major initiative taken by the Government of India is
                  the implementation of telemedicine systems. The eSanjeevani is
                  a national telemedicine service enabling physicians and
                  patients to consult remotely with healthcare professionals
                  directly. This initiative aims to provide health care services
                  in isolated and remote areas.
                </p>
                <ul className="text-secondary fs-5 ">
                  <li>Introduction of Digital health apps.</li>
                  <li>Seamless communication with health providers.</li>
                  <li>Promoting Digital health programs.</li>
                  <li>Empowering communities to connect digitally. </li>
                </ul>
              </div>
              <div className="col-md-4">
                <img
                  src="assets/img/blog/abdm.png"
                  alt=""
                  className="img-fluid "
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="p-4">
            <h3 className="text-semibold"> Frequently Asked Questions </h3>
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed fs-5"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    What are the challenges that India faces as it implements
                    Digital Health Technologies?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body fs-5">
                    Lack of digital infrastructure in rural areas, low levels of
                    digital literacy among the population.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed fs-5"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    What are the initiatives taken by the Indian Government to
                    promote digital healthcare?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse fs-5"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Government of India Has launched, Several initiatives,
                    including the Ayushman Bharat Digital MissionABDM 2015),
                    eSanjeevani Telemedicine Service, National Digital Health
                    MissionNDHM 2015 etc.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4">
            <p className="fw-semibold"> Still have a question? Contact us.</p>
            <Link to="/contact" className="btn btn-primary1">
              Contact us <i className="bi bi-arrow-right"></i>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogMainPage3;
