import React from 'react'
import Header from '../Header';
import Footer from '../Footer';
const DeleteMyAccount = () => {
    return (
        <div>
            <Header></Header>
            <section style={{ paddingTop: "60px" }}>
                <div className='container p-4 mb-5'>
                    <h2 className='text-center fw-bold m-4 '>
                        Account Deletion Instructions for BaelHealth App
                    </h2>
                    <p className='text-start'>Thank you for being a part of the BaelHealth community. If you've decided to delete your BaelHealth account, we're here to guide you through the process.</p>

                    <p className='text-start fw-bold'>Please follow these simple steps:</p>
                    <div className="mt-2">
                        <p className="fw-bold">1. Log in:</p>
                        <p>Open the BaelHealth app and log in to your account.</p>
                    </div>
                    <div className="mt-2">
                        <p className="fw-bold">2.Access Your Profile:</p>
                        <p>‍Click on the person icon located in the top right corner on the dashboard to access your profile page.</p>
                    </div>
                    <div className="mt-2">
                        <p className="fw-bold">3.Navigate to Account Actions:</p>
                        <p>‍Once on your profile page, look for the "Account Actions" section.</p>
                    </div>
                    <div className="mt-2">
                        <p className="fw-bold">4.Delete Your Account:</p>
                        <p>Within "Account Actions," find the option to "Delete Account."Click on it and follow the on-screen instructions to complete the process.</p>
                    </div>
                    <div className="mt-2">
                        <p className="fw-bold">Important Note:
                        </p>
                        <p>‍Once your account is deleted, all associated data will be immediately and irreversibly removed from our system. This includes personal information, preferences, and activity history.
                            We appreciate the time you've spent with BaelHealth. If you have any questions or need assistance, please feel free to reach out to our support team at contact@tecraki.io .</p>
                    </div>

                </div>
            </section>

            <Footer></Footer>
        </div>
    )
}

export default DeleteMyAccount
