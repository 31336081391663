import React, { useState, useContext, useEffect } from "react";
import "../../Patient.css";
import { Link, useNavigate } from "react-router-dom";
import { PatientContext } from "../../contex/PatientContex";
import { toast } from "react-toastify";
import axios from "axios";
import {
  faEdit,
  faSearch,
  faToggleOff,
  faToggleOn,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MedicationReminder = () => {
  const [todays, setTodays] = useState(true);
  const { user } = useContext(PatientContext);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [reminders, setReminders] = useState({});
  const navigate = useNavigate();
  const userId = user.user_id;

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredReminders, setFilteredReminders] = useState([]);

  useEffect(() => {
    if (userId) {
      fetchData();
      fetchDataByCurrentdate();
    }
  }, [userId]);

  // useEffect(() => {
  //   filterReminders(searchQuery); // Set initial value of filteredReminders to data2
  // }, [searchQuery]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/reminders/getRemindersByCurrentdate/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        setCurrentData(response.data.reminders);
        console.log(response.data.reminders);
      } else if (response.status === 204) {
        setCurrentData([]);
        toast.warning("No Reminder found");
      } else {
        toast.error("Error while fetching data: " + response.status);
      }
    } catch (error) {
      console.log(error);
      if (error.request.status === 401) {
        toast.error("Unauthorized Login again");
        setTimeout(() => {
          navigate("/plogin");
        }, 1000);
      }
    }
  };
  const fetchDataByCurrentdate = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASEURL}/api/reminders/getRemindersbyId/${userId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 200) {
        setData(response.data.reminders);
        // console.log(response.data.reminders);
      } else if (response.status === 204) {
        setData([]);
        toast.warning("No Reminder found");
      } else {
        toast.error("Error while fetching data: " + response.status);
      }
    } catch (error) {
      console.log(error);
      if (error.request.status === 401) {
        toast.error("Unauthorized Login again");
        setTimeout(() => {
          navigate("/plogin");
        }, 1000);
      }
    }
  };

  const labelColors = {
    Missed: "bg-danger-subtle text-black",
    active: "bg-success-subtle text-black",
    Ongoing: "bg-primary-subtle text-black",
  };

  const reminders3 = [
    {
      reminderName: "Dementia",
      id: "1",

      status: "Active",
      medicine: [
        {
          medicineName: "Deflox RD 10mg/50mg Syrup",
          medication_type_id: "bottle of 30 ml Syrup",
          dose: "400 mg  ",
          time: ["11:14:00", "16:08:00"],
          timetotake: " day ",
          duration: "week_day",
          days: ["Monday"],
        },
        {
          medicineName: "Rto Capsule",
          medication_type_id: "strip of 10 capsules",
          dose: "700 mg ",
          time: ["17:09:00", "15:09:00"],
          timetotake: "after meals ",
          duration: "week_day",
          days: ["Monday", "Wednesday"],
        },
      ],
    },
    {
      reminderName: "Dementia1",
      id: "2",
      status: "Active",
      medicine: [
        {
          medicineName: "Deflox RD 10mg/50mg Syrup2",
          medication_type_id: "bottle of 30 ml Syrup2",
          dose: "400 mg  ",
          time: ["11:14:00", "16:08:00"],
          timetotake: " day ",
          duration: "week_day",
          days: ["Monday"],
        },
        {
          medicineName: "Rto Capsule2",
          medication_type_id: "strip of 10 capsules2",
          dose: "7002 mg ",
          time: ["17:09:00", "15:09:00"],
          timetotake: "after meals ",
          duration: "week_day",
          days: ["Monday", "Wednesday"],
        },
      ],
    },
  ];

  const handleEdit = (id) => {
    console.log("Edit reminder with id:", id);
    navigate(`/editreminder/${btoa(id)}`);
    // Implement edit logic here
  };

  const handleDelete = async (id) => {
    console.log("Delete reminder with id:", id);
    // Implement delete logic here
    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (confirmed) {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      try {
        const res = await axios.delete(
          `${process.env.REACT_APP_BASEURL}/api/reminders/deletereminder/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 200) {
          toast.success("Successfully deleted");
          fetchData(); // Assuming fetchData is a function to fetch updated data
        }
      } catch (error) {
        toast.error("Error deleting");
      }
    }
  };

  const toggleReminder = (id) => {
    setReminders((prevReminders) => {
      const updatedReminders = { ...prevReminders };
      updatedReminders[id] = !updatedReminders[id];
      return updatedReminders;
    });
  };
  // Filter the data based on search query
  const filteredData = data.filter(reminder => 
    reminder.schedule_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
    reminder.medicine.some(med => 
      med.medicineName?.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className="p-1">
      <div className="shadow-sm rounded border p-2 d-flex justify-content-between">
        <button
          className="primary-btn"
          type="button"
          onClick={() => {
            setTodays(true);
          }}
        >
          Today's Schedule
        </button>
        <button
          className="primary-btn"
          type="button"
          onClick={() => {
            setTodays(false);
          }}
        >
          Existing Schedules
        </button>
        <Link className="primary-btn text-decoration-none" to="/med3">
          Create New Medication Schedule
        </Link>
      </div>
      {todays ? (
        <div className="p-2">
          <h3 className="p-2 text-center notes-heading rounded ">
            Today's Schedule
          </h3>
          <table className="w-100 table border p-2 shadow-sm table-striped table-hover">
            <thead>
              <tr>
                <th>Medicine Name</th>
                <th>Dose & Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((reminder, reminderIndex) => (
                <tr key={reminder.id} className="text-start">
                  <td>
                    <ul className="list-style-none">
                      {reminder.medicine.map((med, medIndex) => (
                        <li key={`${reminderIndex}-${medIndex}`}>
                          {med.medicineName} ({med.medication_type_id})
                          <hr />
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td>
                    <ul className="list-style-none">
                      {reminder.medicine.map((med, medIndex) => (
                        <li key={`${reminderIndex}-${medIndex}`}>
                          {med.dose} & {med.ReminderTimes.join(", ")}
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="text-center">
                    {/* <p
                      className={`${
                        labelColors[reminder.status]
                      } rounded py-1 px-3 `}
                      style={{ width: "fit-content" }}
                    >
                      {reminder.status}
                    </p> */}
                    {reminder.medicine.map((med, medIndex) => (
                      <p
                        className={`${
                          labelColors[med.status]
                        } rounded py-1 px-3 `}
                        style={{ width: "fit-content" }}
                        key={`${reminderIndex}-${medIndex}`}
                      >
                        {med.status}
                      </p>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="p-2">
          <h3 className="p-2 text-center notes-heading rounded ">
            Existing Schedules
          </h3>
          <div className="row">
            <div className="col-md-4 mb-2 d-flex  align-items-center">
              <FontAwesomeIcon icon={faSearch} className="fs-4 me-3" />
              <input
                type="text"
                className=" form-control"
                placeholder=" Search by Schedule Name / Medicine Name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <table className="table table-striped table-hover">
            <thead>
              <tr className="text-start">
                <th>Schedule Name</th>
                <th>Medicine Name</th>
                <th>Dose & Time</th>
                <th>Duration with Days </th>
                <th>Action</th>
                <th>Reminder</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((reminder, reminderIndex) => (
                <tr key={reminder.id} className="text-start">
                  <td>{reminder.schedule_name}</td>
                  <td>
                    <ul className="list-style-none">
                      {reminder.medicine.map((med, medIndex) => (
                        <li key={`${reminderIndex}-${medIndex}`}>
                          {med.medicineName} ({med.medication_type_id})
                          <hr />
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td>
                    <ul className="list-style-none">
                      {reminder.medicine.map((med, medIndex) => (
                        <li key={`${reminderIndex}-${medIndex}`}>
                          {med.dose} & {med.ReminderTimes.join(", ")}
                          <hr />
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td>
                    <ul className="list-style-none">
                      {reminder.medicine.map((med, medIndex) => (
                        <li key={`${reminderIndex}-${medIndex}`}>
                          {med.no_of_days}{" "}
                          {med.medication_schedule_specific_week.join(", ")}
                          <hr />
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td className="">
                    <div className="d-flex justify-content-between">
                      <button
                        className="cursor-pointer btn py-2 px-2"
                        onClick={() => handleDelete(reminder.id)}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="fs-4 text-danger"
                        />
                      </button>
                      <button
                        className="cursor-pointer btn py-2 px-2"
                        onClick={() => handleEdit(reminder.id)}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="fs-4 text-primary"
                        />
                      </button>
                    </div>
                  </td>
                  <td>
                    <button
                      className="cursor-pointer btn py-2"
                      onClick={() => toggleReminder(reminder.id)}
                    >
                      {/* {reminder.medicine.map((med, medIndex) => (
                        <FontAwesomeIcon
                          key={`${reminderIndex}-${medIndex}`}
                          icon={faToggleOn}
                          className="fs-3 text-primary"
                        />
                      ))} */}

                      {reminder.status == "active" ? (
                        <FontAwesomeIcon
                          icon={faToggleOn}
                          className="fs-3 text-primary"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faToggleOff}
                          className="fs-3 text-secondary"
                        />
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MedicationReminder;
