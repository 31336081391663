import React, { useState } from "react";
import "../../../App.css";
import { Link } from "react-router-dom";
const Blogcard = ({ title, category, author, date, content ,profile,path,blogimg}) => {
  const data = [
   
  ];

  return (
    <div className="mb-3 ">
      <Link to={path} class="text-decoration-none">
        <div className="blog-card rounded-4 p-3">
          <div className="d-flex  justify-content-center text-center">

          <img src={blogimg}  class="card-img-top blog-img   rounded " alt="..." style={{height:"250px", width:"280px",}} />
          </div>
          <div class="card-body mt-2">
            <h6 class="card-title text-black">
              {title}.
            </h6>
            <p class=" cotegory rounded py-1 px-3 bg-danger-subtle text-justify mt-1 text-black">
             {category}
            </p>
            <p class="card-text">
              {content}
             
            </p>
          </div>
          <div className="p-2 mt-3 align-items-center d-flex">
            <img
              class="rounded-circle  blog-written-by"
              src={profile}
              alt=""
            />
            <div className="ms-3 text-black">
              <p className="m-0"> {author} </p>
              <p>{date}</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
    // <div className="mb-3 ">
    //   <Link to="/blogpage" class="text-decoration-none">
    //     <div className="blog-card rounded-4 p-3">
    //       <img src="assets/img/patients.svg" class="card-img-top" alt="..." />
    //       <div class="card-body mt-2">
    //         <h5 class="card-title text-black">
    //           Overall Health care with EHR System .
    //         </h5>
    //         <p class=" cotegory rounded py-1 px-3 bg-danger-subtle text-justify mt-1 text-black">
    //           A Better tomorrow with EHR
    //         </p>
    //         <p class="card-text">
    //           {content}
    //           EHR stands for Electronic Health Records, which is a digital
    //           platform of patients' paper charts. Means, it allows storing
    //           entire health records in .......
    //         </p>
    //       </div>
    //       <div className="p-2 mt-3 align-items-center d-flex">
    //         <img
    //           class="rounded-circle  blog-written-by"
    //           src="assets/img/blog/ceo.jpg"
    //           alt=""
    //         />
    //         <div className="ms-3 text-black">
    //           <p className="m-0"> Prashant Yadav </p>
    //           <p>May 23 , 2024</p>
    //         </div>
    //       </div>
    //     </div>
    //   </Link>
    // </div>
  );
};

export default Blogcard;
