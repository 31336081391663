import React, { useState, useEffect } from "react";
import "../../Patient.css";
import bloodpresseure from "../../../assets/images/vitals/favorite.svg";
import pulse from "../../../assets/images/vitals/pulse_alert.svg";
import temp from "../../../assets/images/vitals/dew_point.svg";
import oxygen from "../../../assets/images/vitals/spo2.svg";
import glucose from "../../../assets/images/vitals/glucose.svg";
import height from "../../../assets/images/vitals/altitude.svg";
import weight from "../../../assets/images/vitals/monitor_weight.svg";
import bmi from "../../../assets/images/vitals/accessibility_new.svg";
import {
  faCalendarDay,
  faChartColumn,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import { event } from "jquery";

const Vitals = () => {
  const baseurl = process.env.REACT_APP_BASEURL;
  const [vitals, setVitals] = useState([]);
  const [latestVitals, setLatestVitals] = useState({
    bloodGlucose: 0,
    bloodPressure: 0,
    pulse: 0,
    temperature: 0,
    oxygenSaturation: 0,
    height: 0,
    weight: 0,
    bmi: 0,
    currentDate: "",
  });
  const [userId, setUserId] = useState("");
 // Fetch the user ID from localStorage when the component mounts
 useEffect(() => {
  const id = localStorage.getItem('userId');
  if (id) {
    setUserId(id);
  } else {
    console.error("User ID not found in localStorage");
  }
}, []);

// Fetch vitals only after the user ID has been set
useEffect(() => {
  if (userId) {
    fetchvitals(userId);
  }
}, [userId]);

  const fetchvitals = async () => {

    try {
      
      const res = await axios.get(`${baseurl}/api/vitals/getLatest/${userId}`);
      if (res.status === 200) {
        // console.log(res.data.latestVitals);
        if (res.data.latestVitals.length === 0) {
          setLatestVitals({
            bloodGlucose: 0,
            bloodPressure: 0,
            pulse: 0,
            temperature: 0,
            oxygenSaturation: 0,
            height: 0,
            weight: 0,
            BMI: 0,
            currentDate: "",
          });
        } else {
          setVitals(res.data.latestVitals);
          setLatestVitals(res.data.latestVitals[0]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // Initialize arrays
  let labeArray = [];
  let glucoseArray = [];
  let oxygenArray = [];
  let temperatureArray = [];
  let bloodPressureArray = [];

  // Populate arrays if vitals is not empty
  if (vitals && vitals.length > 0) {
    for (let i = 0; i < 7 && i < vitals.length; i++) {
      labeArray.push(vitals[i].currentDate);
      glucoseArray.push(vitals[i].bloodGlucose);
      oxygenArray.push(vitals[i].oxygenSaturation);
      temperatureArray.push(vitals[i].temperature);
      bloodPressureArray.push(vitals[i].bloodPressure);
    }
  }

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: " Vitals Chart",
      },
    },
    scales: {
      x: {
        type: "category",
        // labels: [
        //   "Monday",
        //   "Tuesday",
        //   "Wednesday",
        //   "Thursday",
        //   "Friday",
        //   "Saturday",
        //   "Sunday",
        // ],
        labels: labeArray,
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 10, // Divide the axis into intervals of 10
        },
      },
    },
  };

  const chartData = {
    datasets: [
      {
        label: "Blood Pressure / mmHg",
        data: bloodPressureArray,
        // data: [90, 130, 125, 180, 135, 180, 132],
        backgroundColor: "RGB(255, 192, 203)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Glucose / mg/dL",
        data: glucoseArray,
        backgroundColor: "RGB(144, 238, 144)",
        borderColor: "rgb(65, 176, 110)",
        borderWidth: 1,
      },
      {
        label: "Oxygen / SpO2 %",
        data: oxygenArray,
        backgroundColor: "RGB(173, 216, 230)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: "Temperature / °F",
        data: temperatureArray,
        backgroundColor: " RGB(255, 165, 0)",
        borderColor: "rgba(255, 206, 86, 1)",
        borderWidth: 1,
      },
    ],
  };

  const [showModal, setShowModal] = useState(false);
  const [selectedVital, setSelectedVital] = useState("");
  const [unit, setunit] = useState("");

  const handleIconClick = (vitalType) => {
    setSelectedVital(vitalType);
    setShowModal(true);
    if (vitalType == "Blood Pressure") {
      setunit("mmHg");
    } else if (vitalType == "Blood Glucose") {
      setunit("mg/dl");
    } else if (vitalType == "Pulse") {
      setunit("bpm");
    } else if (vitalType == "Temperature") {
      setunit("°F");
    } else if (vitalType == "Oxygen Saturation") {
      setunit("% Spo2");
    } else if (vitalType == "Height") {
      setunit("cm");
    } else if (vitalType == "Weight") {
      setunit("kg");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const [data, setData] = useState({
    bloodPressure: "",
    currentDate: "",
    currentTime: "",
  });

  const [time, setTime] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangeTime = (event) => {
    const { value } = event.target;
    setTime(value === "manual");
    if(value === "current") {
      const date = new Date();
      const time = date.toLocaleTimeString();
      const  currentDate =date.toLocaleDateString();
      //  setData((prevData) => ({ ...prevData, currentTime: time }));

    }
    console.log(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedVital == "Blood Pressure") {
      try {
        // console.log("blod");
        const res = await axios.post(
          `${baseurl}/api/vitals/updateBloodPressure`,
          { bloodPressure: data.bloodPressure ,currentDate:data.currentDate, currentTime:data.currentTime}
        );
        setData({
          bloodPressure: "",
          currentDate: "",
          currentTime: "",
        })
      } catch (error) {
        console.log(error.message);
      }
    } else if (selectedVital == "Blood Glucose") {
      try {
        const res = await axios.post(
          `${baseurl}/api/vitals/updateBloodGlucose`,
          { bloodGlucose: data.bloodPressure }
        );
      } catch (error) {
        console.log(error.message);
      }
    } else if (selectedVital == "Temprature") {
      try {
        const res = await axios.post(
          `${baseurl}/api/vitals/updateTemperature`,
          { temperature: data.bloodPressure }
        );
      } catch (error) {
        console.log(error.message);
      }
    }

    // console.log(data);
    setShowModal(false);
  };

  return (
    <div>
      <div className="p-3">
        <div className="row">
          <div className="text-end">
            <Link to="/updatevitals" className="primary-btn">
              Update
            </Link>
          </div>
          <div div className="col-md-3  p-0     ">
            {/*  update vitals mmodal   */}
            <div
              className={`modal ${showModal ? "d-block" : "d-none"} shadow `}
              tabIndex="-1"
              role="dialog"
            >
              <div className="modal-dialog shadow" role="document">
                <div className="modal-content">
                  <div className="">
                    <h5 className="text-center notes-heading p-2 rounded">
                      Update :{" "}
                      <span className="">
                        {selectedVital} {unit}
                      </span>
                    </h5>
                  </div>
                  <div className="modal-body" onSubmit={handleSubmit}>
                    <form className="row">
                      <div className="col-sm-6">
                        <label htmlFor="bloodPressure" className="form-label">
                          {selectedVital}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="vitals"
                          name="bloodPressure"
                          onChange={handleChange}
                          // value={`data.${fid}`}
                          aria-describedby="bloodPressure"
                        />
                      </div>
                      <div className="col-sm-6">
                        <div>
                          <input
                            type="radio"
                            name="time"
                            id="time"
                            className="mt-1"
                            value="current"
                            // onClick={()=>{setTime(false)}}
                            onChange={handleChangeTime}
                          />
                          <label htmlFor="" className="form-label ms-2">
                            Current Date/Time
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            id="time"
                            className="mt-1"
                            value="manual"
                            name="time"
                            onChange={handleChangeTime}
                            // onClick={()=>{setTime(true)}}
                          />
                          <label htmlFor="" className="form-label ms-2">
                            Enter Date/Time
                          </label>
                        </div>
                      </div>
                      {time && (
                        <>
                          <div className="col-sm-6">
                            <label htmlFor="" className="form-label">
                              Time
                            </label>
                            <input
                              type="time"
                              className="form-control"
                              onChange={handleChange}
                              value={data.currentTime}
                              id="time"
                              name="currentTime"
                              aria-describedby="time"
                            />
                          </div>
                          <div className="col-sm-6">
                            <label htmlFor="" className="form-label">
                              Date
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              onChange={handleChange}
                              id="date"
                              name="currentDate"
                              value={data.currentDate}
                              aria-describedby="date"
                            />
                          </div>
                        </>
                      )}

                      <div className=" d-flex justify-content-end gap-2 p-2 mt-2">
                        <button
                          type="button"
                          className="btn  px-4 py-2 btn-outline-danger"
                          onClick={handleCloseModal}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className="btn px-4 py-2 btn-outline-success"
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-2 border shadow-sm rounded bg-body-tertiary m-2 vitals-card">
              <h4 className="text-center "> Blood Preaseure</h4>
              <div className="d-flex-justify-content-center text-center">
                <img src={bloodpresseure} alt="icon" className="vitals-img" />
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <p>
                    <span className="text-main-color text-center">
                      {latestVitals.bloodPressure}
                    </span>
                    mmHg
                  </p>
                </div>
              </div>
              <hr className="m-0 " />
              <div className="d-flex justify-content-between mt-3">
                <div className="d-flex  align-item-center ">
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="me-1 text-secondary"
                  />
                  <p className=" text-secondary">{latestVitals.currentDate}</p>
                </div>
                <div>
                  <p className=" text-secondary"> 17:45 pm </p>
                </div>
                <div className="graph">
                  <Link to={`/trackvitals/bloodpressure`}>
                    <FontAwesomeIcon
                      icon={faChartColumn}
                      className="text-info fs-4"
                    />
                  </Link>
                </div>
                <span
                  onClick={() => handleIconClick("Blood Pressure")}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="fs-4 text-secondary"
                  />
                </span>
              </div>
            </div>
          </div>

          <div div className="col-md-3  p-0 vitals-card    ">
            <div className="p-2 border shadow-sm rounded bg-body-tertiary m-2 vitals-card">
              <h4 className="text-center "> Blood Glucose</h4>
              <div className="d-flex-justify-content-center text-center">
                <img src={glucose} alt="icon" className="vitals-img" />
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  {/* <p className="m-0"> Before Meal</p> */}
                  <p>
                    <span className="text-main-color text-center">
                      {latestVitals.bloodGlucose}
                    </span>
                    mg/dl
                  </p>
                </div>
              </div>
              <hr className="m-0 " />
              <div className="d-flex justify-content-between mt-3">
                <div className="d-flex  align-item-center ">
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="me-1 text-secondary"
                  />
                  <p className=" text-secondary">{latestVitals.currentDate}</p>
                </div>

                <div>
                  <p className=" text-secondary"> 17:45 pm </p>
                </div>
                <div className="graph">
                  <Link to={`/trackvitals/glucose`}>
                    <FontAwesomeIcon
                      icon={faChartColumn}
                      className="text-info fs-4"
                    />
                  </Link>
                </div>
                <span
                  onClick={() => handleIconClick("Blood Glucose")}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="fs-4 text-secondary"
                  />
                </span>
              </div>
            </div>
          </div>
          <div div className="col-md-3  p-0 vitals-card    ">
            <div className="p-2 border shadow-sm rounded bg-body-tertiary m-2 vitals-card">
              <h4 className="text-center ">Pulse</h4>
              <div className="d-flex-justify-content-center text-center">
                <img src={pulse} alt="icon" className="vitals-img" />
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <p>
                    <span className="text-main-color">
                      {latestVitals.pulse}
                    </span>
                    BPM
                  </p>
                </div>
              </div>
              <hr className="m-0 " />
              <div className="d-flex justify-content-between mt-3">
                <div className="d-flex  align-item-center ">
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="me-1 text-secondary"
                  />
                  <p className=" text-secondary">{latestVitals.currentDate}</p>
                </div>
                <div>
                  <p className=" text-secondary"> 17:45 pm </p>
                </div>
                <div className="graph">
                  <Link to={`/trackvitals/pulse`}>
                    <FontAwesomeIcon
                      icon={faChartColumn}
                      className="text-info fs-4"
                    />
                  </Link>
                </div>
                <span
                  onClick={() => handleIconClick("Pulse")}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="fs-4 text-secondary"
                  />
                </span>
              </div>
            </div>
          </div>
          <div div className="col-md-3  p-0 vitals-card    ">
            <div className="p-2 border shadow-sm rounded bg-body-tertiary m-2 vitals-card">
              <h4 className="text-center "> Temprature</h4>
              <div className="d-flex-justify-content-center text-center">
                <img src={temp} alt="icon" className="vitals-img" />
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <p>
                    <span className="text-main-color">
                      {latestVitals.temperature}
                    </span>
                    F
                  </p>
                </div>
              </div>
              <hr className="m-0 " />
              <div className="d-flex justify-content-between mt-3">
                <div className="d-flex  align-item-center ">
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="me-1 text-secondary"
                  />
                  <p className=" text-secondary">{latestVitals.currentDate}</p>
                </div>
                <div>
                  <p className=" text-secondary"> 17:45 pm </p>
                </div>
                <div className="graph">
                  <Link to={`/trackvitals/temprature`}>
                    <FontAwesomeIcon
                      icon={faChartColumn}
                      className="text-info fs-4"
                    />
                  </Link>
                </div>
                <span
                  onClick={() => handleIconClick("Temprature")}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="fs-4 text-secondary"
                  />
                </span>
              </div>
            </div>
          </div>
          <div div className="col-md-3  p-0 vitals-card    ">
            <div className="p-2 border shadow-sm rounded bg-body-tertiary m-2 vitals-card">
              <h4 className="text-center "> Height</h4>
              <div className="d-flex-justify-content-center text-center">
                <img src={height} alt="icon" className="vitals-img" />
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <p>
                    <span className="text-main-color">
                      {latestVitals.height}
                    </span>
                    Cm
                  </p>
                </div>
              </div>
              <hr className="m-0 " />
              <div className="d-flex justify-content-between mt-3">
                <div className="d-flex  align-item-center ">
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="me-1 text-secondary"
                  />
                  <p className=" text-secondary">{latestVitals.currentDate}</p>
                </div>
                <div>
                  <p className=" text-secondary"> 17:45 pm </p>
                </div>
                <div className="graph">
                  <Link to={`/trackvitals/height`}>
                    <FontAwesomeIcon
                      icon={faChartColumn}
                      className="text-info fs-4"
                    />
                  </Link>
                </div>
                <span
                  onClick={() => handleIconClick("Height ")}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="fs-4 text-secondary"
                  />
                </span>
              </div>
            </div>
          </div>
          <div div className="col-md-3  p-0 vitals-card    ">
            <div className="p-2 border shadow-sm rounded bg-body-tertiary m-2 vitals-card">
              <h4 className="text-center "> Weight </h4>
              <div className="d-flex-justify-content-center text-center">
                <img src={weight} alt="icon" className="vitals-img" />
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <p>
                    <span className="text-main-color">
                      {latestVitals.weight}
                    </span>
                    Kg
                  </p>
                </div>
              </div>
              <hr className="m-0 " />
              <div className="d-flex justify-content-between mt-3">
                <div className="d-flex  align-item-center ">
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="me-1 text-secondary"
                  />
                  <p className=" text-secondary">{latestVitals.currentDate}</p>
                </div>
                <div>
                  <p className=" text-secondary"> 17:45 pm </p>
                </div>
                <div className="graph">
                  <Link to={`/trackvitals/weight`}>
                    <FontAwesomeIcon
                      icon={faChartColumn}
                      className="text-info fs-4"
                    />
                  </Link>
                </div>
                <span
                  onClick={() => handleIconClick("Weight")}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="fs-4 text-secondary"
                  />
                </span>
              </div>
            </div>
          </div>
          <div div className="col-md-3  p-0 vitals-card    ">
            <div className="p-2 border shadow-sm rounded bg-body-tertiary m-2 vitals-card">
              <h4 className="text-center "> BMI</h4>
              <div className="d-flex-justify-content-center text-center">
                <img src={bmi} alt="icon" className="vitals-img" />
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <p>
                    <span className="text-main-color">{latestVitals.BMI}</span>
                  </p>
                </div>
              </div>
              <hr className="m-0 " />
              <div className="d-flex justify-content-between mt-3">
                <div className="d-flex  align-item-center ">
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="me-1 text-secondary"
                  />
                  <p className=" text-secondary">{latestVitals.currentDate}</p>
                </div>
                <div>
                  <p className=" text-secondary"> 17:45 pm </p>
                </div>
                <div className="graph">
                  <Link to={`/trackvitals/BMI`}>
                    <FontAwesomeIcon
                      icon={faChartColumn}
                      className="text-info fs-4"
                    />
                  </Link>
                </div>
                <span
                  onClick={() => handleIconClick("BMI")}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="fs-4 text-secondary"
                  />
                </span>
              </div>
            </div>
          </div>
          <div div className="col-md-3  p-0 vitals-card    ">
            <div className="p-2 border shadow-sm rounded bg-body-tertiary m-2 vitals-card">
              <h4 className="text-center "> Oxygen Saturation</h4>
              <div className="d-flex-justify-content-center text-center">
                <img src={oxygen} alt="icon" className="vitals-img" />
              </div>
              <div className="d-flex justify-content-center">
                <div>
                  <p>
                    <span className="text-main-color">
                      {latestVitals.oxygenSaturation}
                    </span>
                    % SpO2
                  </p>
                </div>
              </div>
              <hr className="m-0 " />
              <div className="d-flex justify-content-between mt-3">
                <div className="d-flex  align-item-center ">
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className="me-1 text-secondary"
                  />
                  <p className=" text-secondary">{latestVitals.currentDate}</p>
                </div>
                <div>
                  <p className=" text-secondary"> 17:45 pm </p>
                </div>
                <div className="graph">
                  <Link to={`/trackvitals/oxygen`}>
                    <FontAwesomeIcon
                      icon={faChartColumn}
                      className="text-info fs-4"
                    />
                  </Link>
                </div>
                <span
                  onClick={() => handleIconClick("Oxygen Saturation")}
                  style={{ cursor: "pointer" }}
                >
                  <FontAwesomeIcon
                    icon={faCirclePlus}
                    className="fs-4 text-secondary"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="chart-container rounded shadow p-3">
          <h4 className="chart-heading text-center"> Vitals Chart</h4>
          <div className="chart">
            <Bar data={chartData} options={chartOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vitals;
