import React from 'react'
const Notfound = () => {
    return (
        <div>
            <section class="page_404">
                <div class="container d-flex justify-content-center">
                    <div class="row">
                        <div class="col-sm-12 ">
                            <div class="col-sm-10 col-sm-offset-1  text-center">
                                <div class="four_zero_four_bg">
                                    <h1 class="text-center ">404</h1>
                                </div>

                                <div class="contant_box_404">
                                    <h3 class="h2 fw-bold">
                                        Look like you're lost
                                    </h3>

                                    <p className='fw-bold fs-3'>The page you are looking for is <span class="text-danger-emphasis"> Temporarily out of service</span> </p>

                                    <a href="/" class="link_404">Go to Home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='container d-flex  justify-content-center bottom-0'>
            <small className='text-center'>Designed by Tecraki teams </small>
            </section>
               
            
        </div>
    )
}

export default Notfound
