import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import "../../../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faCalendarCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const BlogMainPage = () => {
  return (
    <>
      <Header />
      <div className="" style={{ paddingTop: "70px" }}>
        <div className="blog-header p-4">
          <div className="container">
            <h1 className="blog-title fs-1 fw-semibold">
              Overall Health care with EHR System
            </h1>
            <div className="d-flex justify-content-between pt-4">
              <p className="fs-4">
                
                <FontAwesomeIcon icon={faCalendarCheck} /> 23/05/2024
              </p>

              {/* <a
                href="#main"
                className="bg-dark-subtle py-2 px-3 rounded-circle fs-4"
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </a> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row p-4 " id="main">
            <div className="col-md-8">
              <h3 className="fw-semibold fs-2  sub-bg shadow-sm">
                A Better tomorrow with EHR
              </h3>
              <hr />
              <p className="text-secondary justify fs-5">
                EHR stands for Electronic Health Records, which is a digital
                platform of patients' paper charts. Means, it allows storing
                entire health records in digital format and allows real-time
                sharing of data between doctors, patients & hospitals. EHR
                system is a patient centered record that enables information to
                be made available instantly and securely for authorized users.
                <br />
                <br />
                One of the prime features of EHR is smooth and easy data
                transfers containing entire medical history for better care of
                patients. This accessibility does not only enhance the efficacy
                of healthcare, but also ensures that decisions are made with
                accuracy and care.
              </p>
            </div>
            <div className="col-md-4 d-flex justify-content-center">
              <div>
                <div>
                  <img
                    src="assets/img/blog/ceo.jpg"
                    alt=""
                    style={{ maxHeight: "200px", width: "200px" }}
                    className="rounded-circle "
                  />
                </div>

                <div className="pt-3">
                  <p className="fw-semibold "> Prashant Yadav </p>
                  <p className="text-italic">
                    CEO of Tecraki Technology Solutions Pvt. Ltd.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            <div className="row">
              <div className="col-md-4">
                <img
                  src="assets/img/blog/healthpro.png"
                  alt=""
                  className="img-fluid "
                />
              </div>
              <div className="col-md-8">
                <h3 className=" sub-bg shadow-sm">
                  EHRs benefits to the health professionals
                </h3>
                <p className="text-secondary justify fs-5">
                  It has the proven track record of providing high quality care
                  to patients. Health professionals can have easy access to
                  comprehensive medical records, hence the result is better
                  coordination and swift decision making for patient’s care. It
                  helps the doctors and medical professionals, providers to
                  streamline processes, improve accuracy, and improve patient
                  care.
                </p>
                <ul className="text-secondary fs-5 ">
                  <li>Reduced costs and Duplications. </li>
                  <li>Enhanced security and efficiency.</li>
                  <li>
                    Smooth communication between health providers and patients.
                  </li>
                  <li>Reduced costs and Duplications. </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="p-4">
            <h3 className=" sub-bg shadow-sm">
              EHRs role in Patient’s Engagement
            </h3>
            <p className="text-secondary justify fs-5">
              Technically, evolved modern medical practice. The EHR system has
              revolutionized the entire. medical system with Improved
              coordination of care, due to common access to patients' medical
              records. Patients can have Seamless communication between health
              providers and hospitals directly. The health care professionals,
              doctors can share real time information, plans to upgrade patients
              health and care without geographical constraints. Hence, it saves
              time and money.
            </p>
            <ul className="text-secondary fs-5 ">
              <li>Improved coordination with health professionals.</li>
              <li>Improved patients care. </li>
              <li> Accurate and real time communication. .</li>
              <li>Seamless flow of information.</li>
              <li>Reduce safety risks. </li>
            </ul>
          </div>
          <div className="p-4">
            <div className="row">
              <div className="col-md-8">
                <h3 className=" sub-bg shadow-sm">
                  EHRs Role in Hospitals and clinics
                </h3>
                <p className="text-secondary justify fs-5">
                  The EHR system benefits medical practice management with more
                  efficiency. It provides a smooth and Refined documentation
                  process to automated coding facilities. Through the EHR system
                  patients can schedule appointments, receive reminders, updates
                  in order to keep track of health. Patients' health information
                  can be viewed at one place, thus less likely to spend time in
                  organizing. This efficiency enables the health professionals
                  to take care of more patients. With reduced amount of work
                  pressure in administration. Hence, saving time and resources.
                </p>
                <ul className="text-secondary fs-5 ">
                  <li>Efficient improved documentation.</li>
                  <li>Reduced medical errors.</li>
                  <li> Reduced cost and saves time.</li>
                  <li>Self appointment Scheduling</li>
                  <li>Automated reminders and updates. </li>
                </ul>
              </div>
              <div className="col-md-4">
                <img
                  src="assets/img/blog/clinic.png"
                  alt=""
                  className="img-fluid "
                />
              </div>
              <p className="justify fs-5 text-secondary">
                The EHR system is the most secure, smooth and instant method
                with a broader view of patients.
              </p>
            </div>
          </div>
          <hr />
          <div className="p-4">
            <h3 className="text-semibold"> Frequently Asked Questions </h3>
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed fs-5"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Does the EHR system allow patients to have direct
                    communication with health providers?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body fs-5">
                    Yes, Better coordination and seamless communication with
                    health professionals is one of the key features of EHR
                    system.
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed fs-5"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Can health provide access to the previous history of
                    patients?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse fs-5"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Yes, with the consent of patients Doctors and medical
                    professionals can access the medical history, vital
                    information, diagnosis, family medical history lab tests
                    reports for improved health quality of patients.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="p-4">
            <p className="fw-semibold"> Still have a question? Contact us.</p>
            <Link to="/contact" className="btn btn-primary1">
              Contact us <i className="bi bi-arrow-right"></i>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogMainPage;
