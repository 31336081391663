import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../Patient.css";
import logo from "../../assets/images/logo3.svg";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateForward } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const PatientLogin = () => {
  const [otpSent, setOtpSent] = useState(false); // State to track if OTP has been sent
  const [time, setTime] = useState(30); // State to track the remaining time
  const [timerActive, setTimerActive] = useState(false); // State to track if the timer is active
  const [phone_number, setNumber] = useState(""); // State to track phone number
  const [generated_otp, setOtp] = useState(""); // State to track OTP
  const navigate = useNavigate();

  // Function to handle phone number change
  const handleNumberChange = (e) => {
    setNumber(e.target.value);
  };

  // Function to handle OTP change
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  // Function to handle form submission for sending OTP
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASEURL}/api/otp/sendOtp`, { phone_number });
      if (res.status === 200) {
        setOtpSent(true);
        setTimerActive(true); // Start the timer
        toast.success("OTP sent successfully!");
      }
    } catch (error) {
      toast.error( error.response.data.message);
      console.log(error)
    }
  };

  // Function to handle OTP verification
  const handleOTPVerification = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASEURL}/api/otp/verifyOtp`, { generated_otp,phone_number });
      if (res.status === 200) {
        setOtpSent(false);
        setTime(30); // Reset the timer
        setTimerActive(false); // Stop the timer
        toast.success("OTP verified successfully!");
        navigate("/completeprofile");
      }
    } catch (error) {
      toast.error("Error: " + error.message);
    }
  };

  // Function to handle resend OTP
  const resendOTP = async () => {
    if (time === 0) {
      try {
        const res = await axios.post(`${process.env.REACT_APP_BASEURL}/api/otp/sendOtp`, { phone_number });
        if (res.status === 200) {
          setTimerActive(true);
          setTime(30); // Start the timer again
          toast.success("OTP sent successfully!");
        }
      } catch (error) {
        toast.error("Error: " + error.message);
      }
    }
  };

  useEffect(() => {
    if (timerActive) {
      const interval = setInterval(() => {
        if (time > 0) {
          setTime((prevTime) => prevTime - 1);
        } else {
          setTimerActive(false);
        }
      }, 1000);

      return () => {
        clearInterval(interval); // Cleanup function to clear the interval
      };
    }
  }, [timerActive, time]); // Run effect when timerActive or time changes

  return (
    <>
      <div className="container">
        <div className="login-container align-content-center">
          <div className="row login-box shadow-sm">
            <div className="col-md-6 align-content-center login-col-1 text-center">
              <img src={logo} className="img-fluid logo-img" alt="web logo" />
            </div>
            <div className="col-md-6 p-4 bg-body-tertiary">
              <p className="text-center fs-5 fw-medium">
                {otpSent ? "Sign in to your Account" : "Login to your Account"}
              </p>
              {otpSent ? (
                // Render OTP fill form if OTP is sent
                <form className="pt-3" onSubmit={handleOTPVerification}>
                  <label className="form-label">Enter OTP</label>
                  <input
                    type="text"
                    required
                    name="otp"
                    value={generated_otp}
                    onChange={handleOtpChange}
                    placeholder="Enter OTP"
                    className="form-control"
                  />
                  <div className="mt-3 pb-2 d-flex justify-content-between">
                    <p
                      className="text-main-color"
                      onClick={resendOTP}
                      disabled={timerActive}
                    >
                      Resend OTP <FontAwesomeIcon icon={faArrowRotateForward} />
                    </p>
                    <p>{time}</p>
                  </div>
                  <div className="pt-3">
                    <button type="submit" className="primary-btn w-100">
                      Verify OTP
                    </button>
                  </div>
                </form>
              ) : (
                // Render phone number input form if OTP is not sent
                <form className="pt-3" onSubmit={handleSubmit}>
                  <div className="d-flex">
                  <p className="m-0 px-2 d-flex align-items-center border1  "> +91</p>
                  <input
                    type="tel"
                    name="number"
                    value={phone_number}
                    required
                    onChange={handleNumberChange}
                    placeholder="Phone Number"
                    className="border2 px-4 py-2 w-100"
                  />
                  </div>
                  <div className="pt-3">
                    <button type="submit" className="primary-btn w-100 ">
                      Sign In
                    </button>
                  </div>
                </form>
              )}
              <div className="pt-3">
                <p className="text-center"> ______________ or ______________ </p>
              </div>
              <div className="pt-3">
                <Link to="/loginwithpassword" className="secondary-btn py-2 text-decoration-none">
                    <button className="btn w-100 py-1  " type="button">

                    Sign in with Password
                    </button>
                
                </Link>
              </div>
              <div className="pt-3">
                <p className="text-center pt-3">
                  Don't have an account?
                  <Link to="/completeprofile" className="text-main-color">
                    Register here
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default PatientLogin;
