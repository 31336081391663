import React, { useState } from 'react'
import Header from '../Header';
import Footer from '../Footer';
const Consent = () => {
  const [updated, setupdated] = useState(" 27 April 2024");

  return (
    <div>
      <Header></Header>
      <section style={{ paddingTop: "60px" }}>
        <div className="container">
          <h1 className=' text-start fw-bold m-4 '>Consent</h1>
          <p className='text-start fw-semibold m-4'>Last updated: {updated}</p>

          <p className='text-justify m-4'>
            Through this privacy policy, we wish to provide information to users of our services about how and why BaelHealth AS ("Bael's") collects and processes personal data. This privacy policy applies to the use of BaelHealth's services, for example if you have shown an interest in, or are using our services, your employer has shared your contact information with us, health care (health care including specialist and primary care) or others to whom you have given access your health data, have shared information with us.It is important to us that your personal data and your personal integrity are protected, and that we process information that we have about you in a legal and transparent manner and in line with the privacy regulations. Personal data is any information about an identified or identifiable natural person. BaelHealth, by the company's top manager, is responsible for the processing of information collected by BaelHealth itself. The day-to-day processing responsibility is delegated to the CMO. In some situations, we will also be a data processor. In situations where we are a data processor, we have usually been sent the personal data from one of our customers, for example a healthcare company. See below for more information about this, or contact us (see contact information below).The privacy policy that follows below contains information you are entitled to when personal data is collected through BaelHealth and general information about how we process 
            your personal data.Your personal data is processed in accordance with the privacy regulations in force at all times.r personal data.Your personal data is processed
            in accordance with the privacy regulations in force at all times.
          </p>
          <h2 className='text-start fw-bold m-4'>1. Our use of personal data</h2>

          <p className='text-justify m-4'>
            We collect and process personal data in order to be able to fulfill our obligations towards our users,
            to be able to manage the business, to be able to fulfill our obligations towards users of BaelHealth'
            software (incl. digital platform, mobile application and web application), including websites, and
            otherwise fulfill the requirements set for us as responsible for processing your personal data. In
            order to provide proper health care to individuals, it is necessary that the individual is identified in a
            proper manner, and that personal information is registered and stored for as long as we have a factual need
            for the information. This is to be able to ensure that the right individual is provided with healthcare to the
            right healthcare organization and healthcare personnel,
            and that the healthcare institution responsible for treatment knows the identity of the individual.
          </p>
          <p className='text-justify m-4'>
            A little more about why we collect personal data and the type of data that is collected?
          </p>
          <p className='text-justify m-4'> <ul>
            <li>Dispatch of relevant medical information – the stored health information will be used to provide you with relevant and appropriate information.</li>
            <li>Name, telephone number and e-mail are used to be able to answer inquiries from users and others who contact us. We consider this to be important in order to be able to fulfill whatever needs the users may have, and to assist them.</li>
            <li> Identity and relevant health data are collected in order to facilitate secure communication, follow-up and treatment by relevant health personnel.</li>
            <li>Use of the service. In order for BaelHealth to be improved, the way you use the app is collected anonymously. Relevant healthcare personnel, to whom you consent, will also be able to see information about this.</li>
            <li> Relevant medical information is collected, which you register in the application, with your consent. This is so that you can share this with your relevant healthcare professionals, and they can provide you with treatment.</li>
          </ul></p>

          <p className='text-justify m-4'>
            Personal data that we process as data controller is mainly obtained from you directly, for example via inquiries from you, your activity on our website, application and digital platforms, or we have received your information via your employer or medical practitioner, because you are an employee or a patient of one of our partners/customers. However, in some cases BaelHealth also collects information from third parties. There can be sources from public, accessible registers or from authorities. We will try to notify you of such collection as soon as we can, unless the collection of the information is required by law,
            notification is impossible or very difficult, or it is obvious that we have collected such information about you.
          </p>
          <h2 className='text-start fw-bold m-4'>2. Disclosure of personal data - Transfer of personal data to third parties</h2>
          <p className="text-justify m-4">
            Your personal data is treated confidentially with us and only those with a
            business need with us will have access to your personal data. As a general
            rule, we will therefore not share your personal data with anyone outside BaelHealth.Information about you will be stored in BaelHealth'
            central database.Personal data may be made available to third parties who help us with the technical operation of our
            IT and accounting systems. When we give third parties access to personal data in this way, we make use of data processing
            agreements so that we ensure that the third parties comply with our requirements for the processing of personal data and
            applicable legislation. It may also be that, in some contexts, we share some of your personal data with our partners,
            such as the health institutions for e.g. interaction. Note that we will never share your personal data with a third party
            without your consent, that we have a legitimate interest in this, or that we are obliged to do so by law .You can always feel confident that we want to
            safeguard your privacy when you provide us with your personal information, or we collaborate with other actors.
          </p>
          <h4 className='text-start fw-bold m-4'>Still have a question?
          </h4>
          <p className='text-justify m-4'>Contact us at <a href="mailto:contact@tecraki.io" className='text-decoration-none text-primary'>contact@tecraki.io</a></p>
          <button className='btn btn-primary2 m-4'>Contact Us <i className='bi bi-arrow-right'></i></button>

        </div>
      </section>
      <section>
        <Footer></Footer>
      </section>
    </div>
  )
}

export default Consent
