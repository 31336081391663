import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import Aos from "aos";
import { Chrono } from "react-chrono";
import Testemonial from "../Testemonial";

const Doctor = () => {
  useEffect(() => {
    Aos.init();
  }, []);
  const items = [
    { title: "Step 1" },
    { title: "Step 2" },
    { title: "Step 3" },
    { title: "Step 4" },
  ];

  return (
    <div>
      <Header />

      <section
        className="doctor-section"
        style={{ paddingTop: "66px" }}
        id="doctor"
      >
        <div className="container front-section">
          <div className="row align-items-center">
            <div className="col-md-6 doc-coloum">
              <h1 className="fw-bold">
                Empowering Easy
                <span className="good-text">Doctor- Patient</span> interaction.
              </h1>
              <p className="fw-semibold">
                Enhancing overall health care system for both doctors and
                patients. Streamlining seamless communication, effortless and
                easy access, better results.
              </p>
              <div className=" d-flex mt-4 gap-1">
                {/* <Link to="#" className='dow-img'> <img src="assets/img/app1.jpg" className="img-fluid rounded-2 playstore" alt="" /></Link>
                                <Link to="#" className='dow-img'> <img src="assets/img/play1.jpg" className="img-fluid rounded-2 playstore" alt="" /></Link> */}
                <Link to="/contact" className="btn btn-primary2">
                  Contact us for a demo
                </Link>
              </div>
            </div>
            <div className="col-md-6  align-self-center  img-container">
              <img
                src="assets/img/webimg/img1.png"
                alt=""
                 style={{height: '90%', width: '90%'}}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section p-4">
        <div className="container">
          <h1 className="section-heading text-center text-justify p-4 ">
          We care equally for the needs of Doctors & Patients and support you throughout your healthcare journey.
          </h1>
        </div>
      </section>
      <section className="patient-stripe">
        <div className="container " data-aos="fade-right">
          <div className="row  stripe-bar">
            <div className="col-sm-3 ">
              <i className="bi bi-bar-chart text-danger-emphasis fs-1"> X5</i>
              <p> Better Care Delivery</p>
            </div>
            <div className="col-sm-3">
              <i className="bi bi-emoji-smile  text-danger-emphasis fs-1">
                86%
              </i>

              <p>Happy Patients</p>
            </div>
            <div className="col-sm-3">
              <i className="bi bi-people-fill text-danger-emphasis fs-1">
                1000 +
              </i>
              <p> Active Users</p>
            </div>
            <div className="col-sm-3">
              <i className="bi bi-pen text-danger-emphasis fs-1"></i>
              <p> E - Prescription</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row p-4 ">
            <div className="col-md-6" data-aos="fade-right">
              <img
                src="assets/img/webimg/img5.jpg"
                className="rounded img-fluid"
                alt=""
              />
            </div>
            <div className="col-md-6 align-self-center " data-aos="fade-left">
              <h2 className="section-heading pb-4">
                Doctors Preference is Bael health. Here is why!
              </h2>
              {/* <p>Open BaelHealth for as little as 30 seconds a day to create long-lasting health habits, supported by your doctor, therapist, or coach and mountains of scientific evidence.</p> */}
              <ul className="unordered-li p-0">
                <li className="d-flex">
                  <i className="bi  bi-check-circle-fill tick fs-4 me-2"> </i>
                  <p>
                    Quick access to patients' health records in one tap such as
                    entire medical history, treatments, vitals etc.
                  </p>
                </li>
                <li className="d-flex">
                  <i className="bi  bi-check-circle-fill tick fs-4 me-2"> </i>
                  <p>
                  Digital documentation ensures accuracy.
                  </p>
                </li>
                <li className="d-flex">
                  <i className="bi  bi-check-circle-fill tick fs-4 me-2"> </i>
                  <p>
                  	Provision to make separate notes for making comprehensive decisions. 
                  </p>
                </li>
                <li className="d-flex">
                  <i className="bi  bi-check-circle-fill tick fs-4 me-2"> </i>
                  <p>
                  	Well coordination with pharmacists of the healthcare team.
                  </p>
                </li>
                <li className="d-flex">
                  <i className="bi  bi-check-circle-fill tick fs-4 me-2"> </i>
                  <p>
                  Secure messaging and appointment schedule feature helps patients to have collaborative relationships. 
                  </p>
                </li>
              </ul>
              <div
                className=" d-flex mt-4 gap-1 "
                data-aos="fade-right"
                aos-delay="300"
              >
                {/* <Link to="#" className='dow-img'> <img src="assets/img/app1.jpg" className="img-fluid rounded-2 playstore" alt="" /></Link>
                                <Link to="#" className='dow-img'> <img src="assets/img/play1.jpg" className="img-fluid rounded-2 playstore" alt="" /></Link> */}
                <Link to="/contact" className="btn btn-primary1">
                  Contact us for a demo
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5">
        <div className="container">
          <div className="row scroll ">
            <div
              className="col-md-6   div1-scroll   d-flex flex-column justify-content-center align-items-center"
              data-aos="fade-right"
            >
              <h3 className="section-heading pb-3"> How to Get Started </h3>
              <Link to="/contact" className="btn btn-primary1">
                Contact us for a demo
              </Link>
            </div>
            <div
              className="col-md-6  timeline-container"
              style={{ height: "100vh" }}
              data-aos="fade-left"
            >
              <div>
                <Chrono
                  mode="VERTICAL"
                  disableToolbar
                  items={items}
                  cardHeight="100%"
                  scrollable="true"
                  theme={{ primary: "maroon", secondary: "pink" }}
                >
                  <div className="col step-p event">
                    <span className="number">01</span>
                    <h3>Register</h3>
                    {/* <p>Receive an invite</p> */}

                    <img
                      src="assets/img/webimg/register.svg"
                      className="rounded img-fluid"
                      alt=""
                      style={{height:"350px", width:"350px"}}
                    />
                  </div>
                  <div className="col step-p  event">
                    <span className="number">02</span>
                    <h3>Lets meet for setup your clinic</h3>
                    {/* <p>In Apple Store or Google Play</p> */}

                    <img
                      src="assets/img/webimg/doctor_svg2.svg"
                      className="rounded img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="col step-p  event">
                    <span className="number">03</span>
                    <h3>Invite Patients</h3>
                    {/* <p>Remember to remember your pin code</p> */}

                    <img
                      src="assets/img/webimg/invite.svg"
                      className="rounded img-fluid"
                      style={{height:"350px", width:"350px"}}
                      alt=""
                    />
                  </div>
                  <div className="col step-p  event">
                    <span className="number">04</span>
                    <h3>Result</h3>
                    {/* <p>Your health care provider will be in touch with you. Tip: Connect other apps to synch health data.</p> */}

                    <img
                      src="assets/img/webimg/result.svg"
                      className="rounded img-fluid"
                      style={{height:"350px", width:"350px"}}
                      alt=""
                    />
                  </div>
                </Chrono>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row pt-4 pb-4">
            <div className="col-sm-5 align-self-center">
              <h1 className="section-heading pb-2"> FAQs</h1>

              <p className="fw-semibold"> Still have a question? Contact us.</p>
              <Link to="/contact" className="btn btn-primary1">
                Contact us <i className="bi bi-arrow-right"></i>
              </Link>
            </div>
            <div className="col-sm-7">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item p-4">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Can I have complete access to patients Medical history
                      like Old prescription & lab reports ,vitals history?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Yes, You can have immediate access to the entire health
                      history of a patient with consent.
                    </div>
                  </div>
                </div>

                <div class="accordion-item p-4">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-three"
                      aria-expanded="false"
                      aria-controls="flush-three"
                    >
                      Is there provision to make separate notes that will
                      visible for me when patient will visit us?
                    </button>
                  </h2>
                  <div
                    id="flush-three"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Yes, we understand the need of making separate notes which
                      are only visible to the user.
                    </div>
                  </div>
                </div>

                <div class="accordion-item p-4">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-four"
                      aria-expanded="false"
                      aria-controls="flush-four"
                    >
                      Can I view the medical prescriptions recommended by other
                      doctors?
                    </button>
                  </h2>
                  <div
                    id="flush-four"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Yes, with patients consent doctors can easily examine the
                      prescriptions recommended by other doctors.
                    </div>
                  </div>
                </div>

                <div class="accordion-item p-4">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-five"
                      aria-expanded="false"
                      aria-controls="flush-five"
                    >
                      Is it secure to communicate with patients and health
                      providers through Bael Health system?
                    </button>
                  </h2>
                  <div
                    id="flush-five"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Yes, Belhealth, provides seamless and secure
                      communication.
                    </div>
                  </div>
                </div>
                <div class="accordion-item p-4">
                  <h2 class="accordion-header">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-six"
                      aria-expanded="false"
                      aria-controls="flush-five"
                    >
                      Does Bael Health system provide the facility to prescribe
                      medicine to patients through the app?
                    </button>
                  </h2>
                  <div
                    id="flush-six"
                    class="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div class="accordion-body">
                      Yes, Bael Health system provides the facility to prescribe
                      medicine to patients through application itself.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testemonial className="pb-3"></Testemonial>
      <Footer />
    </div>
  );
};

export default Doctor;
