import React, { useContext, useEffect, useState } from "react";
import profile from "../../../assets/images/happyfaces1.jpg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { PatientContext } from "../../contex/PatientContex";


const Viewmember = () => {
   const baseurl=process.env.REACT_APP_BASEURL;
  //  const [familyMembers, setFamilyMembers] = useState([]);
 const { user,setuser} = useContext(PatientContext);
  const navigate =useNavigate();
  const[id, setId]= useState("");
   useEffect(()=>{
    const Id = localStorage.getItem('userId');
     if(Id){
       setId(Id);
     }
   },[])
   
 const [familyMembers, setFamilyMembers] = useState([]);
  // const familyMembers = [
  //   {
  //     name: "Ravindra",
  //     age: "20",
  //     gender: "Male",
  //     relation: "Son",
  //     aadharNo: "1234 1234 4569",
  //     patientId: "465946ftwdugb",
  //   },
  //   {
  //     name: "Ravindra",
  //     age: "20",
  //     gender: "Male",
  //     relation: "Son",
  //     aadharNo: "1234 1234 4569",
  //     patientId: "465946ftwdugb",
  //   },
  //   {
  //     name: "Ravindra",
  //     age: "20",
  //     gender: "Male",
  //     relation: "Son",
  //     aadharNo: "1234 1234 4569",
  //     patientId: "465946ftwdugb",
  //   },
  // ];
  useEffect(() => {
     if(id){
       fetchFamily();
     }
 
  }, [id]);

  // let id = "1";

  const fetchFamily = async() => {
    console.log(" get famlity by id  "+id);
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const  res = await axios.get(`${baseurl}/api/familyMember/getFamily/${id}`,
        {
          headers: {
            "Authorization": `Bearer ${token}`
          },
        }
      );
      console.log(res.data.familyMembers);
      setFamilyMembers(res.data.familyMembers);
    } catch (error) {
      // console.log(error.message);
      toast.error("Error: " + error.message);
      // console.error("Errorview:", error);
      // toast.error(error.response.request.statusText + "please login again" );
      // setTimeout(()=>{
      //   if(error.request.status ===401){
      //     navigate("/plogin")
      //   }
      // },1000)
      if(error.request.status===401){
        toast.error(" Unauthroized Login again");
         setTimeout(() => {
          
           navigate("/plogin");
         }, 1000);
        }
    }
  };

  return (
    <div className="p">
      <div className="p-3">
        <div className="d-flex justify-content-end mb-2">
          <Link
            to="/family/addmember"
            className="primary-btn px-3  text-decoration-none "
          >
            Add Member
          </Link>
        </div>
        {familyMembers.map((member, index) => (
          <div className="row p-3 rounded border shadow-sm mb-2" key={index}>
            <div className="col-sm-3 d-flex align-items-center">
              <img
                src={`${baseurl}/${member.profile_photo}`}
                alt=""
                className="rounded-circle "
                style={{ height: "100px", width: "100px" }}
              />
            </div>
            <div className="col-sm-9">
              <h4 className="text-center"> Profile Details </h4>
              <hr />
              <div className="row">
                <div className="col-sm-6">
                  <p className=""> Name: {member.name} </p>
                  <p className=""> Date of birth : {member.date_of_birth} </p>
                  <p className=""> Gender: {member.gender} </p>
                </div>
                <div className="col-sm-6">
                  <p className=""> Aadhar No: {member.aadharNumber} </p>
                  <p className=""> Relation: {member.relation}</p>
                  <p className=""> Patient Id : {member.id} </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <ToastContainer
       position="top-center"
       />
    </div>
  );
};

export default Viewmember;
