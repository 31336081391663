import React, { useEffect, useLayoutEffect} from 'react'
 import { useLocation } from 'react-router-dom'
const Scrolltop = () => {
  const location = useLocation();
useEffect(()=>{
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
     },[location.pathname])
  return  null;
}

export default Scrolltop
