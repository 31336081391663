import React from "react";
import { Link } from "react-router-dom";

const Header = ({ hideLoginButton }) => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img
              src="assets/img/finallogo.png"
              className="logo1"
              alt="Bootstrap"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">
                  Home
                </a>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                Who we serve 
                </a>
                <ul className="dropdown-menu ">
                  <li>
                    <Link className="dropdown-item" to="/patient">
                      For Patients
                    </Link>
                  </li>
                  <li>
                    <Link to="/doctor" className="dropdown-item">
                      For Doctors
                    </Link>
                  </li>

                  <li>
                    <Link to="/clinic" className="dropdown-item">
                      For Hospitals and Clinics
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                Who we are
                </a>
                <ul className="dropdown-menu ">
                  <li>
                    <Link className="dropdown-item" to="/about#about" href="#mission">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/about#mission" href="#mission">
                      Mission
                    </Link>
                  </li>
                  <li>
                    <Link to="/about#vision" className="dropdown-item">
                     Vision
                    </Link>
                  </li>

                  <li>
                    <Link to="/about#ourstory" className="dropdown-item">
                      Our Story
                    </Link>
                  </li>
                  <li>
                    <Link to="/about#team" className="dropdown-item">
                    Leadership Team
                    </Link>
                  </li>
                </ul>
              </li>
           
              <li className="nav-item">
                <Link className="nav-link" to="/blog">
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact us
                </Link>
              </li>

              {!hideLoginButton && ( // Only render the SignIn button if hideLoginButton is false
                // <Link to="/login"className="btn btn-primary1 pe-3 ps-3 pt-2 pb-2"> Sign in </Link>
                <div class="dropdown">
                  <button
                    class="btn btn-primary1 pe-3 ps-3 pt-2 pb-2  dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Login
                  </button>
                  <ul
                    class="dropdown-menu login-drop"
                    style={{ left: "-120px", backgroundColor:"#862c30", color: "#ffff" 
                    }}
                  >
                    <li>
                      <Link Link class="dropdown-item btn-primary1" to="/plogin" style={{ color:"white"}}>
                        Patient Login
                      </Link>
                    </li>
                    <li>
                      <Link class="dropdown-item btn-primary1 " to="/login" style={{ color:"white"}}>
                        Doctor Login
                      </Link>
                    </li>
                    <li>
                      <a class="dropdown-item btn-primary1" to="/login" style={{ color:"white"}}>
                        Clinic and Hospital Login
                      </a>
                    </li>
                  </ul>
                </div>
              )}
              {/* <button className="btn btn-primary1 pe-3 ps-3 pt-2 pb-2"> SignIn </button> */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
