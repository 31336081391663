import { faPlusCircle, faShareNodes } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../../Patient.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import ShareModal from "../ShareModal";

export const Healthrecord = () => {
  const baseurl = process.env.REACT_APP_BASEURL;
  const [record, setRecord] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();

  const columns = [
    { field: "record_id", headerName: "ID", width: 100 },
    { field: "record_name", headerName: "Record Name", width: 250 },
    { field: "doctor_name", headerName: "Doctor Name", width: 150 },
    { field: "facility_name", headerName: "Facility Name", width: 150 },
    {
      field: "date_of_prescription",
      headerName: "Record Date",
      width: 150,
    },
    {
      field: "file_link",
      headerName: "Attachment",
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <p className="btn" onClick={() => handleDownload(params.value)}>
          Download
        </p>
      ),
    },
    {
      field: "record_preview",
      headerName: "Record Preview",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <img
          src={`${process.env.REACT_APP_IMAGEURL}/${params.row.file_link}`}
          style={{ height: "100px", width: "100px", overflow: "hidden" }}
          alt="Preview"
        />
      ),
    },
  ];

  const handleDownload = async (fileLink) => {
    if (!fileLink) {
      console.error("File link is undefined");
      toast.error("File link is undefined");
      return;
    }
    try {
      const response = await axios.get(`${baseurl}/${fileLink}`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "downloaded-image.jpg"); // Specify default file name
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file", error);
      toast.error("Error downloading the file: " + error.message);
    }
  };

  const [id, setId] = useState("");
  useEffect(() => {
    const user = localStorage.getItem("userId");
    if (user) {
      setId(user);
    } else {
      console.error("User ID not found in localStorage");
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchdata();
    }
  }, [id]);

  const fetchdata = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        throw new Error("Authorization token not found");
      }
      const res = await axios.get(`${baseurl}/api/healthRecords/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        const rowsWithId = res.data.Result.map((row) => ({
          ...row,
          id: row.record_id, // Use the 'record_id' as the 'id'
        }));
        setRecord(rowsWithId);
      } else {
        toast.error("Error while fetching data: " + res.status);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.statusText + " please login again");
      setTimeout(() => {
        if (error.request.status === 401) {
          navigate("/plogin");
        }
      }, 1000);
    }
  };

  const getRowHeight = () => {
    return 80; // You can adjust this value according to your needs
  };

  const [isOpen, setIsOpen] = useState(false);
  const [dataToShare, setDataToShare] = useState(""); // State to hold the data to share

  const handleShare = (selectedRecord) => {
    if (!selectedRecord || !selectedRecord.file_link) {
      console.error("Selected record or file link is undefined");
      toast.error("Selected record or file link is undefined");
      return;
    }
    const linkToShare = `${process.env.REACT_APP_BASEURL}/${selectedRecord.file_link}`;
    setDataToShare(linkToShare);
    console.log("Data to share:", linkToShare); // Debugging log
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="p-3">
      <div className="col-sm-12 d-flex justify-content-end gap-1 mb-2">
        <button
          className="primary-btn"
          onClick={() => {
            if (selectedRow) {
              handleShare(selectedRow);
            } else {
              toast.warn("Please select a record to share.");
            }
          }}
        >
          <FontAwesomeIcon icon={faShareNodes} className="me-1" /> Share with
          any One
        </button>
        <Link to="/uploadrecord" className="primary-btn text-decoration-none ">
          <FontAwesomeIcon icon={faPlusCircle} className="me-1" /> Add Record
        </Link>
      </div>

      <div style={{ height: record.length === 0 ? 200 : "auto" }}>
        <DataGrid
          rows={record}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
          getRowHeight={getRowHeight}
          onSelectionModelChange={(newSelectionModel) => {
            if (newSelectionModel.length > 0) {
              const selectedRecordId = newSelectionModel[0];
              const selectedRecord = record.find(
                (rec) => rec.record_id === selectedRecordId
              );
              setSelectedRow(selectedRecord);
              console.log("Selected Record: ", selectedRecord); // Debugging log
            } else {
              setSelectedRow(null);
            }
          }}
        />
      </div>
      <ShareModal
        isOpen={isOpen}
        handleClose={handleClose}
        dataToShare={dataToShare}
      />
      <ToastContainer />
    </div>
  );
};

export default Healthrecord;
