import React, { useEffect, useState } from "react";
import "../../Patient.css";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { data } from "jquery";
const Viewrecord = () => {
  const baseurl = process.env.REACT_APP_BASEURL;
  const { id } = useParams(); 
  console.log(id);

  const [data, setRecord] = useState({});

  useEffect(() => {
    fetchRecord();
  }, []);

  const fetchRecord = async () => {
    try {
      const res = await axios.get(`${baseurl}/api/healthRecords/${id}`);
      setRecord(res.data.Result);
      console.log(res.data.Result);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="m-1 p-3 border shadow-sm bg-body-tertiary rounded">
      <div className="d-flex justify-content-between">
        <div>
          <p className="mb-1">Record Name</p>
          <p className="fw-semibold">{data.record_name}</p>
        </div>
        <div>
          <p className="mb-1">Doctor Name</p>
          <p className="fw-semibold">{data.doctor_name}</p>
        </div>
        <div>
          <p className="mb-1">Venue</p>
          <p className="fw-semibold">{}</p>
        </div>
        <div>
          <p className="mb-1">Date</p>
          <p className="fw-semibold">{data.date_of_prescription}</p>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <iframe
          src={`${baseurl}/${data.file_link}`}
          width="100%"
          height="100%"
          style={{ minHeight: "800px", border: "none" }}
          title="PDF"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Viewrecord;
