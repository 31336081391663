import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import Testemonial from "../Testemonial";
// const ListItemWithIcon = ({ linkTo, text, iconClass }) => {
//   return (
//     <li className="rounded list-home  m-1 p-2 d-flex align-items-center bg-white">
//       <Link
//         className="li-link text-black  text-justify flex-grow-1 fs-4 fw-semibold"
//         to={linkTo}
//       >
//         {text}
//       </Link>
//       <Link
//         className={`${iconClass} ms-1 tick fs-4 flex-grow-1 text-end`}
//         to={linkTo}
//       ></Link>
//     </li>
//   );
// };
const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Header></Header>
      <main id="main" style={{ paddingTop: "56px" }}>

        <section className=" front-section">
          <div className="container pt-4 mt-4 ">
            <h1 data-aos="fade-right" className=" fw-bold text-justify ">
              <span className="good-text"> Bael Health</span> -Digital Health
              Platform for Doctors & Patient.
            </h1>
            <p
              className="mb-5  fw-bold   text-justify main-para"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              We build solutions that make Digital Health Records accessible to
              everyone, anytime, anywhere.
            </p>
            <div className="row">
              <div className="col-md-8">
                <img
                  src="assets/img/webimg/mainpage.png"
                  className="front-img img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-4 align-self-center">
                <div className="d-flex align-items-center justify-content-center">
                  <ul className="unordered-list p-0 ">
                    <li className="p-1 bg-white mb-1 fs-5 rounded">
                      <Link
                        to="/patient"
                        className="text-decoration-none text-black d-flex justify-content-between px-2"
                      >
                        {" "}
                        <p className="p-0 m-0">For Patients</p>{" "}
                        <i className="bi bi-arrow-right-circle-fill  tick "></i>
                      </Link>
                    </li>
                    <li className="p-1 bg-white mb-1  fs-5 rounded">
                      <Link
                        to="/doctor"
                        className="text-decoration-none text-black  d-flex justify-content-between px-2"
                      >
                        <p className="p-0 m-0">For Doctors</p>{" "}
                        <i className="bi bi-arrow-right-circle-fill  tick "></i>
                      </Link>
                    </li>
                    <li className="p-1 bg-white mb-1 fs-5 rounded ">
                      <Link
                        to="/clinic"
                        className="text-decoration-none text-black  d-flex justify-content-between px-2"
                      >
                        <p className="p-0 m-0">For Clinics and Hospitals</p>{" "}
                        <i className="bi bi-arrow-right-circle-fill ms-2  tick "></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className=" d-flex mt-1 gap-2 justify-content-center">
                  <Link to="#" className="dow-img">
                    <img
                      src="assets/img/app1.jpg"
                      className="img-fluid rounded-2 playstore"
                      alt=""
                    />
                  </Link>
                  <Link to="#" className="dow-img">
                    <img
                      src="assets/img/play1.jpg"
                      className="img-fluid rounded-2 playstore"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section p-4">
          <div className="container">
            <h1 className="section-heading text-center text-justify p-4 ">
              Welcome to an era of better health & healthcare experience –
              Powered by Bael Health
            </h1>
          </div>
        </section>
        <section className="patient-stripe">
          <div className="container " data-aos="fade-right">
            <div className="row  stripe-bar">
              <div className="col-sm-3 ">
                <i className="bi bi-bar-chart tick fs-1"> X5</i>
                <p> Better Care Delivery</p>
              </div>
              <div className="col-sm-3">
                <i className="bi bi-emoji-smile  tick fs-1">86%</i>

                <p>Happy Patients</p>
              </div>
              <div className="col-sm-3">
                <i className="bi bi-people-fill tick fs-1">1000 +</i>
                <p> Active Users</p>
              </div>
              <div className="col-sm-3">
                <i className="bi bi-pen tick fs-1"></i>
                <p> E - Prescription</p>
              </div>
            </div>
          </div>
        </section>
        {/*  this is the Patient section ======================================================================== */}
        <section className="section pb-2">
          <div className="container">
            <div className="row  bael1">
              <div className="col-md-7 mb-5">
                <h1 className="section-heading pb-4" data-aos="fade-right">
                  Patient
                </h1>
                <div className="feature-1 p-1 row pt-2" data-aos="fade-right">
                  <div className="wrap-icon icon-1 ">
                    <i className="bi bi-gear-wide-connected"></i>
                  </div>
                  <div className="col align-self-center">
                    <h3 className="mb-3 fw-semibold">Easy Access</h3>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      Easy access to the medical records just in one tap.
                    </p>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      Maintain Entire Family’s and loved ones health record.
                    </p>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      Manage and Review health history, lab results,
                      medications, treatment plans and automated finances
                      conveniently.
                    </p>
                  </div>
                </div>
                <div className="feature-1 p-1 row pt-3" data-aos="fade-right">
                  <div className="wrap-icon icon-1 ">
                    <i className="bi bi-chat-square-quote"></i>
                  </div>
                  <div className="col align-self-center">
                    <h3 className="mb-3 fw-semibold">Seamless communication</h3>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      Easy for patients to communicate securely with their
                      healthcare provider or doctors.
                    </p>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      Allows better coordination with exchange of patients data
                      electronically.
                    </p>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      Saves time and reduces the chance of medical errors.
                    </p>
                  </div>
                </div>
                <div className="feature-1 p-1 row pt-3" data-aos="fade-right">
                  <div className="wrap-icon icon-1 ">
                    <i className="bi bi-wallet2"></i>
                  </div>
                  <div className="col align-self-center">
                    <h3 className="mb-3 fw-semibold">
                      Saves your Time and Money
                    </h3>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      Efficient appointment scheduling regardless of offline
                      visits. Thus, Saves your time!
                    </p>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      Better improved health with early reminders & updates from
                      the doctors and clinics
                    </p>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      Privacy and security is maintained.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-5 mb-5" data-aos="fade-left">
                <img
                  src="assets/img/webimg/oldmanmen.svg"
                  alt="Image"
                  className="img-fluid"
                />
                <div
                  className=" d-flex ps-5 gap-1 ms-5 download-img1  mt-4"
                  data-aos="fade-right"
                  aos-delay="300"
                >
                  <Link to="#" className="dow-img">
                    <img
                      src="assets/img/app1.jpg"
                      className="img-fluid rounded-2 playstore"
                      alt=""
                    />
                  </Link>
                  <Link to="#" className="dow-img">
                    <img
                      src="assets/img/play1.jpg"
                      className="img-fluid rounded-2 playstore"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*  this is the doctor section ======================================================================== */}

        <section className="section pb-2 pt-0">
          <div className="container">
            <div className="row  bael1">
              <div className="col-md-6 mb-5" data-aos="fade-left">
                <img
                  src="assets/img/webimg/img3.jpg"
                  alt="Image"
                  className="img-fluid doctor-sectiion_svg"
                />
              </div>
              <div className="col-md-6 mb-5 align-self-center">
                <h1 className="section-heading pb-4" data-aos="fade-right">
                  Doctor
                </h1>
                <div className="feature-1 p-1 row pt-2" data-aos="fade-right">
                  <div className="wrap-icon icon-1 ">
                    <i className="bi bi-activity"></i>
                  </div>
                  <div className="col align-self-center">
                    <h3 className="mb-3 fw-semibold">
                      Quick Access to patients Health record Enhanced patients
                      care
                    </h3>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      through digital health records, in patients' accounts.
                    </p>
                  </div>
                </div>
                <div className="feature-1 p-1 row pt-3" data-aos="fade-right">
                  <div className="wrap-icon icon-1 ">
                    <i className="bi bi-gear-wide-connected"></i>
                  </div>
                  <div className="col align-self-center">
                    <h3 className="mb-3 fw-semibold"> Immediate access </h3>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      to patients' medical records, vitals and medications that
                      enable more informed decisions.
                    </p>
                  </div>
                </div>
                <div className="feature-1 p-1 row pt-3" data-aos="fade-right">
                  <div className="wrap-icon icon-1 ">
                    <i className="bi  bi-broadcast"></i>
                  </div>
                  <div className="col align-self-center">
                    <h3 className="mb-3 fw-semibold">Well-coordination</h3>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      With the patient's consent Doctors can share patient
                      information with clinic’s, pharmacists of the healthcare
                      team.
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-end me-5">
                  <Link
                    className="btn btn-primary1  p-1 text-end px-2"
                    to="/contact"
                  >
                    {" "}
                    Contact Us for demo
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  this is the  clicnic section ======================================================================== */}
        <section className="section pt-2 pb-4 mb-4">
          <div className="container">
            <div className="row  bael1">
              <div className="col-md-6 align-self-center">
                <h1 className="section-heading pb-4" data-aos="fade-right">
                  Clinic and Hospital
                </h1>
                <div className="feature-1 p-1 row pt-2" data-aos="fade-right">
                  <div className="wrap-icon icon-1 ">
                    <i className="bi  bi-pc-display"></i>
                  </div>
                  <div className="col align-self-center">
                    <h3 className="mb-3 fw-semibold">
                      Simplified Administrative Works
                    </h3>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      such as appointment scheduling, bill clearance and reduced
                      paperwork.
                    </p>
                  </div>
                </div>
                <div className="feature-1 p-1 row pt-3" data-aos="fade-right">
                  <div className="wrap-icon icon-1 ">
                    <i className="bi bi-wallet2"></i>
                  </div>
                  <div className="col align-self-center">
                    <h3 className="mb-3 fw-semibold">Monitor your expenses</h3>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      No paper based processes,lead to decrease duplicate tests
                      and prevent medical errors which can lead to costly
                      complications.
                    </p>
                  </div>
                </div>
                <div className="feature-1 p-1 row pt-3" data-aos="fade-right">
                  <div className="wrap-icon icon-1 ">
                    <i className="bi bi-journal-check"></i>
                  </div>
                  <div className="col align-self-center">
                    <h3 className="mb-3 fw-semibold">Easy Insurance claim</h3>
                    <p>
                      <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                      makes the process of claims quicker and allows insurers to
                      review and approve them more quickly. Hence, faster
                      reimbursement for healthcare providers and patients.
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-center me-5">
                  <Link
                    to="/contact"
                    className="btn btn-primary1  p-1 text-end px-2"
                  >
                    {" "}
                    Contact Us for demo
                  </Link>
                </div>
              </div>
              <div className="col-md-6 " data-aos="fade-left">
                <img
                  src="assets/img/webimg/clinic11.svg"
                  alt="Image"
                  style={{
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                   
                  }}
                  className="img-fluid clinic-section_svg"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="section pt-4 pb-4 mt-4 mb-4">
          <div className="container">
            <h1 className="section-heading fs-3">How to get Started </h1>

            <div className="row">
              <div className=" col-md-4 p-2  " data-aos="fade-right">
                <div className="step p-2 step-box  rounded">
                  <span className="number">01</span>
                  <h5>Sign Up</h5>
                  <p>
                    Sign up for our service by providing your basic information.
                  </p>
                </div>
              </div>
              <div className=" col-md-4 p-2 " data-aos="fade-up">
                <div className="step  p-2 step-box rounded">
                  <span className="number">02</span>
                  <h5>Create Profile</h5>
                  <p>
                    Create your profile by adding more details about yourself.
                  </p>
                </div>
              </div>
              <div className=" col-md-4 p-2 " data-aos="fade-left">
                <div className="step  p-2 step-box rounded">
                  <span className="number">03</span>
                  <h5>Enjoy the app</h5>
                  <p>
                    Start enjoying the features of our app and manage your
                    health easily.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
          {/* ====================== MIssion  ============================================================= */}
        {/* <section className="section  pt-4 pb-4">
          <div className="container">
            <div className="row ">
              <div className="col-md-6   align-self-center ">
                <h1 className="section-heading pb-4" data-aos="fade-right">
                  Our Mission
                </h1>
                <p className="mb-4 pb-4">
                  <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>Bael
                  Health is a healthtech platform that allows patients and
                  doctors to gain greater access, as well as the organization of
                  medical records which leads to improved healthcare.
                </p>
                <p className="mb-4 pb-4">
                  <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                  Organized format of Entire health records, prescriptions, lab
                  tests, vitals, etc.
                </p>
                <p className="mb-4 pb-4">
                  <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>Bael
                  Facilitates the efficient healthcare delivery by digitizing
                  and centralizing patient records is one of the key functions
                  of electronic health records systems.
                </p>
              </div>
              <div
                className="col-md-6 align-self-center clinic-section_svg"
                data-aos="fade-left"
              >
                <img
                  src="assets/img/undraw_svg_2.svg"
                  alt="Image"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section> */}
    
      <Testemonial></Testemonial>
      </main>
      <Footer></Footer>
    </>
  );
};

export default Home;
