import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
const Footer = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = async (data) => {
    try {
        // Implement your form submission logic here
        console.log(data);
        
        // Reset the form after successful submission
        reset();
        // Show success notification
        toast.success("Form submitted successfully!", {
            position: "top-center"
        });
    } catch (error) {
        // Handle form submission errors
        console.error("Form submission error:", error);
        // Show error notification
        toast.error("Failed to submit form. Please try again later.", {
            position: "top-center"
        });
    }
};

  return (
    <div>
      <footer className="footer p-4" role="contentinfo">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-4 mb-md-0">
              <div className="row">
                <div className="col">
                  <img
                    src="assets/img/finallogo.png"
                    className="logo1"
                    alt=""
                  />
                  <p className="mt-3">
                    Join our newsletter to stay up to date on features and
                    releases.
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)} className="footer-subscribe">
                    <div className="d-flex mb-1">
                      <input
                        type="email"
                        className="form-control rounded-0"
                        placeholder="Email"
                        {...register("email", {
                          required: true,
                          pattern: /^\S+@\S+$/i,
                      })}
                      />
                      <button
                        type="submit"
                        className="btn btn-secondary rounded-0 pt-1 pb-1 "
                      >
                        Join
                      </button>
                    </div>
                  </form>
                  <div className="fw-lighter mt-4">

                  <small  className=" ">
                    By subscribing you agree to with our  &nbsp;
                    <Link to="/privacypolicy" className="text-black">
                       Privacy Policy
                    </Link>
                    &nbsp; and provide consent to receive updates from our company.
                  </small>
                  </div>

                  <p className="email fs-5 ">
                  Contact: &nbsp; 
                    <Link
                      to="mailto:Baelhealth.india@gmail.com"
                      className="email-text  text-white"
                    >
                  Baelhealth.india@gmail.com
                    </Link>
                  </p>
                </div>

                <div className="col-md-2"></div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row site-section ">
                <div className="col-md-4  ">
                  <ul className="list-unstyled unordered-li">
                    <li>
                      <Link to="/patient">For Patient </Link>
                    </li>
                    <li>
                      <Link to="/doctor">For Doctors </Link>
                    </li>
                    <li>
                      <Link to="/clinic">For Clinics and Hospitals </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <ul className="list-unstyled unordered-li">
                    <li>
                      <Link to="/"> Home</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/about"> Who we are</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-md-4">
                  <ul className="list-unstyled unordered-li">
                    <p className="fw-semibold p-0"> Follow us </p>
                    <li>
                      <Link
                        to="https://www.facebook.com/people/BaelHealth/61558175377615/"
                        target="_blank"
                        className="m-1 bi bi-facebook"
                      >
                        &nbsp;Facebook
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.linkedin.com/company/tecraki-technology-solutions-pvt-ltd/about/"
                        target="_blank"
                        className="m-1 bi bi-linkedin"
                      >
                        &nbsp;LinkedIn
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://www.instagram.com/bael_health/"
                        target="_blank"
                        className="m-1 bi bi-instagram"
                      >
                        &nbsp;Instagram
                      </Link>
                    </li>
                    <li>
                      <Link to="/twwet" className="m-1 bi bi-twitter-x">
                        &nbsp;Twitter
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr />

          <div className="row site-section">
            <div className="col-md-3">
              <p> © 2024 BaelHealth. All right reserved.</p>
            </div>
            <div className="col-md-3 d-flex justify-content-evenly">
              <Link to="">
                <img
                  src="assets/img/google-trans.png"
                  alt=""
                  className=" footer-img img-fluid p-0 m-0 rounded"
                />
              </Link>

              <Link to="">
                <img
                  src="assets/img/appstore-trans.png"
                  alt=""
                  className=" footer-img img-fluid p-0 m-0 rounded"
                />
              </Link>
            </div>
            <div className="col-md-6">
              <ul className="list-inline  d-flex justify-content-evenly  ">
                <li className="list-inline-item ">
                  <Link to="/privacypolicy">Privacy Policy</Link>
                </li>
            
                <li className="list-inline-item ">
                  <Link to="/consent">Consent</Link>
                </li>
                <li className="list-inline-item ">
                  <Link to="/deletemyaccount">Delete my account</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="row justify-content-center text-center pt-4">
            <div className="col-md-7">
              <p className="copyright">
                &copy; Copyright Tecraki Technology Solutions Pvt Ltd. All
                Rights Reserved
              </p>
              <div className="credits">
                Designed by
                <a
                  href="http://www.tecraki.io"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-black"
                >
                  Tecraki Technology Solutions
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
