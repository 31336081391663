import React, { useState} from 'react';
import axios from 'axios';
import Header from '../Header';
import Footer from '../Footer';
const WriteBlog = () => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [photo, setPhoto] = useState(null);
  const [details, setDetails] = useState('');
  const [imagePreview, setImagePreview] = useState(null);



  const handleInputChange = (setState, e) => {
    setState(e.target.value);
  };

  const handleFileChange = (e) => {
    setPhoto(e.target.files[0]);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('subtitle', subtitle);
    formData.append('photo', photo);
    formData.append('details', details);

    try {
      const response = await axios.post('https://your-api-url.com/blog', formData);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };



  return (
    <>
      <Header></Header>
      <section className='blog-section'>
        <div className="container mt-4 pb-3" style={{ paddingTop: '56px' }}>

          <div className="row pt-4">
            <div className=" section-heading">
              <h1 className="fw-bold">Write a Blog</h1>
            </div>
            <div className="col-md-6 rounded  border border-danger-subtle p-3">
              <form onSubmit={handleSubmit}>
                <div>
                  <label className='fw-medium mb-1' htmlFor="title"> Insert  Blog Photo </label>
                  <br />
                  <input
                    type="file"
                    className="form-control-file mb-2"
                    onChange={(e) => handleFileChange(e)}
                  />
                </div>
                <div>
                  <label className='fw-medium mb-1' htmlFor="sub title"> Titile for blog </label>
                  <textarea
                    type="text"
                    rows="2"
                    className="form-control mb-2"
                    value={title}
                    placeholder="Enter title"
                    onChange={(e) => handleInputChange(setTitle, e)}
                  />
                </div>
                <div>
                  <label className='fw-medium mb-1' htmlFor="sub title "> Subtitle of blog </label>
                  <textarea
                    type="text"
                    className="form-control mb-2 "
                    rows="2"
                    value={subtitle}
                    placeholder="Enter subtitle"
                    onChange={(e) => handleInputChange(setSubtitle, e)}
                  />
                </div>


                <div>
                  <label className='fw-medium mb-1' htmlFor="description"> Description of Blog </label>
                  <textarea
                    className="form-control mb-2"

                    value={details}
                    placeholder="Enter details"
                    rows="10"
                    onChange={(e) => handleInputChange(setDetails, e)}
                  />
                </div>
                <button type="submit" className="btn btn-primary2 ">
                  Post
                </button>
              </form>
            </div>
            <div className="col-md-6 blog-upload-img">

              {imagePreview && (
                <img
                  src={imagePreview}
                  alt="preview"
                  className="mt-2 img-fluid rounded-4 blog-img1"
                  style={{ maxWidth: '100%' }}
                />
              )}


              <div className="title mt-2">
                <h2 className='fw-bold'>{title}</h2>
              </div>

              <div className='mt-2'>
                <p className='fw-semibold fs-4'>{subtitle}</p>
              </div>

              <div className='mt-2'>
                <p className='newline'>{details}</p>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>

      </section>
    </>


  );
};

export default WriteBlog;