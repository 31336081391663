import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { EmailIcon, FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from "react-share";

const ShareModal = ({ isOpen, handleClose, dataToShare }) => {
  // Function to validate if the given string is a valid URL
  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <>
      <div className={`modal ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Share with Anyone</h5>
              <button
                type="button"
                className="btn-close"
                onClick={handleClose}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {isValidUrl(dataToShare) ? (
                <div className="share-buttons d-flex gap-2 justify-content-evenly">
                  <FacebookShareButton url={dataToShare}>
                    <FacebookIcon size={40} round />
                  </FacebookShareButton>
                  <WhatsappShareButton url={dataToShare} title="Check out this health record">
                    <WhatsappIcon size={40} round />
                  </WhatsappShareButton>
                  <EmailShareButton url={dataToShare} subject="Shared from React App">
                    <EmailIcon size={40} round />
                  </EmailShareButton>
                  <LinkedinShareButton url={dataToShare}>
                    <LinkedinIcon size={40} round />
                  </LinkedinShareButton>
                  <TwitterShareButton url={dataToShare} title="Check out this health record">
                    <TwitterIcon size={40} round />
                  </TwitterShareButton>
                </div>
              ) : (
                <p className="text-danger">Invalid URL provided for sharing.</p>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="primary-btn px-4"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default ShareModal;
