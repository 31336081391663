import React, { useEffect, useRef } from "react";
import Header from "../Header";
import Footer from "../Footer";
import AOS from "aos";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedin,
  faLinkedinIn,
  faMeta,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Aboutus = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <Header></Header>
      <section className="about-us" id="about" style={{ paddingTop: "56px" }}>
        <div className="container">
          <div className="row p-4 about-row align-self-center">
            <div className="col-sm-6">
              <h2 className="fw-bold ">
                We have learned our lesson – the answer was BaelHealth
              </h2>
              <p className="fw-semibold ">
                We’re on a mission to make health more accessible to everyone.
              </p>
              {/* <button className='btn btn-primary1'>Download</button> */}
              <div
                className=" d-flex mt-4 gap-1 "
                data-aos="fade-right"
                aos-delay="300"
              >
                <Link to="#" className="dow-img">
                  <img
                    src="assets/img/app1.jpg"
                    className="img-fluid rounded-2 playstore"
                    alt=""
                  />
                </Link>
                <Link to="#" className="dow-img">
                  <img
                    src="assets/img/play1.jpg"
                    className="img-fluid rounded-2 playstore"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="col-sm-6">
              <img
                src="assets/img/webimg/about2.svg"
                className="img-fluid"
                alt=""
                style={{ height: "150%", width: "150%" }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="section p-4">
        <div className="container">
          <h1 className="section-heading text-center text-justify p-4 ">
            Better healthcare experience & improve your health with Bael.
          </h1>
        </div>
      </section>
      <section className="patient-stripe">
        <div className="container " data-aos="fade-right">
          <div className="row  stripe-bar">
            <div className="col-sm-3 ">
              <i className="bi bi-bar-chart text-danger-emphasis fs-1"> X5</i>
              <p> Better Care Delivery</p>
            </div>
            <div className="col-sm-3">
              <i className="bi bi-emoji-smile  text-danger-emphasis fs-1">
                86%
              </i>

              <p>Happy Patients</p>
            </div>
            <div className="col-sm-3">
              <i className="bi bi-people-fill text-danger-emphasis fs-1">
                1000 +
              </i>
              <p> Active Users</p>
            </div>
            <div className="col-sm-3">
              <i className="bi bi-pen text-danger-emphasis fs-1"></i>
              <p> E - Prescription</p>
            </div>
          </div>
        </div>
      </section>
      {/*   ====================== MIssion  ============================================================= */}
      <section className="section  pt-4 pb-2 mb-0">
        <div className="container" id="mission">
          <div className="row ">
            <div className="col-md-6   align-self-center justify">
              <h1 className="section-heading pb-4" data-aos="fade-right">
                Our Mission
              </h1>
              <p className="mb-2 pb-2 ">
                We believe digitization of healthcare is essential for care
                delivery and we strive to create the best-in-class technology
                solutions for our customers.
              </p>
              <h3 className="pb-2">
                Create technology solutions & products that :
              </h3>
              <p className="mb-2 pb-2">
                <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                Bael Empower Patients - Take control of their health by
                providing easy access to consolidated health data.
              </p>
              <p className="mb-2 pb-2">
                <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                Enable Providers (Doctors & Clinics) - Deliver better care by
                having a 360 view of their patients’ health.
              </p>
            </div>
            <div
              className="col-md-6 align-self-center clinic-section_svg"
              data-aos="fade-left"
            >
              <img
                src="assets/img/webimg/undraw_svg_2.svg"
                alt="Image"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
      {/* ==================== vision  ========================= */}
      <section className="section  pt-3 pb-3">
        <div className="container" id="vision">
          <div className="row ">
            <div
              className="col-md-6 align-self-center clinic-section_svg"
              data-aos="fade-left"
            >
              <img
                src="assets/img/webimg/vision.svg"
                alt="Image"
                className="img-fluid"
              />
            </div>
            <div className="col-md-6   align-self-center justify">
              <h1 className="section-heading pb-4" data-aos="fade-right">
                Our Vision
              </h1>

              <p className="mb-2 pb-2 justify">
                We want to empower our users by helping them use the latest &
                most efficient technology; to create solutions & products that
                help them achieve not just better health but also excellence and
                efficiency in business processes, thus flourishing business
                growth and better Return on Investment (RoI).
              </p>
              <h3 className="pb-2">
                We envision solving following needs of our users:
              </h3>
              <p className="mb-4 pb-4">
                <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>Access
                to consolidated health records, the ability to share those
                records & search for healthcare providers as needed.
              </p>
              <p className="mb-4 pb-4">
                <i class="bi bi-check-circle-fill tick fw-bold">&nbsp;</i>
                Access to consolidated health records, the ability to share
                those records & search for healthcare providers as needed.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container" id="ourstory">
          <div className="row pt-3 pb-3">
            <div
              className="col-sm-6 p-3 pt-4  mt-4 text-start"
              data-aos="fade-left"
            >
              <h1 className="fw-bold pt-3 pb-3 pt-4 mt-4 section-heading">
                Story behind BaelHealth
              </h1>
              <p className="justify">
                The idea of BealHealth have been started in 2020 during Corona
                Pandemic When Lot of digitalization was going on in heathcare
                space people are facing lot of issues regarding digitalizatio
                keeping record , e-prescription , tele-consultation etc. The
                have been Consolidated in (January 2024 ) after doing rigorous
                survey and interviewing different patients. Beal Health Always
                try to ease healthcare digitalization in a patents centric
                application so thaat patient can find maximum ease and benefits
                from their health records and current healthcare facilities.
              </p>
              <Link className="btn-primary1 btn px-3 py-2" to="/contact">
                Contact Us
              </Link>
            </div>
            <div
              className="col-sm-6   d-flex align-items-center"
              data-aos="fade-right"
            >
              <img
                src="assets/img/webimg/aboutus.svg"
                className="rounded img-fluid"
                alt=""
                style={{ height: "90%" }}
              />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className=" p-3 pt-4  mt-4 text-start" data-aos="fade-left">
            <h1 className="fw-bold pt-3 pb-3 pt-4 mt-4 section-heading">
              Our Approach
            </h1>
            <p className="justify">Empowering Health Through Innovation</p>
            <ul>
              <li>
                Patient-Centric Design: Our focus is on creating user-friendly
                interfaces that empower patients to manage their health
                effectively, promoting engagement and better outcomes.
              </li>
              <li>
                Interoperability and Integration: We prioritize seamless
                communication between healthcare systems and digital tools,
                ensuring comprehensive patient information is accessible for
                coordinated care.
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/*   this is tema leardership section  */}
      <section>
        <div className="container" id="team">
          <div className="p-3 pt-4  mt-4 text-start" data-aos="fade-left">
            <h1 className="fw-bold pt-3 pb-3 pt-4 mt-4 section-heading">
              Leadership Team
            </h1>
            <p>
              Our leadership team comprises experienced professionals dedicated
              to pioneering advancements in healthcare.
            </p>
            <div>
              <div
                id="carouselExampleRide"
                class="carousel slide"
                data-bs-ride="true"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div className="row p-2">
                      <div className="col-md-4 p-3">
                        <div className="p-3 bg-body-tertiary1 rounded shadow-sm">
                          <div className="d-flex justify-content-start">
                            <img
                              src="assets/img/person/ceo.jpg"
                              class="img-fluid rounded team-img"
                              alt="..."
                              style={{ height: "250px", width: "250px" }}
                            />
                          </div>
                          <div className="name fw-semibold pb-2 pt-2">
                            Prashant Yadav
                          </div>
                          <div className="desig">
                            <p className="fst-italic text-secondary">
                              CEO of Tecraki Technology Solutions Pvt. LTD.
                            </p>
                          </div>
                          <div className="para">
                            <p>
                              Meet Prashant, an alumni of Stanford university
                              Graduate school of Business. Our CEO is an expert
                              in software engineering and Business Growth. With
                              20 years of experience in data management and
                              revenue growth, and a knack for keeping everyone
                              on track by enabling opportunities, Prashant is
                              the glue that holds and leads the team and of
                              managers, analysts and engineers.
                              <br /> <br />
                            </p>
                          </div>

                          <div className="social d-flex justify-content-evenly">
                            <Link
                              to=""
                              className="px-3 py-2 bg-danger-subtle rounded-circle  d-flex justify-content-center align-items-center"
                            >
                              <FontAwesomeIcon
                                icon={faXTwitter}
                                className="fs-5"
                              />
                            </Link>
                            <Link
                              to=""
                              className="px-3 py-2 bg-danger-subtle rounded-circle  d-flex justify-content-center align-items-center"
                            >
                              <FontAwesomeIcon icon={faMeta} className="fs-5" />
                            </Link>
                            <Link
                              to="https://www.linkedin.com/in/prashant-yadav-gsb/"
                              className="px-3 py-2 bg-danger-subtle rounded-circle  d-flex justify-content-center align-items-center"
                            >
                              <FontAwesomeIcon
                                icon={faLinkedinIn}
                                className="fs-5"
                              />
                            </Link>
                            <Link
                              to=""
                              className="px-3 py-2 bg-danger-subtle rounded-circle  d-flex justify-content-center align-items-center"
                            >
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                className="fs-5"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 p-3">
                        <div className="p-3 bg-body-tertiary1 rounded shadow-sm">
                          <div className="d-flex justify-content-start">
                            <img
                              src="assets/img/person/noimg.png"
                              class="img-fluid rounded team-img"
                              alt="..."
                              style={{ height: "250px", width: "250px" }}
                            />
                          </div>
                          <div className="name fw-semibold pb-2 pt-2">
                            Shalini Singh
                          </div>
                          <div className="desig">
                            <p className="fst-italic text-secondary">
                              CEO of Tecraki Technology Solutions Pvt. LTD.
                            </p>
                          </div>
                          <div className="para">
                            <p>
                              Say Hello to, Shalini our CEO and full stack
                              developer with an experience in web Designing and
                              Database management mastermind. With years of
                              experience in designing, she brings the
                              extraordinary touch of tech wizardry and
                              innovative flair to our team. As an expert of
                              technical, and designing, Shalini oversees
                              everything from brainstorming to launch, working
                              closely with the team and client to shape their
                              dreams into reality.
                            </p>
                          </div>

                          <div className="social d-flex justify-content-evenly">
                            <Link
                              to=""
                              className="px-3 py-2 bg-danger-subtle rounded-circle  d-flex justify-content-center align-items-center"
                            >
                              <FontAwesomeIcon
                                icon={faXTwitter}
                                className="fs-5"
                              />
                            </Link>
                            <Link
                              to=""
                              className="px-3 py-2 bg-danger-subtle rounded-circle  d-flex justify-content-center align-items-center"
                            >
                              <FontAwesomeIcon icon={faMeta} className="fs-5" />
                            </Link>
                            <Link
                              to="https://www.linkedin.com/in/shalini-singh-705a162a6/"
                              className="px-3 py-2 bg-danger-subtle rounded-circle  d-flex justify-content-center align-items-center"
                            >
                              <FontAwesomeIcon
                                icon={faLinkedinIn}
                                className="fs-5"
                              />
                            </Link>
                            <Link
                              to=""
                              className="px-3 py-2 bg-danger-subtle rounded-circle  d-flex justify-content-center align-items-center"
                            >
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                className="fs-5"
                              />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleRide"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleRide"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">Next</span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className=" p-3 pt-4  mt-4 text-start" data-aos="fade-left">
            <h1 className="fw-bold pt-3 pb-3 pt-4 mt-4 section-heading">
              Advisors
            </h1>
            <p>
              Our advisory team consists of experts from diverse fields,
              offering invaluable insights and guidance for innovative
              healthcare solutions.
            </p>
            <div className="row p-2">
              <div className="col-md-4 p-3">
                <div className="p-3 bg-body-tertiary1 rounded shadow-sm">
                  <div className="d-flex justify-content-start">
                    <img
                      src="assets/img/person/Navneet.jpg"
                      class="img-fluid rounded team-img"
                      alt="..."
                      style={{ height: "250px", width: "250px" }}
                    />
                  </div>
                  <div className="name fw-semibold pb-2 pt-2">
                    Navneet Kumar singh
                  </div>
                  <div className="desig">
                    <p className="fst-italic text-secondary">
                      Advisor of Beal Health.
                    </p>
                  </div>
                  <div className="para">
                    <p>
                      Say Hello to, Navneet our Advisor with 21 years of
                      experience in project management and Planning. With the
                      background of Business administration from XLRI
                      Jamshedpur. Navneet is the mastermind behind empowering
                      the communities, with project portfolio management. He is
                      constantly thinking about development and monitoring
                      clients to ensure their successful business needs.
                    </p>
                  </div>

                  <div className="social d-flex justify-content-evenly">
                    <Link
                      to=""
                      className="px-3 py-2 bg-danger-subtle rounded-circle  d-flex justify-content-center align-items-center"
                    >
                      <FontAwesomeIcon icon={faXTwitter} className="fs-5" />
                    </Link>
                    <Link
                      to=""
                      className="px-3 py-2 bg-danger-subtle rounded-circle  d-flex justify-content-center align-items-center"
                    >
                      <FontAwesomeIcon icon={faMeta} className="fs-5" />
                    </Link>
                    <Link
                      to="https://www.linkedin.com/in/navneet-kumar-singh-pmp%C2%AE-psm%C2%AE-xlri-ba571927/"
                      className="px-3 py-2 bg-danger-subtle rounded-circle  d-flex justify-content-center align-items-center"
                    >
                      <FontAwesomeIcon icon={faLinkedinIn} className="fs-5" />
                    </Link>
                    <Link
                      to=""
                      className="px-3 py-2 bg-danger-subtle rounded-circle  d-flex justify-content-center align-items-center"
                    >
                      <FontAwesomeIcon icon={faEnvelope} className="fs-5" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer data-aos="fade-up"></Footer>
    </div>
  );
};

export default Aboutus;
